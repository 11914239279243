// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "TikTokFont";
  src: url("./content/fonts/TikTokFont-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "TikTokFont";
  src: url("./content/fonts/TikTokFont-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "TikTokFont";
  src: url("./content/fonts/TikTokFont-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
/* body {
    font-family: 'TikTokFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
} */
body {
  font-family: "TikTokFont", sans-serif !important;
  font-size: 14px;
  padding: 0;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/fonts.css"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,oEAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAEA;EACI,yBAAA;EACA,qEAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAGA;EACI,yBAAA;EACA,iEAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIA;;;;GAAA;AAKA;EACI,gDAAA;EACA,eAAA;EACA,UAAA;EACA,SAAA;AAFJ","sourcesContent":["@font-face {\n    font-family: 'TikTokFont';\n    src: url('./content/fonts/TikTokFont-Regular.woff2') format('woff2');\n    font-weight: 400;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'TikTokFont';\n    src: url('./content/fonts/TikTokFont-Semibold.woff2') format('woff2');\n    font-weight: 600;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'TikTokFont';\n    src: url('./content/fonts/TikTokFont-Bold.woff2') format('woff2');\n    font-weight: 700;\n    font-style: normal;\n}\n\n/* body {\n    font-family: 'TikTokFont', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n        sans-serif;\n} */\nbody {\n    font-family: 'TikTokFont', sans-serif !important;\n    font-size: 14px;\n    padding: 0;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
