import { faBolt, faFileExport, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AUTHORITIES } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import React, { useMemo } from 'react';
import { Translate } from 'react-jhipster';
import { homeIcon, orderIcon, productIcon } from './icons';

const publicMenu = [{ name: 'Dashboard', link: '/dashboard', icon: homeIcon, devider: true }];
const entitiesMenu = [
  { name: 'Orders', link: '/ui-order', icon: orderIcon },
  { name: 'Products', link: '/product', icon: productIcon },
  { name: 'Product Templates', link: '/product-template', icon: productIcon },
  { name: 'Shop', link: '/shop', icon: homeIcon },
  { name: 'Export History', link: '/export-history', icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faFileExport} /> },
  {
    name: <Translate contentKey="global.menu.entities.telegram" />,
    link: '/telegram',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faMessage} />,
  },
  {
    name: 'Flash Sale Schedule',
    link: '/flash-sale-schedule',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={faBolt} />,
    devider: true,
  },
];
const adminMenu = [
  {
    name: <Translate contentKey="global.menu.entities.partnerApp" />,
    link: '/partner-app',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'rocket'} />,
  },
  {
    name: <Translate contentKey="global.menu.entities.sellerAuthorization" />,
    link: '/seller-authorization',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'store'} />,
  },
  {
    name: <Translate contentKey="global.menu.entities.baseCost" />,
    link: '/base-cost',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'money-bill'} />,
  },
  {
    name: <Translate contentKey="global.menu.admin.userManagement" />,
    link: '/admin/user-management',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'users'} />,
  },
  {
    name: <Translate contentKey="global.menu.admin.metrics" />,
    link: '/admin/metrics',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'tachometer-alt'} />,
  },
  {
    name: <Translate contentKey="global.menu.admin.health" />,
    link: '/admin/health',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'heart'} />,
  },
  {
    name: <Translate contentKey="global.menu.admin.configuration" />,
    link: '/admin/configuration',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'cogs'} />,
  },
  {
    name: <Translate contentKey="global.menu.admin.logs" />,
    link: '/admin/logs',
    icon: <FontAwesomeIcon style={{ fontSize: '12px' }} icon={'tasks'} />,
  },
];

export const useSideMenu = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  const menu = useMemo(() => {
    const displayMenu = [...publicMenu];
    if (isAuthenticated) {
      displayMenu.push(...(entitiesMenu as any));
    }
    if (isAdmin) {
      displayMenu.push(...(adminMenu as any));
    }
    return displayMenu;
  }, [isAuthenticated, isAdmin]);

  return menu;
};
