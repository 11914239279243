import { IUser } from 'app/shared/model/user.model';
import { IPartnerApp } from 'app/shared/model/partner-app.model';

export interface ISellerAuthorization {
  id?: string;
  accessToken?: string;
  accessTokenExpireIn?: number;
  refreshToken?: string;
  refreshTokenExpireIn?: number;
  openId?: string;
  sellerName?: string;
  sellerBaseRegion?: string;
  userType?: number;
  user?: IUser | null;
  app?: IPartnerApp | null;
}

export const defaultValue: Readonly<ISellerAuthorization> = {};
