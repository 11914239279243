import './pricing.scss';
import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const pricingPlans = [
  {
    title: 'Pack 20',
    price: '$20/month',
    description: 'The essentials to provide your best work for clients.',
    features: [
      '20 shops',
      'Unlimited orders',
      'Create & get Label',
      'Upload tracking',
      'Staff assign & management',
      'Product Import & Listing',
      'FlashSale Management',
      'Financial Report'
    ],
  },
  {
    title: 'Pack 40',
    price: '$40/month',
    description: 'The essentials to provide your best work for clients.',
    features: [
      '70 shops',
      'Unlimited orders',
      'Create & get Label',
      'Upload tracking',
      'Staff assign & management',
      'Product Import & Listing',
      'FlashSale Management',
      'Financial Report'
    ],
  },
  {
    title: 'Pack 50',
    price: '$50/month',
    description: 'A plan that scales with your rapidly growing business.',
    features: [
      '100 shops',
      'Unlimited orders',
      'Create & get Label',
      'Upload tracking',
      'Staff assign & management',
      'Product Import & Listing',
      'FlashSale Management',
      'Financial Report'
    ],
  },
  {
    title: 'Pack 100',
    price: '$100/month',
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      '300 shops',
      'Unlimited orders',
      'Create & get Label',
      'Upload tracking',
      'Staff assign & management',
      'Product Import & Listing',
      'FlashSale Management',
      'Financial Report',
    ],
  },
];

const Pricing = () => {
  return (
    <Container>
      <Typography color={"blueviolet"} variant="h6" component="p" align="center" gutterBottom>
        Pricing
      </Typography>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Pricing plans for teams of all sizes
      </Typography>
      <Typography variant="h6" component="p" align="center" color="textSecondary" paragraph>
        Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer loyalty, and driving sales.
      </Typography>
      <Grid container spacing={4} className="pricing-grid">
        {pricingPlans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} className="pricing-grid-item">
            <Card className="pricing-card">
              <CardContent className="pricing-card-content">
                <Typography variant="h5" component="h2" className="plan-title">
                  {plan.title}
                </Typography>
                <Typography variant="h6" className="plan-price">
                  {plan.price}
                </Typography>
                <Typography variant="body2" component="p" className="plan-description">
                  {plan.description}
                </Typography>
                <ul className="features-list">
                  {plan.features.map((feature, idx) => (
                    <Typography component="li" key={idx} className="feature-item">
                      <CheckIcon className="check-icon" /> {feature}
                    </Typography>
                  ))}
                </ul>
                <Button variant="contained" color="primary" fullWidth className="buy-button">
                  Buy plan
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Pricing;
