import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import './home.scss';
import { Grid } from '@mui/material';

export const Home = () => {
  const imageUrl = `https://toptopshop.sfo3.cdn.digitaloceanspaces.com/static/images/screenshot.webp`;
  return (
    <div className='bg-white h-100'>
      <Row className="home-container">
        <Grid container spacing={2} className="center-div">
          <Grid item xs={12} xl={4}>
            <h1>Manage your TikTokShop orders in one place</h1>
            <p><strong>Streamline your operations with our all-in-one platform. </strong></p>
            <p>Effortlessly manage orders from intake to fulfillment, including real-time notifications, label
              generation, and third-party shipping coordination. Assign tasks efficiently, track shipments, and import
              products from multiple sources. Gain complete visibility into your finances with robust reporting tools
              while optimizing inventory and product management.</p>
            <p><Link className="btn btn-primary" to={'/dashboard'}>Get Started</Link> <Link className="btn btn-info" to={'/pricing'}>Pricing</Link></p>
          </Grid>
          <Grid item xs={12} xl={8}>
            <div className='d-flex flex-column align-items-center'>
              <img src={imageUrl} alt="TikTok Shop Orders" style={{ maxWidth: '100%', height: 'auto', marginRight: "-20%" }} />
              <a href={imageUrl} download="image.png"
                style={{ marginTop: '20px', textDecoration: 'none', color: '#007bff', fontSize: '16px' }}>
              </a>
            </div>
          </Grid>
        </Grid>
      </Row>
    </div>
  );
};

export default Home;
