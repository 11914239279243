// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-item {
  width: 250px;
}
.language-item:hover {
  background: #f5f5f5;
  cursor: pointer;
}

.language-selected {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: #233241;
}
.language-selected:hover {
  background: #374f67;
  cursor: pointer;
}
.language-selected img {
  width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/AccountDropdown/style.scss","webpack://./src/main/webapp/app/custom.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AADJ;AAEI;EACI,mBCLQ;EDMR,eAAA;AAAR;;AAIA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;AADJ;AAEI;EACI,mBAAA;EACA,eAAA;AAAR;AAEI;EACI,WAAA;AAAR","sourcesContent":["@import \"../../../custom.scss\";\n\n.language-item {\n    width: 250px;\n    &:hover {\n        background: $base-bg-color;\n        cursor: pointer;\n    }\n}\n\n.language-selected {\n    width: 40px;\n    height: 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 40px;\n    background: #233241;\n    &:hover {\n        background: #374f67;\n        cursor: pointer;\n    }\n    img {\n        width: 25px;\n    }\n}","$base-bg-color: #f5f5f5;\n$base-bg-color-dark: #f5f5f5d7;\n\n$text-color-1: rgba(0, 0, 0, .92);\n$header-height: 61;\n$footer-height: 90;\n$text-base-light: #6b7280bf;\n$text-base: #6b7280;\n$text-base-dark: #2a2d32;\n\n// Colors\n$primary: #009995;\n$secondary: #FF6B6B;\n$success: #28A745;\n$info: #17A2B8;\n$warning: #FFC107;\n$danger: #DC3545;\n$light: #F8F9FA;\n$dark: #343A40;\n\n// Additional custom colors\n$accent: #FF9F1C;\n$neutral: #6C757D;\n$light-teal: #4ECDC4;\n$dark-teal: #005F5C;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
