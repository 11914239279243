// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-detail-drawer {
  padding: 20px;
}
.order-detail-drawer .order-products-card {
  background: #f5f5f5;
  border: 1px solid rgba(245, 245, 245, 0.8431372549);
  border-radius: 0 8px 8px 0;
}
.order-detail-drawer .order-products-card img {
  width: 100px;
  height: auto;
}
.order-detail-drawer .order-products-card .order-product-name {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
}
.order-detail-drawer .block-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #2a2d32;
}
.order-detail-drawer .status.CANCELLED {
  color: #DC3545;
}
.order-detail-drawer .status.COMPLETED {
  color: #28A745;
}
.order-detail-drawer .status.AWAITING_COLLECTION {
  color: #6C757D;
}
.order-detail-drawer .listout-label-light {
  color: rgba(107, 114, 128, 0.7490196078);
}
.order-detail-drawer .listout-content {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/ui-order/component/OrderDetailDrawer/style.scss","webpack://./src/main/webapp/app/custom.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;AAII;EACI,mBCPQ;EDQR,mDAAA;EACA,0BAAA;AAFR;AAIQ;EACI,YAAA;EACA,YAAA;AAFZ;AAKQ;EACI,yBAAA;EACA,eAAA;AAHZ;AAOI;EACI,iBAAA;EACA,iBAAA;EACA,cCjBS;ADYjB;AAQI;EACI,cCbC;ADOT;AASI;EACI,cCpBE;ADaV;AAUI;EACI,cCfE;ADOV;AAWI;EACI,wCCnCU;AD0BlB;AAYI;EACI,iBAAA;AAVR","sourcesContent":["@import \"../../../../custom.scss\";\n\n.order-detail-drawer {\n    padding: 20px;\n\n\n    .order-products-card {\n        background: $base-bg-color;\n        border: 1px solid $base-bg-color-dark;\n        border-radius: 0 8px 8px 0;\n\n        img {\n            width: 100px;\n            height: auto;\n        }\n\n        .order-product-name {\n            color: rgba(0, 0, 0, 0.8);\n            font-size: 1rem;\n        }\n    }\n\n    .block-title {\n        font-size: 1.1rem;\n        font-weight: bold;\n        color: $text-base-dark;\n    }\n\n    .status.CANCELLED {\n        color: $danger;\n    }\n\n    .status.COMPLETED {\n        color: $success;\n    }\n\n    .status.AWAITING_COLLECTION {\n        color: $neutral;\n    }\n\n    .listout-label-light {\n        color: $text-base-light;\n    }\n\n    .listout-content {\n        font-weight: bold;\n    }\n}","$base-bg-color: #f5f5f5;\n$base-bg-color-dark: #f5f5f5d7;\n\n$text-color-1: rgba(0, 0, 0, .92);\n$header-height: 61;\n$footer-height: 90;\n$text-base-light: #6b7280bf;\n$text-base: #6b7280;\n$text-base-dark: #2a2d32;\n\n// Colors\n$primary: #009995;\n$secondary: #FF6B6B;\n$success: #28A745;\n$info: #17A2B8;\n$warning: #FFC107;\n$danger: #DC3545;\n$light: #F8F9FA;\n$dark: #343A40;\n\n// Additional custom colors\n$accent: #FF9F1C;\n$neutral: #6C757D;\n$light-teal: #4ECDC4;\n$dark-teal: #005F5C;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
