import { Checkbox, Link, Tooltip } from '@mui/material';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import UpdateDesign from 'app/entities/ui-order/component/OrderDetailDrawer/UpdateDesign';
import PaginationComponent from 'app/shared/components/PaginationComponent';
import EmptyTable from 'app/shared/components/Table/EmptyTable';
import TruncatedText from 'app/shared/components/TruncatedText';
import { ILocalProduct } from 'app/shared/model/local-product.model';
import React, { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextFormat } from 'react-jhipster';
import { Table } from 'reactstrap';
import LocalProductDeleteDialog from '../../product-delete-dialog';
import { ProductContext } from '../../ProductContext';
import ConfirmDeleteProductsModal from '../DeleteProducts';
import ProductDrawerFilter from '../DrawerFilter';
import ImageWithModal from '../ImageWithModal';
import ProductTab from '../ProductTab';
import './styles.scss';
import TableAction from './TableAction';

const ProductTable = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDeleteProductsModal, setOpenDeleteProductsModal] = useState<boolean>(false);
  const [isOpenUpdateDesignModal, setOpenUpdateDesign] = useState<boolean>(false);
  const [currProduct, setCurrProduct] = useState<ILocalProduct>();
  const { setValue, getValues, watch } = useFormContext();
  const paginationState = watch('paginationState');
  const productSelected = watch('productSelected');
  const products = useAppSelector(state => state.localProduct.entities);
  const loading = useAppSelector(state => state.localProduct.loading);
  const totalItems = useAppSelector(state => state.localProduct.totalItems);
  const { onRefreshTable, searchProducts } = useContext(ProductContext);

  const handleDeleteProduct = (product: ILocalProduct) => {
    setOpenDeleteModal(true);
    setCurrProduct(product);
  };

  const handleOpenUpdateDesignModal = (product: ILocalProduct) => {
    setOpenUpdateDesign(true);
    setCurrProduct(product);
  };

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleSelectProduct = (event, product) => {
    if (event.target.checked) {
      setValue('productSelected', [...productSelected, product]);
    } else {
      setValue(
        'productSelected',
        productSelected.filter(p => p.id !== product.id),
      );
    }
  };

  const handleSelectAll = event => {
    if (event.target.checked) {
      setValue('productSelected', products);
    } else {
      setValue('productSelected', []);
    }
  };

  const closeUpdateDesignModal = () => {
    setOpenUpdateDesign(false);
    onRefreshTable?.();
  };

  const closeDeleteProductsModal = clearSelected => {
    setOpenDeleteProductsModal(false);
    if (clearSelected) {
      setValue('productSelected', []);
    }
    onRefreshTable?.();
  };

  const handlePageSizeChange = itemsPerPage => {
    const values = getValues();
    setValue('paginationState', { ...values.paginationState, itemsPerPage, activePage: 1 });
    searchProducts();
  };

  const handlePagination = activePage => {
    const values = getValues();
    setValue('paginationState', { ...values.paginationState, activePage });
    searchProducts();
  };

  return (
    <>
      <TableAction />
      <ProductDrawerFilter />
      <div className="tts-card">
        <div className="d-flex justify-content-between align-items-center">
          <ProductTab />
        </div>
        <Table responsive borderless className="product-table">
          <thead>
            <tr className="px-2">
              <th style={{ width: 28, textAlign: 'center' }}>
                <Checkbox
                  color="primary"
                  style={{ padding: 0 }}
                  checked={productSelected?.length === products?.length}
                  onChange={handleSelectAll}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </th>
              <th>Product</th>
              <th>Published Shops</th>
              <th>Update on</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            <tr className="p-0">
              <td colSpan={9} className="border-0 p-0">
                <div style={{ height: 10, background: '#f5f5f5' }} className=""></div>
              </td>
            </tr>
          </thead>
          <tbody className="position-relative">
            {loading && (
              <div className="table-data-loading position-absolute w-100 h-100 z-3 d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {(products as Array<ILocalProduct>)?.map((product, index) => (
              <>
                <tr key={`table_row_${index}`} className="px-2">
                  <td style={{ width: 28 }} className="border-0">
                    <Checkbox
                      color="primary"
                      checked={productSelected?.map(p => p.id).includes(product.id)}
                      onChange={e => handleSelectProduct(e, product)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </td>
                  <td style={{ maxWidth: 450 }}>
                    <div className="d-flex gap-1">
                      <div className="position-relative">
                        <ImageWithModal src={product.mainProductImage} alt={product.productName} />
                      </div>
                      <div style={{ width: 'calc(100% - 100px)', paddingLeft: 10 }}>
                        <Tooltip title={product.productName}>
                          <p style={{ fontSize: 18 }}>
                            <TruncatedText text={product.productName} />
                          </p>
                        </Tooltip>
                        <div className="">
                          <span>
                            <strong>SKU: </strong>
                          </span>
                          <span>{product?.variations?.[0]?.sellerSku || ''}</span>
                        </div>
                        <div className="">
                          {product.templateName && product.productType ? (
                            <span>{`${product.templateName} - ${product.productType}`}</span>
                          ) : (
                            <span>{product.templateName || product.productType}</span>
                          )}
                        </div>
                        <div className="d-flex gap-2">
                          <span>
                            <strong>Front: </strong>
                          </span>
                          <span>
                            {product.printFileFront ? (
                              <Link href={product.printFileFront} target="_blank" rel="noreferrer">
                                <TruncatedText text={product.printFileFront} maxLength={40} />
                              </Link>
                            ) : (
                              <span className="text-danger">Missing</span>
                            )}
                          </span>
                        </div>
                        <div className="d-flex gap-2">
                          <span>
                            <strong>Back:&nbsp;</strong>
                          </span>
                          <span>
                            {product.printFileBack ? (
                              <Link href={product.printFileBack} target="_blank" rel="noreferrer">
                                <TruncatedText text={product.printFileBack} maxLength={40} />
                              </Link>
                            ) : (
                              <span className="text-danger">{`  Missing`}</span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {product.shops?.map(shop => (
                      <div key={shop.id}>
                        <a>{shop.name}</a>
                      </div>
                    ))}
                  </td>
                  <td>
                    {product.lastModifiedDate ? (
                      <TextFormat value={product.lastModifiedDate} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
                    ) : null}
                  </td>
                  <td>
                    <div className="product-status-live">
                      <span>Live</span>
                    </div>
                  </td>
                  <td className="border-0">
                    <div className="d-flex flex-column gap-1">
                      <Link className="product-action" onClick={() => handleDeleteProduct(product)}>
                        <span className="product-action-link">Delete</span>
                      </Link>
                      <Link className="product-action" onClick={() => handleOpenUpdateDesignModal(product)}>
                        <span className="product-action-link">Update design</span>
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr className="p-0 tr-variations">
                  <td colSpan={9} className="border-0 p-0">
                    <div style={{ height: 10, background: '#f5f5f5' }} className=""></div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
        {products && !products.length ? <EmptyTable message={<span>No Products</span>} /> : null}
        <PaginationComponent
          pageIndex={paginationState.activePage}
          totalRecords={totalItems}
          onPageChange={handlePagination}
          pageSize={paginationState.itemsPerPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
      <LocalProductDeleteDialog isOpen={openDeleteModal} onClose={handleCloseDeleteModal} product={currProduct} />
      <UpdateDesign isOpenModal={isOpenUpdateDesignModal} onCloseModal={closeUpdateDesignModal} product={currProduct} />
      <ConfirmDeleteProductsModal onClose={closeDeleteProductsModal} isOpen={openDeleteProductsModal} products={productSelected} />
    </>
  );
};

export default ProductTable;
