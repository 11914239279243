import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import PageHead from 'app/shared/components/PageHead';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getPaginationState, Translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Form, Input } from 'reactstrap';
import { uploadFile } from '../local-product/local-product.reducer';
import ImportProduct from './components/ImportProduct';
import ProductTable from './components/ProductTable';
import PublicToShop from './components/PublicToShop';
import { ProductProvider } from './ProductContext';

export const Product = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [openInportProductModal, setOpenInportProductModal] = useState(false);
  const loading = useAppSelector(state => state.localProduct.loading);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const [searchKeyword, setSearchKeyword] = useState('');
  const methods = useForm({
    defaultValues: {
      paginationState: overridePaginationStateWithQueryParams(
        getPaginationState(pageLocation, ITEMS_PER_PAGE, 'lastModifiedDate', 'desc'),
        pageLocation.search,
      ),
      productSelected: [],
      searchKeyword: undefined,
      shopPublish: '',
    },
  });

  const handleFileChange = file => {
    if (file && file.type === 'text/csv') {
      dispatch(uploadFile(file));
      (document.getElementById('fileInput') as HTMLInputElement).value = ''; // Clear file input value
    } else {
      alert('Only CSV files are allowed');
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission behavior
      methods.setValue('searchKeyword', searchKeyword);
    }
  };

  return (
    <div id="product-list-page" className="block-container w-100 h-100 position-relative">
      <FormProvider {...methods}>
        <Form>
          <ProductProvider>
            <Container className="mycontainer">
              <PageHead left="Manage Products">
                <div className="d-flex gap-2">
                  <div className="bg-white" style={{ borderRadius: 8 }}>
                    <Input
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search Products"
                      inputProps={{ 'aria-label': 'search product' }}
                      value={searchKeyword}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyPress}
                    />
                  </div>
                  {isAdmin && (
                    <Button className="me-2" onClick={() => navigate('/product/new')} color="primary">
                      <FontAwesomeIcon icon="plus" />
                      &nbsp;
                      <Translate contentKey="dmmtiktokApp.product.home.createLabel">Create new product</Translate>
                    </Button>
                  )}
                  <Button className="me-2" color="info" disabled={loading} onClick={() => setOpenInportProductModal(true)}>
                    <FontAwesomeIcon icon={faFileImport} spin={loading} /> Import
                  </Button>
                </div>
              </PageHead>
              <PublicToShop />

              <div className="">
                <ProductTable />
              </div>
              <ImportProduct
                isOpen={openInportProductModal}
                onClose={() => setOpenInportProductModal(false)}
                onFileUpload={handleFileChange}
              />
            </Container>
          </ProductProvider>
        </Form>
      </FormProvider>
    </div>
  );
};

export default Product;
