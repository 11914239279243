import { faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import React, { FC, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ProductContext } from '../../ProductContext';
import ConfirmDeleteProductsModal from '../DeleteProducts';

export const sortOptions = [
  { key: 'lastModifiedDate,asc', value: 'Time Created (oldest first)' },
  { key: 'lastModifiedDate,desc', value: 'Time Created (newest first)' },
];

interface TableActionProps {}
const TableAction: FC<TableActionProps> = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteProductsModal, setOpenDeleteProductsModal] = useState<boolean>(false);
  const { setValue, watch, getValues } = useFormContext();
  const { onRefreshTable } = useContext(ProductContext);
  const productSelected = watch('productSelected');
  const paginationState = watch('paginationState');
  const loading = useAppSelector(state => state.localProduct.loading);

  const handleOpenDeleteProductsModal = () => {
    if (!productSelected?.length) {
      toast.warning('Select products to delete');
      return;
    }
    setOpenDeleteProductsModal(true);
  };

  const handleCloseDeleteProductsModal = clearSelected => {
    setOpenDeleteProductsModal(false);
    if (clearSelected) {
      setValue('productSelected', []);
    }
    onRefreshTable?.();
  };

  const handleSortChange = (item: (typeof sortOptions)[number]) => {
    const sortSplit = item.key.split(',');
    setValue('paginationState', { ...paginationState, sort: sortSplit[0], order: sortSplit[1] });
    onRefreshTable(0);
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleRefreshTable = () => onRefreshTable?.();

  const getSortLable = (sortId: string) => {
    const sortRecord = sortOptions.find(item => item.key === sortId);
    return sortRecord?.value || '';
  };

  const toggleFilterDrawer = () => {
    const { isOpenFilter } = getValues();
    setValue('isOpenFilter', !isOpenFilter);
  };

  return (
    <div className="d-flex justify-content-between mb-3 gap-2 ">
      <div>
        <Button outline color="info" onClick={toggleFilterDrawer} disabled={loading}>
          <FontAwesomeIcon icon={faFilter} /> <span>Filter</span>
        </Button>
      </div>
      <div className="d-flex gap-2">
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
          <DropdownToggle outline caret>
            Sort by: {getSortLable(`${paginationState?.sort},${paginationState?.order}`)}
          </DropdownToggle>
          <DropdownMenu color="primary">
            {sortOptions?.map(item => (
              <DropdownItem
                active={item.key === `${paginationState?.sort},${paginationState?.order}`}
                onClick={() => handleSortChange(item)}
                key={item.key}
              >
                {item.value}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        <Button className="me-2" color="secondary" onClick={handleRefreshTable} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="dmmtiktokApp.product.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Button
          disabled={productSelected?.length === 0}
          outline
          onClick={handleOpenDeleteProductsModal}
          color="secondary"
          className="d-flex gap-2 align-items-center"
        >
          <FontAwesomeIcon icon={faTrash} color="red" />
          {<span>({productSelected?.length || 0})</span>}
        </Button>

        <ConfirmDeleteProductsModal onClose={handleCloseDeleteProductsModal} isOpen={openDeleteProductsModal} products={productSelected} />
      </div>
    </div>
  );
};

export default TableAction;
