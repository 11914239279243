/* eslint-disable @typescript-eslint/no-misused-promises */
import { faSave, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { partialUpdateEntity } from 'app/entities/local-product/local-product.reducer';
import { FormLabel } from 'app/entities/product/components/common';
import { ILocalProduct } from 'app/shared/model/local-product.model';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const printPositions = [
  {
    label: 'FRONT',
    value: 'FRONT',
  },
  {
    label: 'BACK',
    value: 'BACK',
  },
  {
    label: 'BOTH',
    value: 'BOTH',
  },
  {
    label: 'POCKET',
    value: 'POCKET',
  },
  {
    label: 'BOTH POCKET',
    value: 'BOTH_POCKET',
  },
];
interface FormInput {
  printFileFront: string;
  printFileBack: string;
  printPosition?: string;
}

interface UpdateDesignProps {
  isOpenModal: boolean;
  onCloseModal?: () => void;
  product?: ILocalProduct;
}

const UpdateDesign: FC<UpdateDesignProps> = ({ isOpenModal, onCloseModal, product }) => {
  const updating = useAppSelector(state => state.localProduct.updating);
  const updateSuccess = useAppSelector(state => state.localProduct.updateSuccess);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormInput>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (product) {
      const initFormData: FormInput = {} as FormInput;
      if (product?.printFileFront) {
        initFormData.printFileFront = product?.printFileFront;
      }
      if (product?.printFileBack) {
        initFormData.printFileBack = product?.printFileBack;
      }
      if (product?.printPosition) {
        initFormData.printPosition = product?.printPosition;
      }
      reset(initFormData);
    }
  }, [product?.printFileFront, product?.printFileBack, product?.printPosition]);

  useEffect(() => {
    if (updateSuccess) {
      onCloseModal();
    }
  }, [updateSuccess]);

  const onSubmit = (data: FormInput) => {
    if (!product?.id) {
      toast.error('Missing product ID');
    } else {
      dispatch(partialUpdateEntity({ id: product?.id, ...data }));
    }
  };

  const handleCloseModal = () => {
    reset({});
    onCloseModal();
  };

  return (
    <Modal isOpen={isOpenModal} onClosed={handleCloseModal} zIndex={2000} size="lg">
      <ModalHeader toggle={handleCloseModal}>Update Design URLs</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody id="update-design-modal">
          <FormGroup>
            <FormLabel>Front Design URL</FormLabel>

            <Controller
              control={control}
              name="printFileFront"
              rules={{
                validate() {
                  const { printFileFront, printFileBack } = getValues();
                  if (!printFileFront && !printFileBack) {
                    return 'Input one of Front or Back design';
                  }
                  return true;
                },
              }}
              render={({ field }) => <Input {...field} type="text" placeholder="Enter front URL here..." style={{ fontSize: 14 }} />}
            />
            {errors.printFileFront && <FormFeedback>{errors.printFileFront.message}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <FormLabel>Back Design URL</FormLabel>
            <Controller
              control={control}
              name="printFileBack"
              rules={{
                validate() {
                  const { printFileFront, printFileBack } = getValues();
                  if (!printFileFront && !printFileBack) {
                    return 'Input one of Front or Back design';
                  }
                  return true;
                },
              }}
              render={({ field }) => <Input {...field} type="text" placeholder="Enter back URL here..." style={{ fontSize: 14 }} />}
            />
          </FormGroup>
          {product?.productType === '2D' && (
            <FormGroup>
              <FormLabel>Print position</FormLabel>
              <Controller
                control={control}
                name="printPosition"
                render={({ field }) => (
                  <Input {...field} type="select" style={{ fontSize: 14 }}>
                    {printPositions.map(pp => (
                      <option key={pp.value} value={pp.value}>
                        {pp.label}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button type="submit">
            <FontAwesomeIcon icon={updating ? faSync : faSave} />
            &nbsp; Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UpdateDesign;
