import { Drawer } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ProductFilterForm from '../ProductFilter';

const ProductDrawerFilter = () => {
  const { watch, setValue } = useFormContext();
  const isOpenFilter = watch('isOpenFilter');

  const handleCloseFilterDrawer = () => {
    setValue('isOpenFilter', false);
  };

  return (
    <Drawer
      PaperProps={{
        style: { width: 300 },
      }}
      anchor="right"
      open={isOpenFilter}
      onClose={handleCloseFilterDrawer}
    >
      <ProductFilterForm />
    </Drawer>
  );
};

export default ProductDrawerFilter;
