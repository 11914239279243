import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { useAppSelector } from 'app/config/store';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';

const ShopAuthorizeModal = ({ open, onClose, title }) => {
  const account = useAppSelector(state => state.authentication.account);
  const authenticateLink = `https://dmmtiktok.net/api/shop/authorized/seller?seller_code=${account.login}&region=US`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(authenticateLink);
    alert('Link copied to clipboard!');
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle align={"center"}>{title}</DialogTitle>
      <DialogContent>
        <div className="entity-details">
          <div className="detail-item">
            <Box display="flex" alignItems="center" width={"100%"}>
              <TextField
                fullWidth
                variant="outlined"
                value={authenticateLink}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={copyToClipboard}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: { borderRadius: '8px' }
                }}
              />
            </Box>
          </div>
          <div className="detail-item">
            <b>Step 1:</b> <span>Use a browser where you are logged into ToptopShop, then copy the above link to start the
            installation of the Tiktok Shop app.</span>
          </div>
          <div className="detail-item">
            <b>Step 2:</b> <span>Enter your email and confirm the installation. The browser will redirect you back to the
            ToptopShop app.</span>
          </div>
          <div className="detail-item">
            <b>Step 3:</b> <span>Wait a few seconds for the orders to sync to the app.</span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShopAuthorizeModal;
