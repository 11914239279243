import Footer from 'app/shared/layout/footer/footer';
import React from 'react';
import { Col, Container } from 'reactstrap';
type ContentProps = {
  nonContainer?: boolean
}
const Content: React.FC<React.PropsWithChildren<ContentProps>> = ({ children, nonContainer }) => {
  return (
    <main className="content" id="main-content">
      {
        nonContainer ? children : (<Container>{children}</Container>)
      }
    </main>
  );
};

export default Content;