import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from '../shop-metric/shop-metric.reducer';

const ShopMetricModal = ({ open, id, onClose, title }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open && id) {
      dispatch(getEntity(id));
    }
  }, [open, id, dispatch]);

  const shopMetricEntity = useAppSelector(state => state.shopMetric.entity);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className="entity-details">
          {/* Product */}
          <h3><span>Product</span></h3>
          <div className="detail-item">
            <span className="detail-property">
                <Translate contentKey="dmmtiktokApp.shopMetric.totalProducts">Total Products</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalProducts}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
                <Translate contentKey="dmmtiktokApp.shopMetric.totalLive">Total Live</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalLive}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
                <Translate contentKey="dmmtiktokApp.shopMetric.totalInactive">Total Inactive</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalInactive}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
                <Translate contentKey="dmmtiktokApp.shopMetric.totalReviewing">Total Reviewing</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalReviewing}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
                <Translate contentKey="dmmtiktokApp.shopMetric.totalSuspended">Total Suspended</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalSuspended}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
                <Translate contentKey="dmmtiktokApp.shopMetric.totalDraft">Total Draft</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalDraft}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
                <Translate contentKey="dmmtiktokApp.shopMetric.totalDeleted">Total Deleted</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalDeleted}</span>
          </div>

          {/* Order */}
          <h3><span>Order</span></h3>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.id}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalOrders">Total Orders</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalOrders}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalOnhold">Total On-hold</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalOnhold}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalAwaitingShipment">Total Awaiting Shipment</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalAwaitingShipment}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate
                contentKey="dmmtiktokApp.shopMetric.totalAwaitingCollection">Total Awaiting Collection</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalAwaitingCollection}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalCancelled">Total Cancelled</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalCancelled}</span>
          </div>
          <div className="detail-item-no-border">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalDelivered">Total Delivered</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalDelivered}</span>
          </div>

          {/* Flash Sale */}
          <h3><span>Flash Sale</span></h3>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalFlashsale">Total FlashSale</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalFlashsale}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalInFlashsale">Total In FlashSale</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalInFlashsale}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.shopMetric.totalNotInFlashsale">Total Not In FlashSale</Translate>
            </span>
            <span className="detail-value">{shopMetricEntity.totalNotInFlashsale}</span>
          </div>

        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShopMetricModal;
