// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-product-title {
  font-size: 1.8rem;
  font-weight: bold;
}

.btn-import-product-temp {
  text-decoration: none;
}
.btn-import-product-temp:hover {
  text-decoration: underline;
}

.zone-active {
  border: 1px solid #009995;
  cursor: pointer;
}

.import-file-zone {
  height: 200px;
  text-decoration: none;
  background: #f5f5f5;
  border: 1px solid rgba(245, 245, 245, 0.8431372549);
}
.import-file-zone:hover {
  border: 1px solid #009995;
  cursor: pointer;
}
.import-file-zone p.attach-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.import-file-zone p {
  margin-bottom: 0;
}
.import-file-zone svg {
  font-size: 1.5rem;
  color: #009995;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/ui-order/component/import-tracking-modal/style.scss","webpack://./src/main/webapp/app/custom.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,iBAAA;AADJ;;AAIA;EACI,qBAAA;AADJ;AAGI;EACI,0BAAA;AADR;;AAKA;EACI,yBAAA;EACA,eAAA;AAFJ;;AAKA;EACI,aAAA;EACA,qBAAA;EACA,mBCvBY;EDwBZ,mDAAA;AAFJ;AAII;EACI,yBAAA;EACA,eAAA;AAFR;AAKI;EACI,iBAAA;EACA,mBAAA;AAHR;AAMI;EACI,gBAAA;AAJR;AAOI;EACI,iBAAA;EACA,cC/BE;AD0BV","sourcesContent":["@import \"../../../../custom.scss\";\n\n.import-product-title {\n    font-size: 1.8rem;\n    font-weight: bold;\n}\n\n.btn-import-product-temp {\n    text-decoration: none;\n\n    &:hover {\n        text-decoration: underline;\n    }\n}\n\n.zone-active {\n    border: 1px solid $primary;\n    cursor: pointer;\n}\n\n.import-file-zone {\n    height: 200px;\n    text-decoration: none;\n    background: $base-bg-color;\n    border: 1px solid $base-bg-color-dark;\n\n    &:hover {\n        border: 1px solid $primary;\n        cursor: pointer;\n    }\n\n    p.attach-title {\n        font-weight: bold;\n        margin-bottom: 10px;\n    }\n\n    p {\n        margin-bottom: 0;\n    }\n\n    svg {\n        font-size: 1.5rem;\n        color: $primary;\n\n    }\n}","$base-bg-color: #f5f5f5;\n$base-bg-color-dark: #f5f5f5d7;\n\n$text-color-1: rgba(0, 0, 0, .92);\n$header-height: 61;\n$footer-height: 90;\n$text-base-light: #6b7280bf;\n$text-base: #6b7280;\n$text-base-dark: #2a2d32;\n\n// Colors\n$primary: #009995;\n$secondary: #FF6B6B;\n$success: #28A745;\n$info: #17A2B8;\n$warning: #FFC107;\n$danger: #DC3545;\n$light: #F8F9FA;\n$dark: #343A40;\n\n// Additional custom colors\n$accent: #FF9F1C;\n$neutral: #6C757D;\n$light-teal: #4ECDC4;\n$dark-teal: #005F5C;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
