import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Input } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './telegram.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const TelegramUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const users = useAppSelector(state => state.userManagement.users);
  const telegramEntity = useAppSelector(state => state.telegram.entity);
  const loading = useAppSelector(state => state.telegram.loading);
  const updating = useAppSelector(state => state.telegram.updating);
  const updateSuccess = useAppSelector(state => state.telegram.updateSuccess);

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      setMessage("🎉 New Sale Alert! 🎉\n\n🛍️ Shop Name: {CEN_SHOP_NAME}\n🆔 Shop ID: {CEN_SHOP_ID}\n📦 Items Sold: {QUANTITY}\n💵 Total Amount: {AMOUNT}\n\nCongratulations on your sale! 🎉");
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (!isNew && telegramEntity.message) {
      setMessage(telegramEntity.message);
    }
  }, [telegramEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const handleClose = () => {
    navigate('/telegram' + location.search);
  };

  const saveEntity = values => {
    const entity = {
      ...telegramEntity,
      ...values,
      message,
      user: users.find(it => it.id.toString() === values.user?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
        ...telegramEntity,
        user: telegramEntity?.user?.id,
      };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dmmtiktokApp.telegram.home.createOrEditLabel" data-cy="TelegramCreateUpdateHeading">
            <Translate contentKey="dmmtiktokApp.telegram.home.createOrEditLabel">Create or edit a Telegram</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="4">
          <b>Content code</b>
          <div>{`{SHOP_NAME}`} - Shop name</div>
          <div>{`{SHOP_ID}`} - Shop id</div>
          <div>{`{CEN_SHOP_NAME}`} - Shop name but censored with ****</div>
          <div>{`{CEN_SHOP_ID}`} - Shop id but censored with ****</div>
          <div>{`{AMOUNT}`} - Order total amount</div>
          <div>{`{SUB_AMOUNT}`} - Order total amount</div>
          <div>{`{QUANTITY}`} - Order items</div>
        </Col>
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="telegram-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dmmtiktokApp.telegram.botApiToken')}
                id="telegram-botApiToken"
                name="botApiToken"
                data-cy="botApiToken"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('dmmtiktokApp.telegram.chatId')}
                id="telegram-chatId"
                name="chatId"
                data-cy="chatId"
                type="number"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Input
                type="textarea"
                id="telegram-message"
                name="message"
                data-cy="message"
                rows="6"
                value={message}
                onChange={e => setMessage(e.target.value)} // Update state on change
              />
              {isAdmin ? (
                <ValidatedField id="telegram-user" name="user" data-cy="user" label={translate('dmmtiktokApp.telegram.user')} type="select">
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                    : null}
                </ValidatedField>
              ) : ''}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/telegram" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TelegramUpdate;
