import { useAppDispatch } from 'app/config/store';
import { IUIOrder } from 'app/shared/model/ui-order.model';
import { cleanObject } from 'app/shared/util';
import { ASC, DEFAULT_PAGE, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { searchEntities } from '../ui-order.reducer';

interface IFilterForm {
  timeCreatedFrom?: null;
  timeCreatedTo?: null;
  orderStatus?: string;
  status?: string;
  certificateOfAuthenticity?: string;
  buyerUsername?: string;
  product?: string;
  shopId?: string;
  keyword?: string;
}

interface IPaging {
  page?: number;
  size?: number;
  sort?: string;
  order?: string;
}

interface ISearch extends IFilterForm, IPaging {}

interface OrderContextType {
  ordersSelected: Array<IUIOrder>;
  setOrdersSelected: (orders: Array<IUIOrder>) => void;
  orderStatus: string;
  setOrderStatus: (order: string) => void;

  filterData: IFilterForm;
  setFilterData: (data: IFilterForm) => void;
  refreshList: () => void;
  handleSearch: (payload?: ISearch) => void;

  pagination?: IPaging;
  setPagination: (pagination: IPaging) => void;
}

const defaultOrderFilter: IFilterForm = {
  timeCreatedFrom: null,
  timeCreatedTo: null,
  status: '',
  orderStatus: '',
  certificateOfAuthenticity: '',
  buyerUsername: '',
  product: '',
  shopId: '',
  keyword: '',
};

export const OrderContext = createContext<OrderContextType | undefined>(undefined);
const defaultPaging = {
  page: DEFAULT_PAGE,
  size: ITEMS_PER_PAGE,
  sort: `createTime,desc`,
  order: DESC,
};
export const OrderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [ordersSelected, setOrdersSelected] = useState<Array<IUIOrder>>([]);
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [filterData, setFilterData] = useState<IFilterForm>(defaultOrderFilter);
  const [pagination, setPagination] = useState<IPaging>(defaultPaging);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const searchData = cleanObject({
      ...filterData,
      ...defaultPaging,
      status: orderStatus,
    });
    setPagination(defaultPaging);
    dispatch(searchEntities(searchData));
  }, [orderStatus]);

  const refreshList = () => {
    const searchData = cleanObject({
      ...filterData,
      ...pagination,
      status: orderStatus,
    });
    dispatch(searchEntities(searchData));
  };

  const handleSearch = (payload?: ISearch) => {
    const searchData = cleanObject({
      ...pagination,
      ...filterData,
      status: orderStatus,
      ...(payload || {}),
    });
    dispatch(searchEntities(searchData));
  };

  return (
    <OrderContext.Provider
      value={{
        ordersSelected,
        setOrdersSelected,
        orderStatus,
        setOrderStatus,
        filterData,
        setFilterData,
        refreshList,
        handleSearch,
        pagination,
        setPagination,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
