import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { buildVariantList } from '../product.reducer';
import { BlockTitle, FormLabel } from './common';
import './styles.scss';
import OptionsField from './variant-option';
import helper from '../helper';

const DoneView = ({ variantIndex, onEdit }) => {
  const { getValues } = useFormContext();
  const variationAttributes = getValues()['variationAttributes'][variantIndex];
  return (
    <Row className="">
      <Col>
        <FormLabel>{variationAttributes?.name}</FormLabel>
        <div className="d-flex gap-2">
          {variationAttributes?.values
            ?.filter(item => !!item.value)
            .map(({ value }) => (
              <span key={value} className="variant-item-viewmode">
                {value}
              </span>
            ))}
        </div>
      </Col>
      <Col className="d-flex justify-content-end align-items-start">
        <Button outline onClick={onEdit}>
          Edit
        </Button>
      </Col>
    </Row>
  );
};

const VariantItem = ({ attributeLength, variantIndex, removeVariant }) => {
  const [mode, setMode] = useState('edit');
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const handleDone = () => setMode('view');

  const handleAttributeChanged = () => {
    const { variationAttributes } = getValues();
    const variantData = helper.buildVariantsV1(variationAttributes);
    setValue('variantData', variantData);
  };

  return (
    <div style={{ marginBottom: '20px', background: 'rbga(0,0,0, 0.5)' }} className="border rounded py-3 px-3">
      {mode === 'view' && <DoneView variantIndex={variantIndex} onEdit={() => setMode('edit')} />}
      <div style={{ display: mode === 'edit' ? 'block' : 'none' }}>
        <Label className="mb-1 form-label" for={`variationAttributes[${variantIndex}].name`}>
          <span className="text-danger me-1">*</span>Variant Name
        </Label>
        <div className="d-flex align-items-center bg-white mb-2">
          <div style={{ flex: 1, marginRight: '10px' }}>
            <Controller
              control={control}
              name={`variationAttributes[${variantIndex}].name`}
              render={({ field }) => (
                <Input
                  {...field}
                  id={`variationAttributes[${variantIndex}].name`}
                  onChange={e => {
                    field.onChange(e);
                    handleAttributeChanged();
                  }}
                />
              )}
              rules={{ required: 'Please select or enter a variation' }}
            />
            {errors.variationAttributes?.[variantIndex]?.name && (
              <FormFeedback>{errors.variationAttributes?.[variantIndex]?.name.message}</FormFeedback>
            )}
          </div>
          <Button
            color="danger"
            onClick={() => removeVariant(variantIndex)}
            style={{ background: 'none', border: 'none', color: 'GrayText', padding: 0, marginRight: '10px' }}
            disabled={attributeLength === 1}
          >
            <FontAwesomeIcon className="cursor-pointer table-delete-icon" icon={faTrashAlt} size="lg" />
          </Button>
          <span style={{ cursor: 'grab', marginRight: '8px' }}>
            <p style={{ width: 14, height: 16 }} />
          </span>
        </div>
        <OptionsField variantIndex={variantIndex} onDone={handleDone} />
      </div>
    </div>
  );
};

const ProductVariantForm: React.FC = () => {
  const { control, getValues, setValue } = useFormContext();
  const {
    fields: attributesField,
    append: appendAttribute,
    remove: removeAttribute,
  } = useFieldArray({
    control,
    name: 'variationAttributes',
  });

  const addNewVariant = () => {
    appendAttribute({ name: '', values: [{ value: '' }] });
    const { variationAttributes } = getValues();
    const variantData = helper.buildVariantsV1(variationAttributes);
    setValue('variantData', variantData);
  };

  return (
    <div className="mb-4">
      <BlockTitle text="Sales Information" />
      {attributesField.map((variant, variantIndex) => (
        <VariantItem
          key={variant.id}
          attributeLength={attributesField?.length}
          variantIndex={variantIndex}
          removeVariant={removeAttribute}
        />
      ))}
      <div className="d-flex justify-content-end">
        <Button outline size="sm" color="primary" type="button" onClick={addNewVariant}>
          + Add Variant
        </Button>
      </div>
    </div>
  );
};

export default ProductVariantForm;
