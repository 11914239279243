export interface IPartnerApp {
  id?: string;
  name?: string | null;
  key?: string | null;
  secret?: string | null;
  region?: string | null;
  serviceId?: string | null;
  version?: number | null;
}

export const defaultValue: Readonly<IPartnerApp> = {};
