import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { fetchMetrics } from 'app/entities/dashboard/dashboard-metric.reducer';
import MetricsCard from 'app/entities/dashboard/metrics-card';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DashCard, { HightLightContent } from './DashCard';
import './dashboard.scss';
import './style.scss';

export const Dashboard = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('America/Los_Angeles');

  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const metrics = useAppSelector(state => state.metrics.entity);
  const loading = useAppSelector(state => state.metrics.loading);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().tz('America/Los_Angeles').startOf('day'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().tz('America/Los_Angeles').endOf('day'));

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(fetchMetrics({ startDate: startDate.unix(), endDate: endDate.unix(), shopId: null }));
    }
  }, [startDate, endDate]);

  const roundToHundredths = num => {
    if (typeof num !== "number") {
      return 0
    }
    const roundedNum = Math.round(num * 100) / 100;
    return `$${roundedNum.toFixed(2)}`;
  };

  const imageUrl = `https://toptopshop.sfo3.cdn.digitaloceanspaces.com/static/images/screenshot.webp`;
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Row>
          <Col xs={0} xl={6} xxl={7} />
          <Col xs={12} xl={6} xxl={5}>
            <div className='d-flex gap-2 justify-content-end'>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                timezone={'America/Los_Angeles'}
                label="Start Date"
                value={startDate}
                format={'YYYY-MM-DD'}
                maxDate={dayjs().tz('America/Los_Angeles')}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                timezone={'America/Los_Angeles'}
                label="End Date"
                value={endDate}
                format={'YYYY-MM-DD'}
                maxDate={dayjs().tz('America/Los_Angeles')}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </div>
          </Col>
        </Row>
        <div className="date-range-picker">
        </div>
      </LocalizationProvider>
      <Row>
        {isAuthenticated && (
          <Col xs="12" md="12">
            {loading ? (
              <div className="loading-container">
                <Spinner color="primary" />
                <p>Loading metrics...</p>
              </div>
            ) : (
              <Row className='g-3 equal-height-cols'>
                <DashCard
                  headerTitle="Gross Sales"
                  headerSubTitle="Total gross sales"
                  contentLeft={<HightLightContent contentLeft={roundToHundredths(metrics.grossSales)} />}
                />
                <DashCard
                  headerTitle="GMV with Tax"
                  headerSubTitle="Gross Merchandise Value with tax"
                  contentLeft={<HightLightContent contentLeft={roundToHundredths(metrics.gmvWithTax)} />}
                />
                <DashCard
                  headerTitle="GMV"
                  headerSubTitle="Gross Merchandise Value"
                  contentLeft={<HightLightContent contentLeft={roundToHundredths(metrics.gmv)} />}
                />
                <DashCard
                  headerTitle="Total Orders"
                  headerSubTitle="Total number of orders"
                  contentLeft={<HightLightContent contentLeft={metrics.totalOrders} />}
                />
                <DashCard
                  headerTitle="Ordered SKUs"
                  headerSubTitle="Number of ordered SKUs"
                  contentLeft={<HightLightContent contentLeft={metrics.orderedSKUs} />}
                />
                <DashCard
                  headerTitle="Buyers"
                  headerSubTitle="Total number of buyers"
                  contentLeft={<HightLightContent contentLeft={metrics.buyers} />}
                />
                <DashCard
                  headerTitle="First-Time Buyers"
                  headerSubTitle="Number of first-time buyers"
                  contentLeft={<HightLightContent contentLeft={metrics.firstTimeBuyers} />}
                />
                <DashCard
                  headerTitle="Average Order Value"
                  headerSubTitle="Average order value"
                  contentLeft={<HightLightContent contentLeft={roundToHundredths(metrics.averageOrderValue)} />}
                />
                <DashCard
                  headerTitle="Gross Revenue with TTS Subsidy"
                  headerSubTitle="Gross revenue with TTS subsidy"
                  contentLeft={<HightLightContent contentLeft={roundToHundredths(metrics.grossRevenueWithTTSSubsidy)} />}
                />
              </Row>
            )}
          </Col>
        )
        }
      </Row>
    </>
  );
};

export default Dashboard;
