import React, { FC, PropsWithChildren } from 'react'
import './style.scss'
interface SpinningProps {
    isLoading?: boolean
}
const Spinning: FC<PropsWithChildren<SpinningProps>> = ({ isLoading, children }) => {
    return (
        <div className="position-relative">
            {children}
            {isLoading && (
                <div className="loading-overlay d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Spinning;