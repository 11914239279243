import { IUser } from 'app/shared/model/user.model';

export interface ITelegram {
  id?: string;
  botApiToken?: string;
  chatId?: number;
  message?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<ITelegram> = {};
