import React, { FC, useState } from 'react';
import { Drawer, Button } from '@mui/material';
import { IUIOrder } from 'app/shared/model/ui-order.model';
import OrderDetail from './OrderDetail';
import "./style.scss";

const drawerWidth = '50vw';
interface OrderDetailDrawerProps {
    open?: boolean;
    onToggleDrawer?: () => void;
    order?: IUIOrder;
}
const OrderDetailDrawer: FC<OrderDetailDrawerProps> = ({ open, onToggleDrawer, order }) => {

    const handleToggleDrawer = () => onToggleDrawer();

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={handleToggleDrawer}
            PaperProps={{
                style: { width: drawerWidth },
            }}
        >
            <div
                role="presentation"
                className='order-detail-drawer'
            >
                <OrderDetail order={order} />
            </div>
        </Drawer>
    );
};

export default OrderDetailDrawer;
