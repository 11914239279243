import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FlashSaleSchedule from './flash-sale-schedule';
import FlashSaleScheduleDetail from './flash-sale-schedule-detail';
import FlashSaleScheduleUpdate from './flash-sale-schedule-update';
import FlashSaleScheduleDeleteDialog from './flash-sale-schedule-delete-dialog';

const FlashSaleScheduleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FlashSaleSchedule />} />
    <Route path="new" element={<FlashSaleScheduleUpdate />} />
    <Route path=":id">
      <Route index element={<FlashSaleScheduleDetail />} />
      <Route path="edit" element={<FlashSaleScheduleUpdate />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FlashSaleScheduleRoutes;
