/* eslint-disable @typescript-eslint/no-shadow */
import { Autocomplete, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getFilterEntities } from 'app/entities/shop/shop.reducer';
import React, { useEffect, useMemo, useState } from 'react';
const allOption = { name: 'All shop', id: '-1' };
const ShopAutocomplete = ({ onShopChange, ...props }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<typeof allOption>>();
  const dispatch = useAppDispatch();
  const shops = useAppSelector(state => state.shop.entities);

  const fullShop = useMemo(() => {
    return [allOption, ...shops];
  }, [shops]);

  useEffect(() => {
    dispatch(getFilterEntities());
  }, [dispatch]);

  return (
    <Autocomplete
      {...props}
      freeSolo
      multiple
      filterSelectedOptions
      id="combo-box-demo"
      options={fullShop?.map(option => ({ name: option.name, id: option.id }))}
      sx={{ width: 300 }}
      size="small"
      value={selectedOptions}
      onChange={(event: any, newValues: [{ name: string; id: string }] | null) => {
        let shopIds = [];
        let values = newValues;
        if (newValues) {
          shopIds = newValues.map(v => v.id);
          if (shopIds.includes(allOption.id)) {
            shopIds = shops.map(s => s.id);
            setSelectAll(true);
            values = [allOption];
          } else {
            setSelectAll(false);
          }
        }
        onShopChange(shopIds);
        setSelectedOptions(values);
      }}
      getOptionDisabled={option => {
        return selectAll;
      }}
      renderOption={(props, option, { selected }) => <li {...props}>{option.name}</li>}
      getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
      renderInput={params => (
        <TextField
          {...params}
          label="Shop"
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
        />
      )}
    />
  );
};

export default ShopAutocomplete;
