/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAppDispatch, useAppSelector } from 'app/config/store';
import PageHead from 'app/shared/components/PageHead';
import { IProductAttribute } from 'app/shared/model/local-product.model';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { Button, Form, Spinner } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from '../local-product/local-product.reducer';
import ProductBasicInfomation from './components/basic-info';
import Shipping from './components/shipping';
import ProductVariantForm from './components/variant-form';
import VariantList from './components/variant-list';
import { uploadFile } from './product.reducer';

type FormData = {
  productName: string;
  productDescription: string;
  variants: Array<any>;
  variantList: Record<string, any>;
  variationAttributes: Array<{ name: string; values: string[] }>;
  weightUnit: string;
  weight: number;
  packageHeight: number;
  packageWidth: number;
  packageLength: number;
  productAttributes: Array<{ id: string; name: string; values: Array<{ id: string; value: string }> }>;
};

const defaultValues = {
  productName: undefined,
  productDescription: undefined,
  variations: [],
  variantList: {},
  variationAttributes: [
    {
      name: '',
      values: [{ value: '' }],
    },
  ],
  weightUnit: 'lb',
  weight: undefined,
  packageHeight: undefined,
  packageWidth: undefined,
  packageLength: undefined,
};

type ImageObject = Record<string, string>;
export const ProductUpdate = () => {
  const { id } = useParams<'id'>();
  const loading = useAppSelector(state => state.product.loading);
  const updateSuccess = useAppSelector(state => state.localProduct.updateSuccess);
  const localProductEntity = useAppSelector(state => state.localProduct.entity);

  const methods = useForm<any>({ defaultValues });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isNew = id === undefined;

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, [isNew]);

  useEffect(() => {
    if (updateSuccess) {
      navigate('/product');
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (localProductEntity && localProductEntity.id) {
      const variationAttributes = [];
      for (const va of localProductEntity.variationAttributes) {
        const attribute = { ...va };
        attribute.values = va.values.map(item => ({ value: item }));
        variationAttributes.push(attribute);
      }
      const images = {
        mainProductImage: localProductEntity.mainProductImage,
      };
      Array.of(8).forEach((_, index) => {
        const key = `productImage${index + 2}`;
        images[key] = localProductEntity[key];
      });
      methods.reset({ ...localProductEntity, variationAttributes, images, variations: localProductEntity.variations });
    }
  }, [localProductEntity]);

  const uploadImage = async (file: File) => {
    const response = await dispatch(uploadFile(file));
    return (response.payload as any).data;
  };

  const uploadImages = async (images: Record<string, File | string>): Promise<Record<string, string>> => {
    const convertedImages: ImageObject = {};
    const validImages = Object.keys(images).filter(key => images[key]);
    for (const field of validImages) {
      if (images[field] && images[field] instanceof File) {
        convertedImages[field] = await uploadImage(images[field]);
      } else {
        convertedImages[field] = images[field] as string;
      }
    }
    return convertedImages;
  };

  const buildProductAttributes = (productAttributes: Array<IProductAttribute & { selectedId?: string | Array<string> }>) => {
    return productAttributes
      .filter(item => !!item.selectedId)
      .map(pattr => {
        return {
          id: pattr.id,
          name: pattr.name,
          values: pattr.values.filter(value =>
            typeof pattr.selectedId === 'string' ? value.id === pattr.selectedId : pattr.selectedId.includes(value.id),
          ),
        };
      });
  };

  const onSubmit = async ({ images, sizeChart, ...product }) => {
    const productAttributes = buildProductAttributes(product.productAttributes);
    const imagesUrl = await uploadImages(images);
    let sizeChartPayload = sizeChart;
    if (sizeChart instanceof File) {
      sizeChartPayload = await uploadImage(sizeChart);
    }
    product.variationAttributes.forEach(item => {
      item.values = item.values.filter(opt => opt.name).map(opt => opt.value);
    }, []);
    const entity = {
      ...product,
      ...imagesUrl,
      variantList: undefined,
      sizeChart: sizeChartPayload,
      status: 'active',
      productAttributes,
    };
    if (isNew) {
      await dispatch(createEntity(entity));
    } else {
      entity['id'] = id;
      dispatch(updateEntity(entity));
    }
  };

  return (
    <div style={{ background: 'rgb(245, 245, 245)' }} className="pt-4">
      <PageHead left={`${isNew ? 'Create' : 'Edit'} Product`} />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="bg-white rounded-4 p-4 mb-2">
            <ProductBasicInfomation />
          </div>
          <div className="bg-white rounded-4 p-4 mb-2">
            <ProductVariantForm />
            <VariantList />
          </div>
          <div className="bg-white rounded-4 p-4 mb-4">
            <Shipping />
          </div>
          <div className="d-flex gap-2">
            <Button disabled={loading} color="secondary" className="mb-4" onClick={() => navigate('/product')}>
              Cancel
            </Button>
            <Button disabled={loading} color="primary" type="submit" className="mb-4">
              {loading && <Spinner size="sm" />}
              {`${isNew ? 'Create' : 'Edit'} Product`}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ProductUpdate;
