/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { faCheckCircle, faLink, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, createMissingFlashSale } from 'app/entities/flash-sale-schedule/flash-sale-schedule.reducer';
import FlashSaleModal from 'app/entities/shop/flash-sale-modal';
import MissingFlashSaleModal from 'app/entities/shop/missing-flash-sale-modal';
import ShopAuthorizeModal from 'app/entities/shop/shop-authorize-modal';
import ShopMetricModal from 'app/entities/shop/shop-metric-modal';
import SyncModal from 'app/entities/shop/sync-modal';
import PageHead from 'app/shared/components/PageHead';
import PaginationComponent from 'app/shared/components/PaginationComponent';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getPaginationState, Translate } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Table } from 'reactstrap';
import { getEntities, partialUpdateEntity, syncAll, syncMetrics, syncOrders } from './shop.reducer';

const NoteInput = ({ defaultValue, shopId }) => {
  const [noteValue, setNoteValue] = useState<string>();
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(state => state.shop.updateSuccess);
  const loading = useAppSelector(state => state.shop.loading);

  useEffect(() => {
    if (updateSuccess) {
      setNoteValue(undefined);
    }
  }, [updateSuccess]);

  const handleSave = () => {
    dispatch(partialUpdateEntity({ note: noteValue, id: shopId }));
  };

  return (
    <div className="position-relative">
      <Input
        defaultValue={defaultValue}
        rows={5}
        className="tts-textarea"
        placeholder="Enter your note..."
        type="textarea"
        onChange={e => setNoteValue(e.target.value as string)}
      />
      {noteValue ? (
        <Button className="position-absolute top-0 end-0" outline color="success" onClick={handleSave} disabled={loading}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </Button>
      ) : null}
    </div>
  );
};

export const Shop = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [open, setOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [authorizeModalOpen, setAuthorizeModalOpen] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState<string | null>(null);
  const [actionType, setActionType] = useState('');
  const [flashSaleCreated, setFlashSaleCreated] = useState<string[]>([]);
  const [missingFlashSaleCreated, setMissingFlashSaleCreated] = useState<string[]>([]);
  const shopList = useAppSelector(state => state.shop.entities);
  const loading = useAppSelector(state => state.shop.loading);
  const totalItems = useAppSelector(state => state.shop.totalItems);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleSyncMetrics = () => {
    dispatch(syncMetrics());
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedShop(null);
  };

  const handleClickOpen = (shop, type) => {
    setSelectedShop(shop);
    setActionType(type);
    setOpen(true);
  };

  const handleCreateFlashSale = (discount, duration, auto) => {
    if (selectedShop && discount) {
      const numericDiscount = parseFloat(discount);
      const numericDuration = parseFloat(duration);
      dispatch(
        createEntity({
          shop: { id: selectedShop.id },
          discount: numericDiscount,
          duration: numericDuration,
          auto,
        }),
      );
      setFlashSaleCreated([...flashSaleCreated, selectedShop.id]);
    }
    handleClose();
  };

  const handleCreateMissingFlashSale = (discount, duration) => {
    if (selectedShop && discount) {
      const numericDiscount = parseFloat(discount);
      const numericDuration = parseFloat(duration);
      dispatch(
        createMissingFlashSale({
          shop: { id: selectedShop.id },
          discount: numericDiscount,
          duration: numericDuration,
        }),
      );
      setMissingFlashSaleCreated([...missingFlashSaleCreated, selectedShop.id]);
    }
    handleClose();
  };

  const handleOrderSync = shopId => {
    dispatch(syncOrders(shopId));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedShopId('');
  };

  const handleCloseSyncModal = () => {
    setSyncModalOpen(false);
    setSelectedShopId('');
  };

  const handleOpenModal = (shopId: string) => {
    setModalOpen(true);
    setSelectedShopId(shopId);
  };

  const handleSyncOpenModal = (shopId: string) => {
    setSyncModalOpen(true);
    setSelectedShopId(shopId);
  };

  const handleSyncAllOpenModal = selectedOptions => {
    const syncRequest = {
      shopIds: selectedShopId ? [selectedShopId] : shopList.map(shop => shop.id),
      ...selectedOptions,
    };
    dispatch(syncAll(syncRequest));
    setSyncModalOpen(false);
    setSelectedShopId(null);
  };

  const handleCloseAuthorizeModal = () => {
    setAuthorizeModalOpen(false);
  };

  const handleOpenAuthorizeModal = () => {
    setAuthorizeModalOpen(true);
  };

  const handlePageSizeChange = itemsPerPage => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
      itemsPerPage,
    });
  };

  return (
    <div>
      <PageHead left={<Translate contentKey="dmmtiktokApp.shop.home.title">Shops</Translate>}>
        <div className="d-flex gap-2">
          <Button color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="dmmtiktokApp.shop.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button color="success" onClick={() => setSyncModalOpen(true)} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Sync
          </Button>
          <Button onClick={() => handleOpenAuthorizeModal()} color="info" data-cy="entityCreateButton">
            <FontAwesomeIcon icon={faLink} />
            &nbsp;
            <Translate contentKey="dmmtiktokApp.shop.home.authorizationLink">Add More Shop</Translate>
          </Button>
        </div>
      </PageHead>
      <div className="table-responsive">
        {shopList && shopList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: '20%' }} className="hand" onClick={sort('code')}>
                  <Translate contentKey="dmmtiktokApp.shop.code">Code</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('code')} />
                </th>
                <th style={{ width: '20%' }} className="hand" onClick={sort('name')}>
                  <Translate contentKey="dmmtiktokApp.shop.name">Name</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
                </th>
                <th style={{ width: '10%' }}>Flash Sale</th>
                <th style={{ width: '15%' }}>Missing Flash Sale</th>
                <th style={{ width: '25%' }} className="hand" onClick={sort('name')}>
                  <span>Note</span>
                </th>
                <th style={{ width: '10%' }}>
                  <span>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {shopList.map((shop, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{shop.code}</td>
                  <td>{shop.name}</td>
                  <td>{flashSaleCreated.includes(shop.id) && <FontAwesomeIcon icon={faCheck} />}</td>
                  <td>{missingFlashSaleCreated.includes(shop.id) && <FontAwesomeIcon icon={faCheck} />}</td>
                  <td>
                    <NoteInput defaultValue={shop.note} shopId={shop.id} />
                  </td>
                  <td>
                    <div className="d-flex flex-column gap-1">
                      <Link className="tts-action-link" onClick={() => handleSyncOpenModal(shop.id)}>
                        <span className="tts-action-link">Sync</span>
                      </Link>
                      <Link className="tts-action-link" onClick={() => handleOpenModal(shop.id)}>
                        <span className="tts-action-link">View Metrics </span>
                      </Link>
                      <Link
                        className="tts-action-link"
                        onClick={() =>
                          (window.location.href = `/shop/${shop.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                      >
                        <span className="tts-action-link">Delete</span>
                      </Link>
                      <Link className="tts-action-link" onClick={() => handleClickOpen(shop, 'create')}>
                        <span className="tts-action-link">FlashSale</span>
                      </Link>
                      <Link className="tts-action-link" onClick={() => handleClickOpen(shop, 'missing')}>
                        <span className="tts-action-link">Missing</span>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="dmmtiktokApp.shop.home.notFound">No Shops found</Translate>
            </div>
          )
        )}
      </div>
      <PaginationComponent
        pageIndex={paginationState.activePage}
        totalRecords={totalItems}
        onPageChange={handlePagination}
        pageSize={paginationState.itemsPerPage}
        onPageSizeChange={handlePageSizeChange}
      />
      <FlashSaleModal
        open={open && actionType !== 'missing'}
        onClose={handleClose}
        onSubmit={handleCreateFlashSale}
        title={'Create Flash Sale'}
      />

      <MissingFlashSaleModal
        open={open && actionType === 'missing'}
        onClose={handleClose}
        onSubmit={handleCreateMissingFlashSale}
        title={'Create Missing Flash Sale'}
      />

      <ShopMetricModal open={modalOpen} id={selectedShopId} onClose={handleCloseModal} title={'View Shop Metrics'} />

      <ShopAuthorizeModal open={authorizeModalOpen} onClose={handleCloseAuthorizeModal} title={'Your authorization link'} />

      <SyncModal open={syncModalOpen} handleClose={handleCloseSyncModal} handleSync={handleSyncAllOpenModal} />
    </div>
  );
};

export default Shop;
