import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './base-cost.reducer';

export const BaseCostDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const baseCostEntity = useAppSelector(state => state.baseCost.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="baseCostDetailsHeading">
          <Translate contentKey="dmmtiktokApp.baseCost.detail.title">BaseCost</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{baseCostEntity.id}</dd>
          <dt>
            <span id="sku">
              <Translate contentKey="dmmtiktokApp.baseCost.sku">Sku</Translate>
            </span>
          </dt>
          <dd>{baseCostEntity.sku}</dd>
          <dt>
            <span id="typeValue">
              <Translate contentKey="dmmtiktokApp.baseCost.typeValue">Type</Translate>
            </span>
          </dt>
          <dd>{baseCostEntity.typeValue}</dd>
          <dt>
            <span id="colorValue">
              <Translate contentKey="dmmtiktokApp.baseCost.colorValue">Color</Translate>
            </span>
          </dt>
          <dd>{baseCostEntity.colorValue}</dd>
          <dt>
            <span id="sizeValue">
              <Translate contentKey="dmmtiktokApp.baseCost.sizeValue">Size</Translate>
            </span>
          </dt>
          <dt>
            <span id="sideValue">
              <Translate contentKey="dmmtiktokApp.baseCost.sideValue">Side</Translate>
            </span>
          </dt>
          <dd>{baseCostEntity.sizeValue}</dd>
          <dt>
            <span id="cost">
              <Translate contentKey="dmmtiktokApp.baseCost.cost">Cost</Translate>
            </span>
          </dt>
          <dd>{baseCostEntity.cost}</dd>
        </dl>
        <Button tag={Link} to="/base-cost" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/base-cost/${baseCostEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BaseCostDetail;
