import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './flash-sale-schedule.reducer';

export const FlashSaleScheduleDeleteDialog = ({ id, onClose }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, [dispatch, id]);

  const flashSaleScheduleEntity = useAppSelector(state => state.flashSaleSchedule.entity);
  const updateSuccess = useAppSelector(state => state.flashSaleSchedule.updateSuccess);

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess, loadModal]);

  const handleClose = () => {
    onClose();
    navigate('/flash-sale-schedule');
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(flashSaleScheduleEntity.id));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="flashSaleScheduleDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="dmmtiktokApp.flashSaleSchedule.delete.question">
        <Translate contentKey="dmmtiktokApp.flashSaleSchedule.delete.question" interpolate={{ id: flashSaleScheduleEntity.id }}>
          Are you sure you want to delete this Flash Sale Schedule?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-flashSaleSchedule" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FlashSaleScheduleDeleteDialog;
