// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sync-modal-header {
  background-color: #007bff;
  color: white;
  text-align: center;
}

.sync-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sync-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sync-modal-footer {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/shop/sync-modal.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF","sourcesContent":[".sync-modal-header {\n  background-color: #007bff;\n  color: white;\n  text-align: center;\n}\n\n.sync-modal-body {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.sync-option {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.sync-modal-footer {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
