/* eslint-disable @typescript-eslint/no-misused-promises */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { translate, Translate } from 'react-jhipster';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ILocalProduct } from 'app/shared/model/local-product.model';
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getProductTemplate, publishProducts } from '../../product.reducer';
import { FormLabel } from '../common';

const PRODUCT_SOURCES = ['amazon', 'etsy', 'ebay'];

interface ConfirmModalProps {
  isOpen?: boolean;
  products?: Array<ILocalProduct>;
  categoryName: string;
  onClose: () => void;
  methods: UseFormReturn<FieldValues, any, undefined>;
}
const ConfirmModal: FC<ConfirmModalProps> = ({ isOpen, products, categoryName, onClose, methods }) => {
  const shops = useAppSelector(state => state.shop.entities);
  const productTemplates = useAppSelector(state => state.product.productTemplates);
  const dispatch = useAppDispatch();

  const shopIds = useMemo(() => {
    const values = methods.getValues();
    return values?.shopIds;
  }, [isOpen]);

  useEffect(() => {
    if (!productTemplates) {
      dispatch(getProductTemplate());
    }
  }, []);

  const confirmPublish = () => {
    if (!products?.length) {
      toast.error('Select products in order to publish');
      return;
    }
    const data = methods.getValues();
    const productIds = products.map(p => p.id);
    const payload = { ...data, productIds } as any;
    return dispatch(publishProducts(payload));
  };

  const getShopName = (shopId: string) => {
    const shop = shops?.find(sh => sh.id === shopId);
    return (
      <div style={{ background: '#f5f5f5', padding: '4px 8px' }} className="rounded-3 hightlight">
        {shop?.name || ''}
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader toggle={onClose} data-cy="exportHistoryDeleteDialogHeading">
        Confirm publish product operation
      </ModalHeader>
      <ModalBody className="confirm-modal-content" id="dmmtiktokApp.exportHistory.delete.question">
        <div>
          <div className="mb-2">
            Are you sure you want to publish <strong className="hightlight">{products?.length || 0}</strong> products to {` `}
            <strong className="hightlight">{shopIds?.length || 0}</strong> shops at category{' '}
            <strong className="hightlight">{categoryName}</strong>?
          </div>
          <div className="mb-2">
            <strong>Shops:</strong>
          </div>
          <div className="mb-3 d-flex gap-2">{(shopIds || [])?.map(sId => getShopName(sId))}</div>
          <div className="mb-3">
            <strong>Products:</strong>
          </div>
          <div className="product-list-container">
            {products?.map(product => (
              <div className="position-relative d-flex rounded-2 mb-2 product-list" key={product.id}>
                <div className="">
                  <img height={100} src={product.mainProductImage} alt={product.productName} className="thumbnail-img" />
                </div>
                <div>{product.productName}</div>
                {product.source ? (
                  <div className="product-with-source position-absolute z-1 d-flex justify-content-center align-items-center">
                    <div>{product.source}</div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-exportHistory" data-cy="entityConfirmDeleteButton" color="primary" onClick={confirmPublish}>
          <FontAwesomeIcon icon={faBolt} />
          &nbsp;
          <Translate contentKey="entity.action.publish">Publish</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
