import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/estimate-cost';
import { getEntities, getEntity } from 'app/entities/partner-app/partner-app.reducer';
import { ITotalOrder } from 'app/shared/model/total-order';

const initialState: EntityState<ITotalOrder> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/dashboard';

export const fetchTotalOrders = createAsyncThunk(
  'totalOrder/fetch_total_order_list',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}/total-orders?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<ITotalOrder[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

// Function to fetch a single TotalOrder by ID
export const fetchTodayTotalOrders = createAsyncThunk(
  'totalOrder/fetch_today_total_order',
  async (pickedDate: string) => {
    const requestUrl = `${apiUrl}/today-total-orders?date=${pickedDate}`;
    return axios.get<ITotalOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const TotalOrderSlice = createEntitySlice({
  name: 'totalOrder',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchTodayTotalOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(fetchTotalOrders), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(fetchTodayTotalOrders, fetchTotalOrders), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = TotalOrderSlice.actions;

// Reducer
export default TotalOrderSlice.reducer;
