import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './flash-sale-schedule.reducer';

export const FlashSaleScheduleDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const flashSaleScheduleEntity = useAppSelector(state => state.flashSaleSchedule.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="flashSaleScheduleDetailsHeading">
          <Translate contentKey="dmmtiktokApp.flashSaleSchedule.detail.title">FlashSaleSchedule</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{flashSaleScheduleEntity.id}</dd>
          <dt>
            <span id="cron">
              <Translate contentKey="dmmtiktokApp.flashSaleSchedule.cron">Cron</Translate>
            </span>
          </dt>
          <dd>{flashSaleScheduleEntity.cron}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="dmmtiktokApp.flashSaleSchedule.status">Status</Translate>
            </span>
          </dt>
          <dd>{flashSaleScheduleEntity.status}</dd>
          <dt>
            <span id="discount">
              <Translate contentKey="dmmtiktokApp.flashSaleSchedule.discount">Discount</Translate>
            </span>
          </dt>
          <dd>{flashSaleScheduleEntity.discount}</dd>
          <dt>
            <span id="duration">
              <Translate contentKey="dmmtiktokApp.flashSaleSchedule.duration">Duration</Translate>
            </span>
          </dt>
          <dd>{flashSaleScheduleEntity.duration}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="dmmtiktokApp.flashSaleSchedule.description">Description</Translate>
            </span>
          </dt>
          <dd>{flashSaleScheduleEntity.description}</dd>
          <dt>
            <Translate contentKey="dmmtiktokApp.flashSaleSchedule.shop">Shop</Translate>
          </dt>
          <dd>{flashSaleScheduleEntity.shop ? flashSaleScheduleEntity.shop.id : ''}</dd>
          <dt>
            <Translate contentKey="dmmtiktokApp.flashSaleSchedule.user">User</Translate>
          </dt>
          <dd>{flashSaleScheduleEntity.user ? flashSaleScheduleEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/flash-sale-schedule" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/flash-sale-schedule/${flashSaleScheduleEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default FlashSaleScheduleDetail;
