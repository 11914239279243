import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IEstimateCost } from 'app/shared/model/estimate-cost';

const initialState: EntityState<IEstimateCost> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/dashboard';

export const fetchEstimateCosts = createAsyncThunk(
  'partnerApp/fetch_estimate_cost_list',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}/estimate-costs?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IEstimateCost[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const fetchTodayEstimateCosts = createAsyncThunk(
  'partnerApp/fetch_today_estimate_cost',
  async (pickedDate: string) => {
    const requestUrl = `${apiUrl}/today-estimate-costs?date=${pickedDate}`;
    return axios.get<IEstimateCost>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const EstimateCostSlice = createEntitySlice({
  name: 'estimateCost',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchTodayEstimateCosts.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(fetchEstimateCosts), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(fetchTodayEstimateCosts, fetchEstimateCosts), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = EstimateCostSlice.actions;

// Reducer
export default EstimateCostSlice.reducer;
