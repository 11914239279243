import { IShop } from 'app/shared/model/shop.model';

export interface ILocalProduct {
  id?: string;
  brand?: string | null;
  productName?: string | null;
  productDescription?: string | null;
  packageWeight?: string | null;
  packageLength?: string | null;
  packageWidth?: string | null;
  packageHeight?: string | null;
  deliveryOptions?: string | null;
  mainProductImage?: string | null;
  productImage2?: string | null;
  productImage3?: string | null;
  productImage4?: string | null;
  productImage5?: string | null;
  productImage6?: string | null;
  productImage7?: string | null;
  productImage8?: string | null;
  productImage9?: string | null;
  sizeChart?: string | null;
  productStatus?: string | null;
  printFileBack?: string | null;
  printFileFront?: string | null;
  printPosition?: string | null;
  productType?: string | null;
  templateName?: string | null;
  lastModifiedDate?: Date | null;
  shops?: IShop[] | null;
  variations?: Array<any>;
  source?: string;
  sellerSku?: string;
}


export interface IVariantData {
  variantColumnNames: Array<string>,
  columnNames: Array<string>,
  tableData: Array<any>,
}
export interface IProductAttribute {
  id: string,
  is_customizable: boolean,
  is_multiple_selection: boolean,
  is_requried: boolean,
  name: string,
  type: string,
  values: [
    {
      id: string,
      name: string
    }
  ],
  customizable: boolean,
  multipleSelection: boolean,
  requried: boolean
}

export interface IProductCategory {
  id: string,
  is_leaf: false,
  local_name: string,
  parent_id: string,
  permission_statuses: Array<string>
}

export const defaultValue: Readonly<ILocalProduct> = {};
