import React, { useState } from 'react';
import { Row, Col, Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './style.scss';

const recordPerPageOptions = [
  { label: '20 records', value: 20 },
  { label: '50 records', value: 50 },
  { label: '100 records', value: 100 },
];

interface PaginationProps {
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
}

const PaginationComponent: React.FC<PaginationProps> = ({ pageIndex, pageSize, totalRecords, onPageChange, onPageSizeChange }) => {
  const [currentRecordPerPage, setCurrentRecordPerPage] = useState(recordPerPageOptions[0].label);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const start = totalRecords === 0 ? 0 : pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize;
  const end = Math.min((pageIndex + 1) * pageSize, totalRecords);
  const totalPages = Math.ceil(totalRecords / pageSize);
  // console.log("totalPages", totalPages)
  // console.log("pageIndex", pageIndex)
  // console.log("pageSize", pageSize)

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const handleChangePageSize = item => {
    onPageSizeChange(item.value);
    setCurrentRecordPerPage(item.label);
    toggle();
  };

  return (
    <Row className="align-items-center">
      <Col xs={12} md={6} className="mb-2 mb-md-0">
        Showing {start} to {end} of {totalRecords} results
      </Col>
      <Col xs={12} md={6}>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <div className="d-flex gap-2 align-items-center">
            <div>
              <span>Items per page</span>
            </div>
            <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggle}>
              <DropdownToggle caret outline>
                {currentRecordPerPage}
              </DropdownToggle>
              <DropdownMenu right>
                {recordPerPageOptions.map((item, index) => (
                  <DropdownItem key={item.label} onClick={() => handleChangePageSize(item)}>
                    {item.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div>
            <Button
              size="sm"
              outline
              color="primary"
              onClick={() => onPageChange(pageIndex - 1)}
              disabled={totalRecords === 0 || pageIndex === 0}
            >
              Previous
            </Button>
          </div>
          <div>
            <Button
              size="sm"
              outline
              color="primary"
              onClick={() => onPageChange(pageIndex + 1)}
              disabled={totalRecords === 0 || pageIndex === totalPages}
            >
              Next
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PaginationComponent;
