/* eslint-disable @typescript-eslint/no-misused-promises */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ILocalProduct } from 'app/shared/model/local-product.model';
import { toast } from 'react-toastify';
import { deleteProducts } from '../../product.reducer';
import { useFormContext } from 'react-hook-form';

interface ConfirmModalProps {
  isOpen?: boolean;
  products?: Array<ILocalProduct>;
  onClose: (clearSelected: boolean) => void;
}
const ConfirmDeleteProductsModal: FC<ConfirmModalProps> = ({ isOpen, products, onClose }) => {
  const dispatch = useAppDispatch();
  const deleteProductsStatus = useAppSelector(state => state.product.deleteProductsStatus);
  const { watch } = useFormContext();
  const productSelected = watch('productSelected');

  useEffect(() => {
    if (deleteProductsStatus === 'success') {
      onClose(true);
    }
  }, [deleteProductsStatus]);

  const confirmDelete = () => {
    if (!products?.length) {
      toast.error('Select products in order to delete');
      return;
    }
    const productIds = products.map(p => p.id);
    return dispatch(deleteProducts(productIds));
  };

  const handleCloseModal = () => {
    onClose(true);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCloseModal} size="lg">
      <ModalHeader toggle={handleCloseModal} data-cy="exportHistoryDeleteDialogHeading">
        Confirm delete products operation
      </ModalHeader>
      <ModalBody className="confirm-modal-content" id="dmmtiktokApp.exportHistory.delete.question">
        <div>
          <div className="mb-2">
            Are you sure you want to delete <strong style={{ color: 'red' }}>{productSelected?.length || 0}</strong> products below:
          </div>
          <div className="mb-3">
            <strong>Products:</strong>
          </div>
          <div className="product-list-container">
            {products?.map(product => (
              <div className="position-relative d-flex rounded-2 mb-2 product-list" key={product.id}>
                <div className="">
                  <img height={100} src={product.mainProductImage} alt={product.productName} className="thumbnail-img" />
                </div>
                <div>{product.productName}</div>
                {product.source ? (
                  <div className="product-with-source position-absolute z-1 d-flex justify-content-center align-items-center">
                    <div>{product.source}</div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={deleteProductsStatus === 'pending'} color="secondary" onClick={handleCloseModal}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button
          disabled={deleteProductsStatus === 'pending'}
          id="jhi-confirm-delete-exportHistory"
          data-cy="entityConfirmDeleteButton"
          color="primary"
          onClick={confirmDelete}
        >
          <FontAwesomeIcon icon={faBolt} />
          &nbsp;
          <span>Delete</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmDeleteProductsModal;
