import { IProductAttribute } from "app/shared/model/local-product.model";
import { ILocalVariation } from "app/shared/model/local-variation.model";

export default interface IProductTemplate {
    id?: string,
    name?: string,
    productDescription?: string,
    weightUnit?: number | null,
    packageWeight?: string,
    packageLength?: string,
    packageWidth?: string,
    packageHeight?: string,
    deliveryOptions?: string | null,
    sizeChart?: string | null,
    variationAttributes?: Array<ILocalVariation>;
    variations?: Array<any>;
    productAttributes?: Array<IProductAttribute>;
    user?: any
}

export const defaultValue: Readonly<IProductTemplate> = {};