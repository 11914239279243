/* eslint-disable @typescript-eslint/no-misused-promises */
import FilterListIcon from '@mui/icons-material/FilterList';
import ImportIcon from '@mui/icons-material/ImportExport';
import SortIcon from '@mui/icons-material/Sort';
import ExportIcon from '@mui/icons-material/UploadFile';
import { Menu, MenuItem, Radio } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IShop } from 'app/shared/model/shop.model';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Input } from 'reactstrap';
import FilterDrawer from '../../filter-drawer';
import { bulkCreateLabel, bulkGetLabel, exportOrders, uploadFile } from '../../ui-order.reducer';
import ImportTrackingModal from '../import-tracking-modal/import-tracking-modal';
import { OrderContext } from '../OrderContext';

const sortOptions = [
  { key: 'rtsSlaTime,asc', value: 'Ship by date (oldest first)' },
  { key: 'rtsSlaTime,desc', value: 'Ship by date (newest first)' },
  { key: 'paidTime,asc', value: 'Time paid (oldest first)' },
  { key: 'paidTime,desc', value: 'Time paid (newest first)' },
  { key: 'createTime,asc', value: 'Time Created (oldest first)' },
  { key: 'createTime,desc', value: 'Time Created (newest first)' },
  { key: 'total,asc', value: 'Total Price (lowest first)' },
  { key: 'total,desc', value: 'Total Price (highest first)' },
];

const OrderActions = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [openImportTrackingModal, setOpenImportTrackingModal] = useState(false);
  const [sortCriteria, setSortCriteria] = useState('id,asc');
  const loading = useAppSelector(state => state.uIOrder.loading);
  const { filterData, ordersSelected, setFilterData, handleSearch, setPagination, pagination, setOrdersSelected } =
    useContext(OrderContext);
  const shops = useAppSelector<Array<IShop>>(state => state.shop.entities);
  const dispatch = useAppDispatch();

  const shopName = useMemo(() => {
    if (shops?.length && filterData?.shopId) {
      const shop = shops?.find(s => s.id === filterData.shopId);
      return shop?.name || '';
    }
    return '';
  }, [filterData?.shopId, shops]);

  const dateRangeLabel = useMemo(() => {
    let text = '';
    if (filterData?.timeCreatedFrom) {
      text += moment(filterData.timeCreatedFrom).format('DD/MM/YYYY');
    }
    if (filterData?.timeCreatedTo) {
      text += ` - ${moment(filterData.timeCreatedTo).format('DD/MM/YYYY')}`;
    }
    return text;
  }, [filterData?.timeCreatedFrom, filterData?.timeCreatedTo]);

  const handleDrawerOpen = () => setFilterDrawerOpen(true);

  const handleDrawerClose = () => setFilterDrawerOpen(false);

  const handleSortClose = () => setAnchorEl(null);

  const handleSortChange = (option: string) => {
    setSortCriteria(option);
    setAnchorEl(null);
    const newPaging = { ...pagination, sort: sortCriteria };
    setPagination(newPaging);
    handleSearch(newPaging);
  };

  const handleApplyFilters = data => {
    const newFilter = { ...filterData, ...data };
    setFilterData(newFilter);
    handleSearch(newFilter);
  };

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExport = async () => {
    const exportIds = ordersSelected?.map(order => order.id);
    if (exportIds?.length) {
      await dispatch(exportOrders(exportIds));
      setOrdersSelected([]);
    } else {
      toast.warning('Select order to export');
    }
  };

  const handleFileChange = file => {
    if (file && file.type === 'text/csv') {
      dispatch(uploadFile(file));
      (document.getElementById('fileInput') as HTMLInputElement).value = ''; // Clear file input value
    } else {
      toast.warning('Only CSV files are allowed');
    }
  };

  const handleBulkCreateLabel = async () => {
    const exportIds = ordersSelected?.map(order => order.id);
    if (exportIds?.length) {
      await dispatch(bulkCreateLabel(exportIds));
      setOrdersSelected([]);
    } else {
      toast.warning('Select order to create label');
    }
  };

  const handleBulkGetLabel = async () => {
    const exportIds = ordersSelected?.map(order => order.id);
    if (exportIds?.length) {
      await dispatch(bulkGetLabel(exportIds));
      setOrdersSelected([]);
    } else {
      toast.warning('Select order to get label');
    }
  };

  return (
    <>
      <div className="d-flex gap-4 align-items-center mb-2">
        <Button size="sm" outline startIcon={<FilterListIcon />} onClick={handleDrawerOpen}>
          Filter
        </Button>
        <Button size="sm" outline startIcon={<SortIcon />} sx={{ ml: 2 }} onClick={handleSortClick}>
          Sort by
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleSortClose}>
          {sortOptions.map(option => (
            <MenuItem key={option.key} onClick={() => handleSortChange(option.key)}>
              <Radio
                checked={sortCriteria === option.key}
                onChange={() => handleSortChange(option.key)}
                value={option.key}
                name="sort-criteria"
                inputProps={{ 'aria-label': option.value }}
              />
              {option.value}
            </MenuItem>
          ))}
        </Menu>
        <Button size="sm" outline startIcon={<ExportIcon />} sx={{ ml: 2 }} onClick={handleExport}>
          Export
        </Button>
        <Input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" accept=".csv" />
        <Button
          size="sm"
          outline
          startIcon={<ImportIcon />}
          sx={{ ml: 2 }}
          disabled={loading}
          onClick={() => setOpenImportTrackingModal(true)}
        >
          Import Tracking
        </Button>
        <Button size="sm" outline onClick={handleBulkCreateLabel}>
          Bulk Create Label
        </Button>
        <Button size="sm" outline onClick={handleBulkGetLabel}>
          Bulk Get Label
        </Button>
      </div>
      {(dateRangeLabel || filterData.orderStatus || shopName || filterData.buyerUsername || filterData.product) && (
        <div className="mb-4">
          <div>Filter by:</div>
          <div className="d-flex gap-2 flex-wrap ">
            {dateRangeLabel && (
              <div className="order-filter-item">
                Date: <strong>{dateRangeLabel}</strong>
              </div>
            )}
            {filterData.orderStatus && (
              <div className="order-filter-item">
                Status: <strong>{filterData.orderStatus}</strong>
              </div>
            )}
            {filterData.shopId && (
              <div className="order-filter-item">
                Shop: <strong>{shopName}</strong>
              </div>
            )}
            {filterData.buyerUsername && (
              <div className="order-filter-item">
                Buyer Name: <strong>{filterData.buyerUsername}</strong>
              </div>
            )}
            {filterData.product && (
              <div className="order-filter-item">
                Product: <strong>{filterData.product}</strong>
              </div>
            )}
          </div>
        </div>
      )}
      <FilterDrawer open={filterDrawerOpen} onClose={handleDrawerClose} onApply={handleApplyFilters} shops={shops} />
      <ImportTrackingModal
        isOpen={openImportTrackingModal}
        onClose={() => setOpenImportTrackingModal(false)}
        onFileUpload={handleFileChange}
      />
    </>
  );
};

export default OrderActions;
