import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import IProductTemplate, { defaultValue } from 'app/shared/model/product-template';
import { createEntitySlice, EntityState, IQueryParams, ISearchParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const apiPath = "/api/product-templates";
export const searchEntities = createAsyncThunk(
    'product-template/fetch_entity_list',
    async ({ page, size, sort, keyword }: ISearchParams) => {
        const requestUrl = `${apiPath}?keyword=${keyword}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
        return axios.get<Array<IProductTemplate>>(requestUrl);
    },
    { serializeError: serializeAxiosError },
);
export const getEntities = createAsyncThunk(
    'product-template/fetch_entity_list',
    async ({ page, size, sort }: IQueryParams) => {
        const requestUrl = `${apiPath}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
        return axios.get<Array<IProductTemplate>>(requestUrl);
    },
    { serializeError: serializeAxiosError },
);
export const getEntity = createAsyncThunk(
    'product-templates/fetch_entity',
    async (id: string | number) => {
        const requestUrl = `${apiPath}/${id}`;
        return axios.get<IProductTemplate>(requestUrl);
    },
    { serializeError: serializeAxiosError },
);
export const createEntity = createAsyncThunk(
    'product-templates/create_entity',
    async (entity: IProductTemplate) => {
        const result = await axios.post<IProductTemplate>(apiPath, cleanEntity(entity));
        return result;
    },
    { serializeError: serializeAxiosError },
);
export const updateEntity = createAsyncThunk(
    'product-templates/update_entity',
    async (entity: IProductTemplate) => {
        const requestUrl = `${apiPath}/${entity.id}`;
        const result = await axios.put<IProductTemplate>(requestUrl, cleanEntity(entity));
        return result;
    },
    { serializeError: serializeAxiosError },
);
export const partialUpdateEntity = createAsyncThunk(
    'product-templates/partial_update_entity',
    async (entity: IProductTemplate) => {
        const requestUrl = `${apiPath}/${entity.id}`;
        const result = await axios.patch<IProductTemplate>(requestUrl, cleanEntity(entity));
        return result;
    },
    { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
    'product-templates/delete_entity',
    async (id: string | number) => {
        const requestUrl = `${apiPath}/${id}`;
        const result = await axios.delete<IProductTemplate>(requestUrl);
        return result;
    },
    { serializeError: serializeAxiosError },
);

interface IProductTemplateState extends EntityState<IProductTemplate> {
    deleteStatus?: string;
}

const initialState: IProductTemplateState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
    deleteStatus: 'edle',
}
export const ProductTemplateSlice = createSlice({
    name: 'productTemplate',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data, headers } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                    totalItems: parseInt(headers['x-total-count'], 10),
                };
            })
            .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload.data;
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            })
            // delete
            .addMatcher(isPending(deleteEntity), state => {
                state.deleteStatus = "pending";
            })
            .addMatcher(isFulfilled(deleteEntity), state => {
                state.deleteStatus = "success";
            })
            .addMatcher(isRejected(deleteEntity), state => {
                state.deleteStatus = "failed";
            });
    },
});

export const { reset } = ProductTemplateSlice.actions;

// Reducer
export default ProductTemplateSlice.reducer;
