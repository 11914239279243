import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ILocalProduct } from 'app/shared/model/local-product.model';
import { getEntity, updateEntity, createEntity, reset } from './local-product.reducer';

export const LocalProductUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const localProductEntity = useAppSelector(state => state.localProduct.entity);
  const loading = useAppSelector(state => state.localProduct.loading);
  const updating = useAppSelector(state => state.localProduct.updating);
  const updateSuccess = useAppSelector(state => state.localProduct.updateSuccess);

  const handleClose = () => {
    navigate('/local-product' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    const entity = {
      ...localProductEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...localProductEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dmmtiktokApp.localProduct.home.createOrEditLabel" data-cy="LocalProductCreateUpdateHeading">
            <Translate contentKey="dmmtiktokApp.localProduct.home.createOrEditLabel">Create or edit a LocalProduct</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="local-product-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.brand')}
                id="local-product-brand"
                name="brand"
                data-cy="brand"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productName')}
                id="local-product-productName"
                name="productName"
                data-cy="productName"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productDescription')}
                id="local-product-productDescription"
                name="productDescription"
                data-cy="productDescription"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.packageWeight')}
                id="local-product-packageWeight"
                name="packageWeight"
                data-cy="packageWeight"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.packageLength')}
                id="local-product-packageLength"
                name="packageLength"
                data-cy="packageLength"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.packageWidth')}
                id="local-product-packageWidth"
                name="packageWidth"
                data-cy="packageWidth"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.packageHeight')}
                id="local-product-packageHeight"
                name="packageHeight"
                data-cy="packageHeight"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.deliveryOptions')}
                id="local-product-deliveryOptions"
                name="deliveryOptions"
                data-cy="deliveryOptions"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.mainProductImage')}
                id="local-product-mainProductImage"
                name="mainProductImage"
                data-cy="mainProductImage"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage2')}
                id="local-product-productImage2"
                name="productImage2"
                data-cy="productImage2"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage3')}
                id="local-product-productImage3"
                name="productImage3"
                data-cy="productImage3"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage4')}
                id="local-product-productImage4"
                name="productImage4"
                data-cy="productImage4"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage5')}
                id="local-product-productImage5"
                name="productImage5"
                data-cy="productImage5"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage6')}
                id="local-product-productImage6"
                name="productImage6"
                data-cy="productImage6"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage7')}
                id="local-product-productImage7"
                name="productImage7"
                data-cy="productImage7"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage8')}
                id="local-product-productImage8"
                name="productImage8"
                data-cy="productImage8"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productImage9')}
                id="local-product-productImage9"
                name="productImage9"
                data-cy="productImage9"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.sizeChart')}
                id="local-product-sizeChart"
                name="sizeChart"
                data-cy="sizeChart"
                type="text"
              />
              <ValidatedField
                label={translate('dmmtiktokApp.localProduct.productStatus')}
                id="local-product-productStatus"
                name="productStatus"
                data-cy="productStatus"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/local-product" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LocalProductUpdate;
