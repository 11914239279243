export interface IBaseCost {
  id?: string;
  sku?: string | null;
  typeValue?: string | null;
  colorValue?: string | null;
  sizeValue?: string | null;
  sideValue?: string | null;
  cost?: number | null;
}

export const defaultValue: Readonly<IBaseCost> = {};
