import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React, { FC, ReactElement } from 'react'
import { Col, ColProps, Row } from 'reactstrap';

export const HightLightContent = ({ contentLeft }) => {
    return (
        <span className='hightlight-content-left'>{contentLeft}</span>
    )
}

interface IDashCard extends ColProps {
    headerTitle: string | ReactElement;
    headerSubTitle?: string | ReactElement;
    footerTitle?: string | ReactElement;
    percentage?: number;
    contentLeft?: number | ReactElement;
}
const DashCard: FC<IDashCard> = ({ headerTitle, headerSubTitle = null, footerTitle, percentage, contentLeft, ...colProps }) => {
    return (
        <Col sm={12} lg={6} xl={4} {...colProps}>
            <div className='d-flex flex-column dash-card'>
                <Row className='card-row'>
                    <Col sm={10}>
                        <div><span className='header-title'>{headerTitle}</span></div>
                        <div>
                            <span className='header-sub-title'>
                                {headerSubTitle}
                            </span>
                        </div>
                    </Col>
                    <Col sm={2} className="d-flex justify-content-end" style={{ paddingTop: 5 }}>
                        <Tooltip title="Expand this metric">
                            <FontAwesomeIcon className='expand-metric' icon={faExpand} />
                        </Tooltip>
                    </Col>
                </Row>
                <Row className='card-row'>
                    <Col>
                        {contentLeft}
                    </Col>
                </Row>
                {footerTitle && <Row className='card-row'>
                    <Col>
                        <span>{footerTitle}</span>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <span className={`${percentage === 0 ? 'nonpositive' : percentage > 0 ? "positive" : "nagative"}`}>{percentage}</span>
                    </Col>
                </Row>}
            </div>
        </Col>
    )
}

export default DashCard;