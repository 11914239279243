import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import PaginationComponent from 'app/shared/components/PaginationComponentV1';
import EmptyTable from 'app/shared/components/Table/EmptyTable';
import { IUIOrder } from 'app/shared/model/ui-order.model';
import { ASC, DESC } from 'app/shared/util/pagination.constants';
import React, { FC, memo, useContext, useState } from 'react';
import { Translate } from 'react-jhipster';
import { useLocation } from 'react-router';
import { Table } from 'reactstrap';
import { OrderContext } from '../OrderContext';
import OrderDetailDrawer from '../OrderDetailDrawer';

interface OrderTableProps {}
const OrderTable: FC<OrderTableProps> = () => {
  const pageLocation = useLocation();
  // const [paginationState, setPaginationState] = useState(
  //   overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  // );
  const { ordersSelected, setOrdersSelected, pagination, setPagination, handleSearch } = useContext(OrderContext);
  const [activeOrder, setActiveOrder] = useState<IUIOrder>();
  const [openOrderDetailDrawer, setOpenOrderDetailDrawer] = useState<boolean>(false);
  // store
  const orders = useAppSelector(state => state.uIOrder.entities);
  const loading = useAppSelector(state => state.uIOrder.loading);
  const totalItems = useAppSelector(state => state.uIOrder.totalItems);

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = pagination.sort;
    const order = pagination.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const sort = p => () => {
    const newPaging = { ...pagination, sort: p, order: pagination?.order === ASC ? DESC : ASC };
    setPagination(newPaging);
    handleSearch(newPaging);
  };

  const handleSelectAll = event => {
    if (event.target.checked) {
      setOrdersSelected(orders);
    } else {
      setOrdersSelected([]);
    }
  };

  const handleSelectOrder = (event, order) => {
    if (event.target.checked) {
      setOrdersSelected([...ordersSelected, order]);
    } else {
      setOrdersSelected(ordersSelected.filter(o => o.id !== order.id));
    }
  };

  const handlePagination = currentPage => {
    setPagination({
      ...pagination,
      page: currentPage,
    });
    handleSearch({
      ...pagination,
      page: currentPage,
    });
  };

  const toggleDetailOrderDrawer = order => {
    setOpenOrderDetailDrawer(true);
    setActiveOrder(order);
  };

  const handlePageSizeChange = size => {
    setPagination({
      ...pagination,
      page: 0,
      size,
    });
    handleSearch({
      ...pagination,
      page: 0,
      size,
    });
  };

  return (
    <div>
      <div className="table-responsive">
        <Table responsive>
          <thead>
            <tr>
              <th>
                <input type="checkbox" checked={orders?.length && ordersSelected?.length === orders?.length} onChange={handleSelectAll} />
              </th>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="dmmtiktokApp.uIOrder.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
              </th>
              <th className="hand" onClick={sort('shopName')}>
                <Translate contentKey="dmmtiktokApp.uIOrder.buyer">Buyer</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('buyer')} />
              </th>
              <th className="hand">
                <Translate contentKey="dmmtiktokApp.uIOrder.items">Items</Translate>{' '}
              </th>
              <th className="hand" onClick={sort('status')}>
                <Translate contentKey="dmmtiktokApp.uIOrder.status">Order status</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('status')} />
              </th>
              <th className="hand" onClick={sort('shippingType')}>
                <Translate contentKey="dmmtiktokApp.uIOrder.shippingType">Shipping method</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('shippingType')} />
              </th>
              <th className="hand" onClick={sort('deliveryOptionName')}>
                <Translate contentKey="dmmtiktokApp.uIOrder.deliveryOptionName">Delivery option</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('deliveryOptionName')} />
              </th>
              <th className="hand" onClick={sort('total')}>
                <Translate contentKey="dmmtiktokApp.uIOrder.total">Total</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('total')} />
              </th>
              <th>Shop</th>
            </tr>
          </thead>
          <tbody className="position-relative">
            {loading && (
              <div className="table-data-loading position-absolute w-100 h-100 z-3 d-flex justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {orders?.length
              ? orders.map((uIOrder, i) => (
                  <React.Fragment key={`entity-${i}`}>
                    <tr data-cy="entityTable" style={{ cursor: 'pointer' }} onClick={() => toggleDetailOrderDrawer(uIOrder)}>
                      <td onClick={e => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={ordersSelected.map(o => o.id).includes(uIOrder.id)}
                          onChange={e => handleSelectOrder(e, uIOrder)}
                        />
                      </td>
                      <td>
                        <span>{uIOrder.id}</span>
                      </td>
                      <td>
                        <span>{uIOrder.buyer}</span>
                      </td>
                      <td>
                        <span>{uIOrder.lineItems.length}</span>
                      </td>
                      <td>
                        <span>{uIOrder.status}</span>
                      </td>
                      <td>
                        <span>{uIOrder.shippingType}</span>
                      </td>
                      <td>
                        <span>{uIOrder.deliveryOptionName}</span>
                      </td>
                      <td>
                        <span>{uIOrder.total}</span>
                      </td>
                      <td>
                        <span>{uIOrder.shop.name}</span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              : null}
          </tbody>
        </Table>
        {orders && !orders.length ? <EmptyTable /> : null}
      </div>
      <PaginationComponent
        pageIndex={pagination.page}
        totalRecords={totalItems}
        onPageChange={handlePagination}
        pageSize={pagination.size}
        onPageSizeChange={handlePageSizeChange}
      />
      <OrderDetailDrawer
        open={openOrderDetailDrawer}
        onToggleDrawer={() => setOpenOrderDetailDrawer(!openOrderDetailDrawer)}
        order={activeOrder}
      />
    </div>
  );
};

export default memo(OrderTable);
