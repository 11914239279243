import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SellerAuthorization from './seller-authorization';
import SellerAuthorizationDetail from './seller-authorization-detail';
import SellerAuthorizationUpdate from './seller-authorization-update';
import SellerAuthorizationDeleteDialog from './seller-authorization-delete-dialog';

const SellerAuthorizationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SellerAuthorization />} />
    <Route path="new" element={<SellerAuthorizationUpdate />} />
    <Route path=":id">
      <Route index element={<SellerAuthorizationDetail />} />
      <Route path="edit" element={<SellerAuthorizationUpdate />} />
      <Route path="delete" element={<SellerAuthorizationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SellerAuthorizationRoutes;
