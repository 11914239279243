import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import React, { useContext, useState } from 'react';
import { Translate } from 'react-jhipster';
import { Button, Input } from 'reactstrap';
import { OrderContext } from '../OrderContext';

const OrderPageHeader = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const loading = useAppSelector(state => state.uIOrder.loading);
  const { refreshList, handleSearch } = useContext(OrderContext);

  const handleSyncList = () => refreshList();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission behavior
      handleSearch({ keyword: searchKeyword });
    }
  };

  return (
    <div className="d-flex gap-2">
      <div className="bg-white" style={{ borderRadius: 8 }}>
        <Input
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Orders"
          inputProps={{ 'aria-label': 'search order' }}
          value={searchKeyword}
          onChange={handleSearchChange}
          onKeyDown={handleKeyPress}
        />
      </div>
      <Button size="sm" className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
        <FontAwesomeIcon icon="sync" spin={loading} />{' '}
        <Translate contentKey="dmmtiktokApp.product.home.refreshListLabel">Refresh List</Translate>
      </Button>
    </div>
  );
};

export default OrderPageHeader;
