import { useAppDispatch } from 'app/config/store';
import PageHead from 'app/shared/components/PageHead';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { getFilterEntities } from '../shop/shop.reducer';
import OrderActions from './component/OrderActions';
import { OrderProvider } from './component/OrderContext';
import OrderPageHeader from './component/OrderPageHeader';
import OrderStatusTab from './component/OrderStatusTab';
import OrderTable from './component/OrderTable';
import './component/style.scss';

export const UIOrder = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getFilterEntities());
  }, []);

  return (
    <OrderProvider>
      <PageHead left={<Translate contentKey="dmmtiktokApp.uIOrder.home.title">Manage Orders</Translate>}>
        <OrderPageHeader />
      </PageHead>
      <OrderActions />
      <div className="order-table-container">
        {/* Tab */}
        <OrderStatusTab />
        {/* Table */}
        <OrderTable />
      </div>
    </OrderProvider>
  );
};

export default UIOrder;
