import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';

const MissingFlashSaleModal = ({ open, onClose, onSubmit, title }) => {
  const [discount, setDiscount] = React.useState('10');
  const [duration, setDuration] = useState("24");
  const handleSubmit = () => {
    onSubmit(discount, discount);
    setDiscount('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the discount percentage for the flash sale.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="discount"
          label="Discount"
          type="number"
          fullWidth
          value={discount}
          onChange={e => setDiscount(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="duration"
          label="Duration"
          type="number"
          fullWidth
          value={duration}
          onChange={e => setDuration(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissingFlashSaleModal;
