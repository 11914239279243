import { faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem, Modal, ModalBody } from 'reactstrap';
import "./style.scss";

const ImportTrackingModal = ({ isOpen, onClose, onFileUpload }) => {
  const [file, setFile] = useState<File>();

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleUpload = () => {
    onFileUpload(file);
  };

  return (
    <Modal centered isOpen={isOpen} toggle={onClose} size='lg'>
      <ModalBody>
        <div className='d-flex gap-4 py-4 px-3'>
          <div>
            <img width={200} height={200} src="content/images/excel-uploading.png" />
          </div>
          <div className='d-flex gap-4 justify-content-center flex-column'>
            <div>
              <div className='import-product-title'>Upload your spreadsheet</div>
              <div>Fill in the template and then upload it to import products.</div>
            </div>
            <label
              {...getRootProps()}
              className={`import-file-zone d-flex gap-4 align-items-center rounded-4 p-4 ${isDragActive ? 'zone-active' : ''}`}>
              <div>
                <FontAwesomeIcon icon={faFileUpload} />
              </div>
              <div>
                <p className='attach-title'>Attach your spreadsheet here</p>
                <div>
                  <p>Maximum file size: 5MB</p>
                  <p>Accept format: .csv only</p>
                  <p>Table criteria</p>
                  <p>It is recommended that you upload a spreadsheet with no more than 3,000 rows each time.</p>
                </div>
              </div>
            </label>
            <input {...getInputProps()} accept=".csv" />
          </div>
        </div>
        {file && (
          <div className="mb-3 px-3">
            <label>Files to be uploaded:</label>
            <ListGroup>
              <ListGroupItem>{file.name}</ListGroupItem>
            </ListGroup>

          </div>
        )}
        <div className='d-flex gap-4 justify-content-between align-items-center px-3'>
          <Link color='link' to={"https://toptopshop.sfo3.cdn.digitaloceanspaces.com/templates/order-tracking.csv"} className="btn-import-product-temp">
            <FontAwesomeIcon icon={faFileDownload} />{" "}
            <span>Download template</span>
          </Link>
          <Button disabled={!file} color="success" onClick={handleUpload}>
            Upload Files
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ImportTrackingModal
