

export interface IProduct {
  name?: string;
}

export interface IVariantList {
  variants?: Array<any>
}

export const defaultValue: Readonly<IProduct> = {};
