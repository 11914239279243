import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PartnerApp from './partner-app';
import PartnerAppDetail from './partner-app-detail';
import PartnerAppUpdate from './partner-app-update';
import PartnerAppDeleteDialog from './partner-app-delete-dialog';

const PartnerAppRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PartnerApp />} />
    <Route path="new" element={<PartnerAppUpdate />} />
    <Route path=":id">
      <Route index element={<PartnerAppDetail />} />
      <Route path="edit" element={<PartnerAppUpdate />} />
      <Route path="delete" element={<PartnerAppDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PartnerAppRoutes;
