import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Table } from 'reactstrap';
import { getPaginationState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, uploadFile } from './local-product.reducer';

export const LocalProduct = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search)
  );

  const [selectedFile, setSelectedFile] = useState(null);

  const localProductList = useAppSelector(state => state.localProduct.entities);
  const loading = useAppSelector(state => state.localProduct.loading);
  const totalItems = useAppSelector(state => state.localProduct.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      dispatch(uploadFile(file));
      (document.getElementById('fileInput') as HTMLInputElement).value = ''; // Clear file input value
    } else {
      alert('Only CSV files are allowed');
    }
  };

  return (
    <div>
      <h2 id="local-product-heading" data-cy="LocalProductHeading">
        <Translate contentKey="dmmtiktokApp.localProduct.home.title">Local Products</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="dmmtiktokApp.localProduct.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="info" disabled={loading} onClick={() => document.getElementById('fileInput').click()}>
            <FontAwesomeIcon icon={faFileImport} spin={loading} />{' '}
            Import
          </Button>
          <Input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" accept=".csv" />
          <Link to="/product/new" className="btn btn-primary jh-create-entity" id="jh-create-entity"
                data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="dmmtiktokApp.localProduct.home.createLabel">Create new Local Product</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {localProductList && localProductList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="dmmtiktokApp.localProduct.id">ID</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
              </th>
              <th className="hand" onClick={sort('brand')}>
                <Translate contentKey="dmmtiktokApp.localProduct.brand">Brand</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('brand')} />
              </th>
              <th className="hand" onClick={sort('productName')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productName">Product Name</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productName')} />
              </th>
              <th className="hand" onClick={sort('productDescription')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productDescription">Product
                  Description</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productDescription')} />
              </th>
              <th className="hand" onClick={sort('packageWeight')}>
                <Translate contentKey="dmmtiktokApp.localProduct.packageWeight">Package Weight</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('packageWeight')} />
              </th>
              <th className="hand" onClick={sort('packageLength')}>
                <Translate contentKey="dmmtiktokApp.localProduct.packageLength">Package Length</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('packageLength')} />
              </th>
              <th className="hand" onClick={sort('packageWidth')}>
                <Translate contentKey="dmmtiktokApp.localProduct.packageWidth">Package Width</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('packageWidth')} />
              </th>
              <th className="hand" onClick={sort('packageHeight')}>
                <Translate contentKey="dmmtiktokApp.localProduct.packageHeight">Package Height</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('packageHeight')} />
              </th>
              <th className="hand" onClick={sort('deliveryOptions')}>
                <Translate contentKey="dmmtiktokApp.localProduct.deliveryOptions">Delivery Options</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('deliveryOptions')} />
              </th>
              <th className="hand" onClick={sort('mainProductImage')}>
                <Translate contentKey="dmmtiktokApp.localProduct.mainProductImage">Main Product Image</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('mainProductImage')} />
              </th>
              <th className="hand" onClick={sort('productImage2')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage2">Product Image 2</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage2')} />
              </th>
              <th className="hand" onClick={sort('productImage3')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage3">Product Image 3</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage3')} />
              </th>
              <th className="hand" onClick={sort('productImage4')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage4">Product Image 4</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage4')} />
              </th>
              <th className="hand" onClick={sort('productImage5')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage5">Product Image 5</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage5')} />
              </th>
              <th className="hand" onClick={sort('productImage6')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage6">Product Image 6</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage6')} />
              </th>
              <th className="hand" onClick={sort('productImage7')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage7">Product Image 7</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage7')} />
              </th>
              <th className="hand" onClick={sort('productImage8')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage8">Product Image 8</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage8')} />
              </th>
              <th className="hand" onClick={sort('productImage9')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productImage9">Product Image 9</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productImage9')} />
              </th>
              <th className="hand" onClick={sort('sizeChart')}>
                <Translate contentKey="dmmtiktokApp.localProduct.sizeChart">Size Chart</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('sizeChart')} />
              </th>
              <th className="hand" onClick={sort('productStatus')}>
                <Translate contentKey="dmmtiktokApp.localProduct.productStatus">Product Status</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('productStatus')} />
              </th>
              <th />
            </tr>
            </thead>
            <tbody>
            {localProductList.map((localProduct, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/local-product/${localProduct.id}`} color="link" size="sm">
                    {localProduct.id}
                  </Button>
                </td>
                <td>{localProduct.brand}</td>
                <td>{localProduct.productName}</td>
                <td>{localProduct.productDescription}</td>
                <td>{localProduct.packageWeight}</td>
                <td>{localProduct.packageLength}</td>
                <td>{localProduct.packageWidth}</td>
                <td>{localProduct.packageHeight}</td>
                <td>{localProduct.deliveryOptions}</td>
                <td>{localProduct.mainProductImage}</td>
                <td>{localProduct.productImage2}</td>
                <td>{localProduct.productImage3}</td>
                <td>{localProduct.productImage4}</td>
                <td>{localProduct.productImage5}</td>
                <td>{localProduct.productImage6}</td>
                <td>{localProduct.productImage7}</td>
                <td>{localProduct.productImage8}</td>
                <td>{localProduct.productImage9}</td>
                <td>{localProduct.sizeChart}</td>
                <td>{localProduct.productStatus}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/local-product/${localProduct.id}`} color="info" size="sm"
                            data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/local-product/${localProduct.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                    <Button
                      onClick={() =>
                        (window.location.href = `/local-product/${localProduct.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                      }
                      color="danger"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="dmmtiktokApp.localProduct.home.notFound">No Local Products found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={localProductList && localProductList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems}
                          itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default LocalProduct;
