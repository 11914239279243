/* eslint-disable @typescript-eslint/no-misused-promises */
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useContext, useEffect } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button, Col, Form, Row } from 'reactstrap';
import { getProductTemplate } from '../../product.reducer';
import { ProductContext } from '../../ProductContext';

const productSourceOptions = [
  {
    label: 'Tiktok Shop',
    value: 'tts',
  },
  {
    label: 'Extension',
    value: 'ext',
  },
  {
    label: 'CSV File',
    value: 'csv',
  },
];

const ProductFilterForm = () => {
  const shops = useAppSelector(state => state.shop.entities);
  const templates = useAppSelector(state => state.product.productTemplates);
  const loading = useAppSelector(state => state.localProduct.loading);
  const { setValue } = useFormContext();
  const methods = useForm();
  const dispatch = useAppDispatch();
  const { searchProducts } = useContext(ProductContext);

  useEffect(() => {
    if (!templates) {
      dispatch(getProductTemplate());
    }
  }, []);

  const onSubmit = () => {
    const values = methods.getValues();
    setValue('filter', values);
    setValue('isOpenFilter', false);
    searchProducts({ ...values, activePage: 0 });
  };

  const handleClearFilter = () => {
    methods.reset();
    setValue('filter', {});
    setValue('isOpenFilter', false);
    searchProducts({ activePage: 0 });
  };

  return (
    <FormProvider {...methods}>
      <Form className="tts-card mb-3">
        <div className="d-flex align-items-center gap-2 mt-2">
          <h2>Filter Product</h2>
        </div>
        <Row className="mb-2">
          <Col xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel size="small" style={{ background: 'white' }}>
                Shop
              </InputLabel>
              <Controller
                name="shopId"
                defaultValue=""
                control={methods.control}
                render={({ field }) => (
                  <Select {...field} size="small">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {shops.map(shop => (
                      <MenuItem key={shop.id} value={shop.id}>
                        {shop.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Col>
          <Col xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel size="small" style={{ background: 'white' }}>
                Template
              </InputLabel>
              <Controller
                name="templateId"
                defaultValue=""
                control={methods.control}
                render={({ field }) => (
                  <Select {...field} size="small">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {templates?.map(template => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Col>
          <Col xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel size="small" style={{ background: 'white' }}>
                Source
              </InputLabel>
              <Controller
                name="productSource"
                defaultValue=""
                control={methods.control}
                render={({ field }) => (
                  <Select {...field} size="small">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {productSourceOptions.map(source => (
                      <MenuItem key={source.value} value={source.value}>
                        {source.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Col>
          <Col xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel size="small" style={{ background: 'white' }}>
                Product Type
              </InputLabel>
              <Controller
                name="productType"
                defaultValue=""
                control={methods.control}
                render={({ field }) => (
                  <Select {...field} size="small">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {['2D', '3D', 'Drop'].map(type => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Col>
          <Col xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel size="small" style={{ background: 'white' }}>
                Product Status
              </InputLabel>
              <Controller
                name="productStatus"
                defaultValue=""
                control={methods.control}
                render={({ field }) => (
                  <Select {...field} size="small">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {['ACTIVE', 'DEACTIVE'].map(stt => (
                      <MenuItem key={stt} value={stt}>
                        {stt}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Col>
        </Row>
        <div className="d-flex justify-content-between gap-2">
          <Button disabled={loading} outline onClick={handleClearFilter}>
            Clear
          </Button>
          <Button disabled={loading} outline color="primary" type="button" onClick={methods.handleSubmit(onSubmit)}>
            Apply
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

export default ProductFilterForm;
