import uIOrder from 'app/entities/ui-order/ui-order.reducer';
import partnerApp from 'app/entities/partner-app/partner-app.reducer';
import shop from 'app/entities/shop/shop.reducer';
import sellerAuthorization from 'app/entities/seller-authorization/seller-authorization.reducer';
import baseCost from 'app/entities/base-cost/base-cost.reducer';
import estimateCost from 'app/entities/dashboard/estimate-cost.reducer';
import estimatedProfit from 'app/entities/dashboard/estimated-profit.reducer';
import totalOrder from 'app/entities/dashboard/total-order.reducer';
import dashboardMetric from 'app/entities/dashboard/dashboard-metric.reducer';
import exportHistory from 'app/entities/export-history/export-history.reducer';
import flashSaleSchedule from 'app/entities/flash-sale-schedule/flash-sale-schedule.reducer';
import product from 'app/entities/product/product.reducer';
import localProduct from 'app/entities/local-product/local-product.reducer';
import shopMetric from 'app/entities/shop-metric/shop-metric.reducer';
import telegram from 'app/entities/telegram/telegram.reducer';
import metrics from 'app/entities/dashboard/dashboard-metric.reducer';
import productPublishHistory from 'app/entities/product-publish-history/product-publish-history.reducer';
import productTemplate from 'app/entities/product-template/product-template.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  uIOrder,
  partnerApp,
  shop,
  sellerAuthorization,
  baseCost,
  estimateCost,
  totalOrder,
  estimatedProfit,
  dashboardMetric,
  exportHistory,
  flashSaleSchedule,
  localProduct,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  product,
  shopMetric,
  metrics,
  telegram,
  productPublishHistory,
  productTemplate,
};

export default entitiesReducers;
