import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './ui-order.reducer';

export const UIOrderDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const uIOrderEntity = useAppSelector(state => state.uIOrder.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="uIOrderDetailsHeading">
          <Translate contentKey="dmmtiktokApp.uIOrder.detail.title">UIOrder</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.id}</dd>
          <dt>
            <span id="shopName">
              <Translate contentKey="dmmtiktokApp.uIOrder.shopName">Shop Name</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.shopName}</dd>
          <dt>
            <span id="seller">
              <Translate contentKey="dmmtiktokApp.uIOrder.seller">Seller</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.seller}</dd>
          <dt>
            <span id="estimateAmount">
              <Translate contentKey="dmmtiktokApp.uIOrder.estimateAmount">Estimate Amount</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.estimateAmount}</dd>
          <dt>
            <span id="baseCost">
              <Translate contentKey="dmmtiktokApp.uIOrder.baseCost">Base Cost</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.baseCost}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="dmmtiktokApp.uIOrder.status">Status</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.status}</dd>
          <dt>
            <span id="trackingNumber">
              <Translate contentKey="dmmtiktokApp.uIOrder.trackingNumber">Tracking Number</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.trackingNumber}</dd>
          <dt>
            <span id="shipmentUrl">
              <Translate contentKey="dmmtiktokApp.uIOrder.shipmentUrl">Shipment Url</Translate>
            </span>
          </dt>
          <dd>{uIOrderEntity.shipmentUrl}</dd>
          <dt>
            <Translate contentKey="dmmtiktokApp.uIOrder.shop">Shop</Translate>
          </dt>
          <dd>{uIOrderEntity.shop ? uIOrderEntity.shop.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/ui-order" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ui-order/${uIOrderEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UIOrderDetail;
