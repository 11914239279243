export type CategoryType = {
    id: string;
    is_leaf: boolean;
    local_name: string;
    parent_id: string;
    permission_statuses: string[];
    children?: CategoryType[];
};

export const buildTree = (data: CategoryType[]): CategoryType[] => {
    const map: { [key: string]: CategoryType } = {};
    const roots: CategoryType[] = [];

    // Create a map of all nodes
    data.forEach(item => {
        map[item.id] = { ...item, children: [] };
    });

    // Build the tree structure
    data.forEach(item => {
        if (item.parent_id !== "0") {
            // If the item has a parent, add it to the parent's children array
            if (map[item.parent_id]) {
                map[item.parent_id].children?.push(map[item.id]);
            }
        } else {
            // If the item has no parent, it's a root node
            roots.push(map[item.id]);
        }
    });

    return roots;
};
