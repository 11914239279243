import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import ProductPublishHistory from './product-publish-history';
const ProductPublishHistoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductPublishHistory />} />
  </ErrorBoundaryRoutes>
);

export default ProductPublishHistoryRoutes;
