import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, ReactElement } from 'react';
interface EmptyTableProps {
  message?: string | ReactElement;
}
const EmptyTable: FC<EmptyTableProps> = ({ message }) => {
  return (
    <div className="table-no-data w-100 d-flex justify-content-between align-items-center flex-column">
      <FontAwesomeIcon size="4x" icon={faFolderOpen} />
      <div>{message || 'No Data'}</div>
    </div>
  );
};

export default EmptyTable;
