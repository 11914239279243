// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#product-category-dd input {
  color: #6b7280;
}

.dd-mega {
  height: 64px;
  width: 100%;
  border: 1px solid;
}

.product-category-mega-menu {
  width: 100%;
}
.product-category-mega-menu .category-wrapper {
  height: 250px;
  overflow-y: auto;
}
.product-category-mega-menu .breadcrumb-item {
  padding: 2px 4px;
}
.product-category-mega-menu .breadcrumb-item:hover {
  background: #f5f5f5;
  cursor: pointer;
  border-radius: 4px;
}
.product-category-mega-menu .category-level li {
  border: none;
}
.product-category-mega-menu .category-level li.active {
  background: white;
  cursor: pointer;
  color: #009995;
}
.product-category-mega-menu .category-level li.active * {
  color: #009995;
}
.product-category-mega-menu .category-level li:hover {
  background: #f5f5f5;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/product/components/MegaProductCategory/style.scss","webpack://./src/main/webapp/app/custom.scss"],"names":[],"mappings":"AAGI;EACI,cCGI;ADLZ;;AAMA;EACI,YAAA;EACA,WAAA;EACA,iBAAA;AAHJ;;AAMA;EACI,WAAA;AAHJ;AAMI;EACI,aAAA;EACA,gBAAA;AAJR;AAOI;EACI,gBAAA;AALR;AAOQ;EACI,mBC3BI;ED4BJ,eAAA;EACA,kBAAA;AALZ;AAUQ;EACI,YAAA;AARZ;AAWQ;EACI,iBAAA;EACA,eAAA;EACA,cC9BF;ADqBV;AAWY;EACI,cCjCN;ADwBV;AAaQ;EACI,mBCjDI;EDkDJ,eAAA;AAXZ","sourcesContent":["@import \"../../../../custom.scss\";\n\n#product-category-dd {\n    input {\n        color: $text-base;\n    }\n}\n\n.dd-mega {\n    height: 64px;\n    width: 100%;\n    border: 1px solid;\n}\n\n.product-category-mega-menu {\n    width: 100%;\n\n\n    .category-wrapper {\n        height: 250px;\n        overflow-y: auto;\n    }\n\n    .breadcrumb-item {\n        padding: 2px 4px;\n\n        &:hover {\n            background: $base-bg-color;\n            cursor: pointer;\n            border-radius: 4px;\n        }\n    }\n\n    .category-level {\n        li {\n            border: none;\n        }\n\n        li.active {\n            background: white;\n            cursor: pointer;\n            color: $primary;\n\n            * {\n                color: $primary;\n            }\n        }\n\n        li:hover {\n            background: $base-bg-color;\n            cursor: pointer;\n        }\n\n        .category-item {}\n    }\n\n}","$base-bg-color: #f5f5f5;\n$base-bg-color-dark: #f5f5f5d7;\n\n$text-color-1: rgba(0, 0, 0, .92);\n$header-height: 61;\n$footer-height: 90;\n$text-base-light: #6b7280bf;\n$text-base: #6b7280;\n$text-base-dark: #2a2d32;\n\n// Colors\n$primary: #009995;\n$secondary: #FF6B6B;\n$success: #28A745;\n$info: #17A2B8;\n$warning: #FFC107;\n$danger: #DC3545;\n$light: #F8F9FA;\n$dark: #343A40;\n\n// Additional custom colors\n$accent: #FF9F1C;\n$neutral: #6C757D;\n$light-teal: #4ECDC4;\n$dark-teal: #005F5C;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
