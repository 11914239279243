// ImageWithModal.js
import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import './style.scss'; // Ensure to import the SCSS file

const ImageWithModal = ({ src, alt }) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div className="image-container">
            <img src={src} alt={alt} className="thumbnail-img" />
            <div className="overlay" onClick={toggle}>
                <FontAwesomeIcon icon={faEye} size="2x" className="eye-icon" />
            </div>

            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalBody className="modal-body-custom">
                    <div className="close-icon">
                        <FontAwesomeIcon icon={faTimes} onClick={toggle} />
                    </div>
                    <img src={src} alt={alt} className="img-fluid" />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ImageWithModal;
