import { faGripLines, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import React, { useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, FormFeedback, Input, Label } from 'reactstrap';
import helper from '../helper';

const OptionItem = ({ variantIndex, optionIndex, handleBlur, handleKeyDown, maxSize, inputRef }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const handleOptionChanged = () => {
    const { variationAttributes } = getValues();
    const variantData = helper.buildVariantsV1(variationAttributes);
    setValue('variantData', variantData);
  };

  return (
    <div style={{ marginRight: '10px', width: 'calc(100% - 60px)' }}>
      <Controller
        control={control}
        name={`variationAttributes.${variantIndex}.values.${optionIndex}.value`} // Ensure the name field is correct
        render={({ field: { value, onChange, ...field } }) => (
          <Input
            {...field}
            value={value}
            ref={inputRef}
            onKeyDown={e => handleKeyDown(e, optionIndex)}
            onBlur={e => handleBlur(optionIndex, e.target.value)}
            onChange={e => {
              onChange(e);
              handleOptionChanged();
            }}
          />
        )}
        rules={{ required: maxSize > 1 ? undefined : 'Please enter an option' }}
      />
      {errors.variants?.[variantIndex]?.values?.[optionIndex]?.value && (
        <FormFeedback>{errors.variants[variantIndex].values[optionIndex].value.message}</FormFeedback>
      )}
    </div>
  );
};

const OptionsField = ({ variantIndex, onDone }) => {
  const { control } = useFormContext();
  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
    swap: swapOption,
  } = useFieldArray({
    control,
    name: `variationAttributes.${variantIndex}.values`,
  });

  const newInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (newInputRef.current) {
        newInputRef.current.focus();
      }
    }, 50);
  }, [optionFields.length]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    swapOption(source.index, destination.index);
  };

  const addNewOption = () => {
    appendOption({ value: '' });
  };

  const handleBlur = (index: number, value: string) => {
    if (index === optionFields.length - 1 && value?.trim() !== '') {
      addNewOption();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      const fieldValue = newInputRef?.current?.value;
      if (index === optionFields.length - 1 && fieldValue?.trim() !== '') {
        addNewOption();
      }
      event.preventDefault();
    }
  };

  const handleDone = () => {
    onDone();
  };

  return (
    <>
      <Label className="mb-1">
        <span className="text-danger me-1">*</span>Options
      </Label>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {droppableProvided => (
            <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} style={{ padding: 0, listStyleType: 'none' }}>
              {optionFields.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {draggableProvided => (
                    <li
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      className="d-flex align-items-center bg-white mb-2"
                      style={draggableProvided.draggableProps.style}
                    >
                      <OptionItem
                        inputRef={index === optionFields.length - 1 ? newInputRef : null}
                        maxSize={optionFields.length}
                        variantIndex={variantIndex}
                        optionIndex={index}
                        handleBlur={handleBlur}
                        handleKeyDown={handleKeyDown}
                      />
                      {index < optionFields.length - 1 && (
                        <>
                          <Button
                            color="danger"
                            onClick={() => removeOption(index)}
                            style={{ background: 'none', border: 'none', color: 'GrayText', padding: 0, marginRight: '10px' }}
                          >
                            <FontAwesomeIcon className="cursor-pointer table-delete-icon" icon={faTrashAlt} size="lg" />
                          </Button>
                          <span {...draggableProvided.dragHandleProps} style={{ cursor: 'grab', marginRight: '8px' }}>
                            <FontAwesomeIcon icon={faGripLines} />
                          </span>
                        </>
                      )}
                    </li>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </ul>
          )}
        </Droppable>
        <Button size="sm" outline color="primary" type="button" onClick={handleDone}>
          Done
        </Button>
      </DragDropContext>
    </>
  );
};

export default OptionsField;
