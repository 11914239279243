import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UIOrder from './ui-order';
import PartnerApp from './partner-app';
import Shop from './shop';
import SellerAuthorization from './seller-authorization';
import BaseCost from './base-cost';
import ExportHistory from './export-history';
import FlashSaleSchedule from './flash-sale-schedule';
import Product from './product';
import LocalProduct from './local-product';
import Telegram from './telegram';
import ProductPublishHistory from './product-publish-history';
import ProductTemplate from './product-template';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="ui-order/*" element={<UIOrder />} />
        <Route path="partner-app/*" element={<PartnerApp />} />
        <Route path="shop/*" element={<Shop />} />
        <Route path="product/*" element={<Product />} />
        <Route path="product-template/*" element={<ProductTemplate />} />
        <Route path="seller-authorization/*" element={<SellerAuthorization />} />
        <Route path="base-cost/*" element={<BaseCost />} />
        <Route path="export-history/*" element={<ExportHistory />} />
        <Route path="flash-sale-schedule/*" element={<FlashSaleSchedule />} />
        <Route path="local-product/*" element={<LocalProduct />} />
        <Route path="telegram/*" element={<Telegram />} />
        <Route path="product-publish-history/*" element={<ProductPublishHistory />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
