import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProductCategory } from 'app/shared/model/local-product.model';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Input, ListGroup, ListGroupItem, Row } from 'reactstrap';
import './style.scss';
import { buildTree, CategoryType } from './helper';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getProductCategory } from '../../product.reducer';

interface MenuItem {
  id: string;
  label: string;
  children?: MenuItem[];
}
interface MegaProductCategoryProps {
  container?: string;
  onSelect: (catId: string) => void;
  onCategoryString?: (categoryString: string) => void;
  defaultValue?: string;
}
const MegaProductCategory: React.FC<MegaProductCategoryProps> = ({ container, onSelect, onCategoryString, defaultValue }) => {
  const [menuData, setMenuData] = useState<Array<CategoryType>>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState('');
  const [selectedItems, setSelectedItems] = useState<Array<CategoryType>>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>();
  const categories = useAppSelector<Array<any>>(state => state.product.productCategories);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!categories) {
      dispatch(getProductCategory());
    }
  }, []);

  // useEffect(() => {
  //   if (defaultValue) {
  //     const categoryLevel3 = categories.find(c => c.id === defaultValue);
  //     if (categoryLevel3) {
  //       const categoryLevel2 = categories.find(c => c.id === categoryLevel3.parent_id);
  //     }
  //   }
  //   // dispatch(getProductCategory());
  // }, [defaultValue, categories]);

  useEffect(() => {
    if (categories?.length && !selectedItems?.length) {
      const catTree = buildTree(categories);
      if (catTree?.length > 0) {
        const firstLevel1 = catTree[0];
        const firstLevel2 = firstLevel1.children?.[0] || null;
        setSelectedItems([firstLevel1, firstLevel2].filter(Boolean));
        setMenuData(catTree);
      }
    }
  }, [categories]);

  useEffect(() => {
    if (selectedItems?.length === 3) {
      onSelect(selectedItems[selectedItems.length - 1].id);
      const catStr = getSelectedString();
      setCategorySelected?.(catStr);
      onCategoryString?.(catStr);
      toggle();
    }
  }, [selectedCategory]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const getSelectedString = () =>
    selectedItems?.reduce((prev, curr) => {
      if (!prev) {
        return curr.local_name;
      }

      return `${prev} - ${curr.local_name}`;
    }, '');

  const handleItemClick = (item: CategoryType, level: number) => {
    const newSelectedItems = [...selectedItems.slice(0, level), item];
    if (level < 2 && item.children) {
      newSelectedItems.push(item.children[0]);
    }
    setSelectedItems(newSelectedItems.slice(0, 3));
    if (level === 2) {
      setSelectedCategory(item);
    } else {
      setSelectedCategory(undefined);
    }
  };

  const renderMenuLevel = (items: Array<CategoryType> | undefined, level: number) => (
    <Col xs="4" className="category-wrapper">
      <ListGroup flush className="category-level">
        {items?.map(item => (
          <ListGroupItem
            key={item.id}
            action
            active={level === 2 ? selectedCategory?.id === item.id : selectedItems[level]?.id === item.id}
            onClick={() => handleItemClick(item, level)}
          >
            <div className="category-item">{item.local_name}</div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Col>
  );

  const renderBreadcrumbs = () => (
    <div className="d-flex gap-2 px-4 py-2 align-items-center">
      <div>
        <a>All Categories</a>
      </div>
      {selectedItems.map((item, index) => (
        <div
          key={item.id}
          onClick={e => {
            e.preventDefault();
            handleItemClick(item, index);
          }}
          className="d-flex align-items-center gap-1"
        >
          <FontAwesomeIcon icon={faChevronRight} />
          <div className="breadcrumb-item" onClick={e => e.preventDefault()}>
            {item.local_name}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} color="primary" style={{ width: '100%' }} id="product-category-dd">
      <DropdownToggle tag="div" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen}>
        <Input value={categorySelected} placeholder="Select category" onClick={toggle} />
      </DropdownToggle>
      <DropdownMenu
        container={document.getElementById(container || 'product-category-dd')}
        style={{ width: '100%', marginTop: 6, border: '1px solid #cccccc3b' }}
      >
        <div className="product-category-mega-menu">
          {renderBreadcrumbs()}
          <Row>
            {renderMenuLevel(menuData, 0)}
            {renderMenuLevel(selectedItems[0]?.children, 1)}
            {renderMenuLevel(selectedItems[1]?.children, 2)}
            <Col sm={12} />
          </Row>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MegaProductCategory;
