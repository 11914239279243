import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { useSideMenu } from './useSideMenu';

interface MenuItem {
  name: string | ReactElement;
  link?: string;
  icon?: string | React.JSX.Element;
  subItems?: MenuItem[];
  devider?: boolean;
}

const MenuItem: React.FC<{ item: MenuItem; depth: number }> = ({ item, depth = 0 }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();

  const isActive = item.link ? location.pathname === item.link : false;

  return (
    <>
      {item.subItems ? (
        <NavItem key={item.link}>
          <NavLink href="#" onClick={() => setIsOpen(!isOpen)}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                {item.icon}
                <span style={{ paddingTop: 3 }}>{item.name}</span>
              </div>
              <FontAwesomeIcon style={{ fontSize: '12px' }} icon={isOpen ? faChevronDown : faChevronRight} />
            </div>
          </NavLink>
          <Collapse isOpen={isOpen}>
            <Nav vertical>
              {item.subItems.map(subItem => (
                <MenuItem key={subItem.link} item={subItem} depth={depth + 1} />
              ))}
            </Nav>
          </Collapse>
        </NavItem>
      ) : (
        <>
          <NavItem className={`${isActive ? 'active' : ''}`} key={item.link}>
            <Link className={`nav-link`} to={item.link}>
              <div className="d-flex align-items-center gap-2">
                {item.icon}
                <span>{item.name}</span>
              </div>
            </Link>
          </NavItem>
          {item.devider ? <li className="nav-devider"></li> : null}
        </>
      )}
    </>
  );
};

export const SideBarContent = ({ menu }) => {
  return (
    <div className="sidebar-content">
      <Nav vertical>
        {menu.map(item => (
          <MenuItem key={item.link} item={item} depth={0} />
        ))}
      </Nav>
    </div>
  );
};

const SideMenu = () => {
  const menu = useSideMenu();

  return (
    <div className="sidebar bg-white">
      <SideBarContent menu={menu} />
    </div>
  );
};

export default SideMenu;
