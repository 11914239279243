import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Table, Modal, Typography, Box, ButtonGroup } from '@mui/material';
import { Button } from 'reactstrap';
import { Translate, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SyncIcon from '@mui/icons-material/Sync';
import PlusIcon from '@mui/icons-material/PlusOne';
import { getEntities } from './partner-app.reducer';
import LoadingButton from '@mui/lab/LoadingButton';
import PageHead from 'app/shared/components/PageHead';
import PaginationComponent from 'app/shared/components/PaginationComponent';
const PartnerApp = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  // Pagination and data state
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const partnerAppList = useAppSelector(state => state.partnerApp.entities);
  const loading = useAppSelector(state => state.partnerApp.loading);
  const totalItems = useAppSelector(state => state.partnerApp.totalItems);

  // Modal state
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPartnerApp, setSelectedPartnerApp] = useState(null);

  useEffect(() => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  }, [dispatch, paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const openModal = partnerApp => {
    setSelectedPartnerApp(partnerApp);
    toggleModal();
  };

  const sortEntities = () => {
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  // Function to handle syncing list
  const handleSyncList = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <PageHead left={<Translate contentKey="dmmtiktokApp.partnerApp.home.title">Partner Apps</Translate>}>
        <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="dmmtiktokApp.product.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Button className="me-2" color="primary" onClick={() => navigate("/partner-app/new")}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="dmmtiktokApp.partnerApp.home.createLabel">Create new Partner App</Translate>
        </Button>
      </PageHead>
      {partnerAppList && partnerAppList.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="dmmtiktokApp.partnerApp.id">ID</Translate> <FontAwesomeIcon
                  icon={getSortIconByFieldName('id')} />
              </th>
              <th className="hand" onClick={sort('name')}>
                <Translate contentKey="dmmtiktokApp.partnerApp.name">Name</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
              </th>
              <th className="hand" onClick={sort('key')}>
                <Translate contentKey="dmmtiktokApp.partnerApp.key">Key</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('key')} />
              </th>
              <th className="hand" onClick={sort('secret')}>
                <Translate contentKey="dmmtiktokApp.partnerApp.secret">Secret</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('secret')} />
              </th>
              <th className="hand" onClick={sort('version')}>
                <Translate contentKey="dmmtiktokApp.partnerApp.version">Version</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('version')} />
              </th>
              <th className="hand" onClick={sort('region')}>
                <Translate contentKey="dmmtiktokApp.partnerApp.region">Region</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('region')} />
              </th>
              <th className="hand" onClick={sort('serviceId')}>
                <Translate contentKey="dmmtiktokApp.partnerApp.serviceId">Service Id</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('serviceId')} />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {partnerAppList.map((partnerApp, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td><span>{partnerApp.id}</span></td>
                <td><span>{partnerApp.name}</span></td>
                <td><span>{partnerApp.key}</span></td>
                <td><span>{partnerApp.secret}</span></td>
                <td><span>{partnerApp.version}</span></td>
                <td><span>{partnerApp.region}</span></td>
                <td><span>{partnerApp.serviceId}</span></td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <ButtonGroup variant="contained">
                      <Button
                        startIcon={<VisibilityIcon />}
                        onClick={() => openModal(partnerApp)}
                        data-cy="entityDetailsButton"
                        variant="contained"
                        color="info"
                        size="medium"
                      >
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        startIcon={<EditIcon />}
                        href={`/partner-app/${partnerApp.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        variant="contained"
                        color="primary"
                        size="medium"
                      >
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        onClick={() =>
                          (window.location.href = `/partner-app/${partnerApp.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        variant="contained"
                        color="warning"
                        size="medium"
                      >
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </ButtonGroup>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        !loading && (
          <Box sx={{ p: 2, bgcolor: 'warning.light' }}>
            <Translate contentKey="dmmtiktokApp.partnerApp.home.notFound">No Partner Apps found</Translate>
          </Box>
        )
      )}

      <PaginationComponent
        pageIndex={paginationState.activePage}
        totalRecords={totalItems}
        onPageChange={handlePagination}
        pageSize={paginationState.itemsPerPage}
        onPageSizeChange={() => { }}
      />

      {/* Modal to display partner app details */}
      <Modal open={modalOpen} onClose={toggleModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" align="center">
            <Translate contentKey="dmmtiktokApp.partnerApp.detail.title">Partner App Details</Translate>
          </Typography>
          {selectedPartnerApp && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                <strong>
                  <Translate contentKey="dmmtiktokApp.partnerApp.id">ID</Translate>:
                </strong>{' '}
                {selectedPartnerApp.id}
              </Typography>
              <Typography variant="body1">
                <strong>
                  <Translate contentKey="dmmtiktokApp.partnerApp.name">Name</Translate>:
                </strong>{' '}
                {selectedPartnerApp.name}
              </Typography>
              <Typography variant="body1">
                <strong>
                  <Translate contentKey="dmmtiktokApp.partnerApp.key">Key</Translate>:
                </strong>{' '}
                {selectedPartnerApp.key}
              </Typography>
              <Typography variant="body1">
                <strong>
                  <Translate contentKey="dmmtiktokApp.partnerApp.secret">Secret</Translate>:
                </strong>{' '}
                {selectedPartnerApp.secret}
              </Typography>
              <Typography variant="body1">
                <strong>
                  <Translate contentKey="dmmtiktokApp.partnerApp.version">Version</Translate>:
                </strong>{' '}
                {selectedPartnerApp.version}
              </Typography>
              {/* Add more fields as needed */}
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button onClick={toggleModal} color="secondary" variant="contained">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PartnerApp;
