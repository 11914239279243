import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import PageHead from 'app/shared/components/PageHead';
import React, { useEffect, useState } from 'react';
import { getPaginationState, Translate } from 'react-jhipster';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { deleteEntity, searchEntities } from './product-template.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useLocation, useNavigate } from 'react-router';
import PaginationComponent from 'app/shared/components/PaginationComponent';
import IProductTemplate from 'app/shared/model/product-template';
import { Link } from '@mui/material';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const ProductTemplate = () => {
  const pageLocation = useLocation();
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<IProductTemplate>();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const productTemplates = useAppSelector<Array<IProductTemplate>>(state => state.productTemplate.entities);
  const loading = useAppSelector(state => state.productTemplate.loading);
  const totalItems = useAppSelector(state => state.productTemplate.totalItems);
  const deleteStatus = useAppSelector(state => state.productTemplate.deleteStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    if (deleteStatus === 'success') {
      setCurrentTemplate(undefined);
      setDeleteConfirmOpen(false);
      getAllEntities();
    }
  }, [deleteStatus]);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  const getAllEntities = () => {
    dispatch(
      searchEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const handleEditEntity = (pt: IProductTemplate) => {
    navigate(`/product-template/${pt.id}/edit`);
  };

  const handleCreateEntity = () => {
    navigate('/product-template/new');
  };

  const handleOpenDeleteConfirmation = (template: IProductTemplate) => {
    setCurrentTemplate(template);
    setDeleteConfirmOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setCurrentTemplate(undefined);
    setDeleteConfirmOpen(false);
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(currentTemplate.id));
  };

  return (
    <div>
      <PageHead left={<span>Product Template</span>}>
        <div className="d-flex gap-2">
          <Button color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="dmmtiktokApp.shop.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button color="primary" onClick={handleCreateEntity} disabled={loading}>
            <FontAwesomeIcon icon={faPlus} /> <span>Create template</span>
          </Button>
        </div>
      </PageHead>
      <Table responsive className="product-template-table">
        <thead>
          <tr className="px-2">
            <th>Product Template Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {productTemplates?.map(pt => (
            <tr key={pt.id}>
              <td>
                <span>{pt.name}</span>
              </td>
              <td>
                <div className="d-flex gap-3 flex-wrap">
                  <Link className="product-action" onClick={() => handleEditEntity(pt)}>
                    <span className="product-action-link">Edit</span>
                  </Link>
                  <Link className="product-action" onClick={() => handleOpenDeleteConfirmation(pt)}>
                    <span className="product-action-link">Delete</span>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationComponent
        pageIndex={paginationState.activePage}
        totalRecords={totalItems}
        onPageChange={handlePagination}
        pageSize={paginationState.itemsPerPage}
        onPageSizeChange={() => {}}
      />
      <Modal isOpen={isDeleteConfirmOpen} toggle={handleCloseDeleteConfirmation}>
        <ModalHeader toggle={handleCloseDeleteConfirmation} data-cy="localProductDeleteDialogHeading">
          <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
        </ModalHeader>
        <ModalBody id="dmmtiktokApp.localProduct.delete.question">
          <span>
            Are you sure you want to delete this template <strong>{currentTemplate?.name}</strong>?
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseDeleteConfirmation} disabled={deleteStatus === 'pending'}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button
            id="jhi-confirm-delete-localProduct"
            data-cy="entityConfirmDeleteButton"
            color="danger"
            onClick={confirmDelete}
            disabled={deleteStatus === 'pending'}
          >
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductTemplate;
