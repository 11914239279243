import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { IProductAttribute, IProductCategory, IVariantData } from 'app/shared/model/local-product.model';
import { defaultValue, IProduct } from 'app/shared/model/product.model';
import { EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import helper, { IVariantList } from './helper';

interface IProductState extends EntityState<IProduct> {
    variants?: IVariantData;
    variantList?: IVariantList;
    productCategories?: Array<IProductCategory>;
    productAttributes?: Array<IProductAttribute>;
    publishSuccessMessage?: string;
    attributeLoading?: boolean;
    publishLoading?: boolean;
    productTemplateLoading?: boolean;
    productTemplates?: Array<any>;
    deleteProductsStatus?: string;
}
const initialState: IProductState = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
    attributeLoading: false,
    publishLoading: false,
    deleteProductsStatus: "idle",

    variants: null,
    productCategories: null,
    productAttributes: null,

    publishSuccessMessage: ""
};

const apiUrl = 'api/product';
const apiUploadUrl = 'api/upload';

// Actions
export const uploadFile = createAsyncThunk(
    'product/upload_image',
    async (file: File, thunkAPI) => {
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post(apiUploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return result;
    },
    { serializeError: serializeAxiosError }
);

export const getProductCategory = createAsyncThunk(
    'product/categories',
    async () => {
        const requestUrl = "api/categories";
        return axios.get<Array<IProductCategory>>(requestUrl);
    },
    { serializeError: serializeAxiosError },
);
export const getProductAttribute = createAsyncThunk(
    'product/attributes',
    async (categoryId: string) => {
        const requestUrl = `api/categories/${categoryId}/attributes`;
        return axios.get<Array<IProductAttribute>>(requestUrl);
    },
    { serializeError: serializeAxiosError },
);
export const getProductTemplate = createAsyncThunk(
    'product/product-templates',
    async () => {
        const requestUrl = `api/product-templates`;
        return axios.get<Array<IProductAttribute>>(requestUrl);
    },
    { serializeError: serializeAxiosError },
);

interface IPublishProducts {
    shopId: string,
    categoryId: string,
    productIds: Array<string>
}
export const publishProducts = createAsyncThunk(
    'local-product/publishProducts',
    async (payload: IPublishProducts) => {
        const requestUrl = `api/local-products/publish`;
        return axios.post<Array<IProduct>>(requestUrl, payload);
    },
    { serializeError: serializeAxiosError },
);
export const deleteProducts = createAsyncThunk(
    'local-product/deleteProducts',
    async (payload: Array<string>) => {
        const requestUrl = `/api/local-products/delete`;
        return axios.delete<IProduct>(requestUrl, { data: payload });
    },
    { serializeError: serializeAxiosError },
);

// slice

export const ProductSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        buildVariantList(state, action: PayloadAction<Array<any>>) {
            state.variantList = helper.buildVariantsV1(action.payload);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getProductCategory.fulfilled, (state, action) => {
                state.productCategories = action.payload.data;
            })
            .addCase(getProductAttribute.fulfilled, (state, action) => {
                state.productAttributes = action.payload.data;
            })
            .addMatcher(isFulfilled(uploadFile, getProductCategory, getProductAttribute), (state, action) => {
                state.loading = false;
                state.updateSuccess = true;
            })
            .addMatcher(isPending(uploadFile, getProductCategory, getProductAttribute), state => {
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isRejected(uploadFile, getProductCategory, getProductAttribute), state => {
                state.loading = false;
            })
            // publish products
            .addMatcher(isFulfilled(publishProducts), state => {
                state.publishLoading = false;
                state.publishSuccessMessage = 'product.messages.publishSuccess';
            })
            .addMatcher(isPending(publishProducts), state => {
                state.publishLoading = true;
            })
            .addMatcher(isRejected(publishProducts), state => {
                state.publishLoading = false;
            })
            // attribute
            .addMatcher(isFulfilled(getProductAttribute), state => {
                state.attributeLoading = false;
            })
            .addMatcher(isPending(getProductAttribute), state => {
                state.attributeLoading = true;
            })
            .addMatcher(isRejected(getProductAttribute), state => {
                state.attributeLoading = false;
            })
            // productTemplates
            .addMatcher(isFulfilled(getProductTemplate), (state, action) => {
                state.productTemplateLoading = false;
                state.productTemplates = action.payload.data
            })
            .addMatcher(isPending(getProductTemplate), state => {
                state.productTemplateLoading = true;
            })
            .addMatcher(isRejected(getProductTemplate), state => {
                state.productTemplateLoading = false;
            })
            // delete products
            .addMatcher(isFulfilled(deleteProducts), (state, action) => {
                state.deleteProductsStatus = "success";
            })
            .addMatcher(isPending(deleteProducts), state => {
                state.deleteProductsStatus = "pending";
            })
            .addMatcher(isRejected(deleteProducts), state => {
                state.deleteProductsStatus = "failed";
            })
    }
});

export const { buildVariantList } = ProductSlice.actions;

// Reducer
export default ProductSlice.reducer;
