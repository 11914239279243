import './base-cost-detail-modal.scss';
import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './base-cost.reducer';

export const BaseCostDetailModal = ({ isOpen, toggle, id }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen && id) {
      dispatch(getEntity(id));
    }
  }, [isOpen, id]);

  const baseCostEntity = useAppSelector(state => state.baseCost.entity);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey="dmmtiktokApp.baseCost.detail.title">BaseCost</Translate>
      </ModalHeader>
      <ModalBody>
        <div className="entity-details">
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
            <span className="detail-value">{baseCostEntity.id}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.baseCost.sku">Sku</Translate>
            </span>
            <span className="detail-value">{baseCostEntity.sku}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.baseCost.typeValue">Type</Translate>
            </span>
            <span className="detail-value">{baseCostEntity.typeValue}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.baseCost.colorValue">Color</Translate>
            </span>
            <span className="detail-value">{baseCostEntity.colorValue}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.baseCost.sizeValue">Size</Translate>
            </span>
            <span className="detail-value">{baseCostEntity.sizeValue}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.baseCost.sideValue">Side</Translate>
            </span>
            <span className="detail-value">{baseCostEntity.sideValue}</span>
          </div>
          <div className="detail-item">
            <span className="detail-property">
              <Translate contentKey="dmmtiktokApp.baseCost.cost">Cost</Translate>
            </span>
            <span className="detail-value">{baseCostEntity.cost}</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="info" onClick={toggle}>
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/base-cost/${baseCostEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BaseCostDetailModal;
