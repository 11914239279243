import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Telegram from './telegram';
import TelegramDetail from './telegram-detail';
import TelegramUpdate from './telegram-update';
import TelegramDeleteDialog from './telegram-delete-dialog';

const TelegramRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Telegram />} />
    <Route path="new" element={<TelegramUpdate />} />
    <Route path=":id">
      <Route index element={<TelegramDetail />} />
      <Route path="edit" element={<TelegramUpdate />} />
      <Route path="delete" element={<TelegramDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TelegramRoutes;
