import { Tab, Tabs } from '@mui/material';
import { a11yProps } from 'app/entities/ui-order/custom-tab-panel';
import React, { useContext, useState } from 'react';
import { ProductContext } from '../../ProductContext';
import { useFormContext } from 'react-hook-form';
import { ProductForm } from '../../product.type';

const productStatusOptions = [
  {
    label: 'All',
    index: 0,
    value: '',
  },
  {
    label: 'Published',
    index: 1,
    value: 'published',
  },
  {
    label: 'UnPublish',
    index: 2,
    value: 'unpublished',
  },
];

const ProductTab = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { searchProducts } = useContext(ProductContext);
  const { setValue } = useFormContext<ProductForm>();

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    const shopPublish = productStatusOptions[tabIndex].value;
    setActiveTab(tabIndex);
    setValue('filter.shopPublish', shopPublish);
    searchProducts({ shopPublish, activePage: 0 });
  };

  return (
    <Tabs value={activeTab} onChange={handleChange} aria-label="order filter tabs">
      {productStatusOptions.map(option => (
        <Tab key={option.value} label={option.label} {...a11yProps(option.index)} />
      ))}
    </Tabs>
  );
};

export default ProductTab;
