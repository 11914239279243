import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#009995', // Replace with your SCSS $primary color
        },
        secondary: {
            main: '#FF6B6B', // Replace with your SCSS $secondary color
        },
        success: {
            main: '#28A745',
        },
        info: {
            main: '#17A2B8',
        },
        warning: {
            main: '#FFC107',
        },
        error: {
            main: '#DC3545',
        },
    },
});

export default theme;