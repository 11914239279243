import { useAppDispatch } from 'app/config/store';
import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';
import Content from './Content';
import DrawerMenu from './DrawerMenu';
import SideMenu from './SideMenu';
import "./styles.scss";

const DefaultLayout: React.FC<React.PropsWithChildren> = ({ children, }) => {
  return (
    <Container fluid className="p-0">
      <DrawerMenu />
      <div>
        <SideMenu />
        <Content>{children}</Content>
      </div>
    </Container>
  );
};

export default DefaultLayout;