import './sync-modal.scss';
import React, {useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Divider} from "@mui/material";
import {getFilterEntities} from "app/entities/shop/shop.reducer";

const SyncModal = ({ open, handleClose, handleSync }) => {
  const [value, setValue] = useState<string>('2D');
  const [selectedOptions, setSelectedOptions] = useState({
    product: true,
    flashSale: false,
    order: true,
    metrics: true,
    productType: '2D',
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    selectedOptions.productType = value;
  }, [value]);

  const handleOptionChange = e => {
    const { name, checked } = e.target;
    setSelectedOptions(prevState => ({
      ...prevState,
      [name]: checked,
      productType: value,
    }));
  };

  const handleSyncClick = () => {
    handleSync(selectedOptions);
    // Reset selected options to default
    setValue('2D');
    setSelectedOptions({
      product: true,
      flashSale: false,
      order: true,
      metrics: true,
      productType: '2D',
    });
  };

  const handleCancelClick = () => {
    setValue('2D');
    setSelectedOptions({
      product: true,
      flashSale: false,
      order: true,
      metrics: true,
      productType: '2D',
    });
    handleClose();
  };

  return (
    <Modal isOpen={open} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Sync Options</ModalHeader>
      <ModalBody>
        <div>
          <label>
            <input type="checkbox" name="product" checked={selectedOptions.product} onChange={handleOptionChange} />
            &nbsp; Sync Products
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" name="order" checked={selectedOptions.order} onChange={handleOptionChange} />
            &nbsp; Sync Orders
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" name="metrics" checked={selectedOptions.metrics} onChange={handleOptionChange} />
            &nbsp; Sync Metrics
          </label>
        </div>
        <Divider />
        <p>
          <label>
            <FormControl>
              <FormLabel>Select Product Type</FormLabel>
              <RadioGroup row aria-label="productType" name="productType" value={value} onChange={handleRadioChange}>
                <FormControlLabel value="2D" control={<Radio />} label="2D" />
                <FormControlLabel value="3D" control={<Radio />} label="3D" />
                <FormControlLabel value="Dropship" control={<Radio />} label="Dropship" />
              </RadioGroup>
            </FormControl>
          </label>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSyncClick}>
          Sync
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SyncModal;
