import React from 'react';

interface PageHeadProps {
    left?: string | React.ReactElement;
    center?: string | React.ReactElement;
    right?: string | React.ReactElement;
}

const PageHead: React.FC<React.PropsWithChildren<PageHeadProps>> = ({ children, left, center, right }) => {
    return (
        <div className='d-flex justify-content-between align-items-center mb-4' style={{ fontSize: "20px" }}>
            {left && <div className='d-flex' style={{ fontSize: "24px" }}>{left}</div>}
            <div>{center || children}</div>
            {right && <div>{right}</div>}
        </div>
    )
}

export default PageHead;