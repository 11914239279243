import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError, ISearchParams } from 'app/shared/reducers/reducer.utils';
import { IUIOrder, defaultValue } from 'app/shared/model/ui-order.model';
import { extend } from 'lodash';


interface IFilterOrder {

}
interface ExtraEntityState extends EntityState<IUIOrder> {
  filterData: IFilterOrder;
}
const initialState: ExtraEntityState = {
  loading: false,
  errorMessage: null,
  entities: null,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  filterData: {}
};

const apiUrl = 'api/ui-orders';

// Actions

export const getEntities = createAsyncThunk(
  'uIOrder/fetch_entity_list',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IUIOrder[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const searchEntities = createAsyncThunk(
  'uIOrder/fetch_entity_list',
  async ({ page, size, sort, shopId, keyword, status, orderStatus, timeCreatedFrom, timeCreatedTo, buyerUsername, product }: ISearchParams) => {
    const requestUrl = `${apiUrl}?shopId=${shopId}&keyword=${keyword}&status=${status}&orderStatus=${orderStatus}
    &timeCreatedFrom=${timeCreatedFrom}&timeCreatedTo=${timeCreatedTo}&buyerUsername=${buyerUsername}&product=${product}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IUIOrder[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const exportOrders = createAsyncThunk(
  'uIOrder/export_orders',
  async (exportIds: Array<number | string>, thunkAPI) => {
    const result = await axios.post<IUIOrder>(`${apiUrl}/export`, exportIds);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const bulkCreateLabel = createAsyncThunk(
  'uIOrder/bulk_create_label',
  async (exportIds: Array<string | number>, thunkAPI) => {
    const result = await axios.post<IUIOrder>(`${apiUrl}/bulk-create-label`, exportIds);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const bulkGetLabel = createAsyncThunk(
  'uIOrder/bulk_get_label',
  async (exportIds: Array<string | number>, thunkAPI) => {
    const result = await axios.post<IUIOrder>(`${apiUrl}/bulk-get-label`, exportIds);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const uploadFile = createAsyncThunk(
  'uIOrder/upload_file',
  async (file: File, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', file);
    const result = await axios.post(`${apiUrl}/import-tracking`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'uIOrder/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUIOrder>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'uIOrder/create_entity',
  async (entity: IUIOrder, thunkAPI) => {
    const result = await axios.post<IUIOrder>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'uIOrder/update_entity',
  async (entity: IUIOrder, thunkAPI) => {
    const result = await axios.put<IUIOrder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'uIOrder/partial_update_entity',
  async (entity: IUIOrder, thunkAPI) => {
    const result = await axios.patch<IUIOrder>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'uIOrder/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IUIOrder>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const UIOrderSlice = createEntitySlice({
  name: 'uIOrder',
  initialState,
  reducers: {

  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = UIOrderSlice.actions;

// Reducer
export default UIOrderSlice.reducer;
