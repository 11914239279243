import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LocalProduct from './local-product';
import LocalProductDetail from './local-product-detail';
import LocalProductUpdate from './local-product-update';
import LocalProductDeleteDialog from './local-product-delete-dialog';

const LocalProductRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LocalProduct />} />
    <Route path="new" element={<LocalProductUpdate />} />
    <Route path=":id">
      <Route index element={<LocalProductDetail />} />
      <Route path="edit" element={<LocalProductUpdate />} />
      <Route path="delete" element={<LocalProductDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LocalProductRoutes;
