import React from 'react';
import './style.scss';

const TruncatedText = ({ text, maxLength = 50 }) => {
  if (typeof text !== 'string') return null;
  const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return <span className="truncated-text text-wrap">{truncatedText}</span>;
};

export default TruncatedText;
