import { useAppDispatch } from 'app/config/store';
import { ILocalProduct } from 'app/shared/model/local-product.model';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { createContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { getPaginationState, IPaginationBaseState } from 'react-jhipster';
import { useLocation } from 'react-router';
import { searchEntities } from '../local-product/local-product.reducer';
import { useFormContext } from 'react-hook-form';
import { cleanObject } from 'app/shared/util';

interface ProductContextType {
  onRefreshTable: (activePage?: number) => void;
  searchProducts: (payload?: Record<string, any>) => void;
  // paginationState?: IPaginationBaseState;
}
export const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const ProductProvider: FC<PropsWithChildren> = ({ children }) => {
  const { watch, getValues } = useFormContext();
  const searchKeyword = watch('searchKeyword');
  const dispatch = useAppDispatch();

  useEffect(() => {
    onRefreshTable();
  }, []);

  useEffect(() => {
    if (searchKeyword !== undefined) {
      onRefreshTable(0);
    }
  }, [searchKeyword]);

  const onRefreshTable = (activePage?: number) => {
    const { paginationState, filter } = getValues();
    dispatch(
      searchEntities(
        cleanObject({
          page: activePage || paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          keyword: searchKeyword,
          ...(filter || {}),
        }),
      ),
    );
  };

  const searchProducts = (payload: Record<string, any> = {}) => {
    const { paginationState } = getValues();
    dispatch(
      searchEntities(
        cleanObject({
          page: payload?.activePage || paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          keyword: searchKeyword,
          ...payload,
        }),
      ),
    );
  };

  return <ProductContext.Provider value={{ onRefreshTable, searchProducts }}>{children}</ProductContext.Provider>;
};
