import React from "react";
const fontSize = 14
export const homeIcon = (
    <svg
        className="theme-arco-icon theme-arco-icon-home_unselect index__icon--K9Hiq"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // style={{ stroke: "unset", color: "rgb(var(--theme-arco-primary-6))" }}
        style={{fontSize}}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.39673 11.3262C5.39673 10.4055 6.14311 9.65912 7.06382 9.65912H8.94178C9.86248 9.65912 10.6089 10.4055 10.6089 11.3262V14.7338H9.2752V11.3262C9.2752 11.1421 9.12592 10.9928 8.94178 10.9928H7.06382C6.87968 10.9928 6.7304 11.1421 6.7304 11.3262V14.7338H5.39673V11.3262Z"
            fill="#009995"
        />
        <path
            d="M7.81936 2.64117C7.93014 2.56882 8.07322 2.56882 8.184 2.64117L13.3455 6.01224C13.4398 6.07381 13.4966 6.1788 13.4966 6.2914V13.0667C13.4966 13.2509 13.3473 13.4002 13.1632 13.4002H10.6089V14.7338H13.1632C14.0839 14.7338 14.8303 13.9874 14.8303 13.0667V6.2914C14.8303 5.72842 14.5461 5.20348 14.0748 4.89563L8.91328 1.52456C8.35939 1.16279 7.64398 1.16279 7.09008 1.52456L1.92858 4.89563C1.45723 5.20348 1.1731 5.72842 1.1731 6.2914V13.0667C1.1731 13.9874 1.91947 14.7338 2.84018 14.7338H5.39673V13.4002H2.84018C2.65604 13.4002 2.50677 13.2509 2.50677 13.0667V6.2914C2.50677 6.1788 2.56359 6.07381 2.65786 6.01224L7.81936 2.64117Z"
            fill="#000"
        />
    </svg>
)

export const orderIcon = (
    <svg
        className="theme-arco-icon theme-arco-icon-order_unselect index__icon--K9Hiq"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="unset"
        color="rgb(var(--theme-arco-primary-6))"
        style={{fontSize}}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.154.837a1 1 0 00-1 1v.081H3.592c-.92 0-1.666.746-1.666 1.667v9.911c0 .92.746 1.667 1.666 1.667h8.816c.92 0 1.667-.746 1.667-1.667V3.585c0-.92-.746-1.667-1.667-1.667h-1.57v-.08a1 1 0 00-1-1H6.154zm4.684 2.414v.084a1 1 0 01-1 1H6.154a1 1 0 01-1-1v-.084H3.592a.333.333 0 00-.333.334v9.911c0 .184.15.334.333.334h8.816c.184 0 .333-.15.333-.334V3.585a.333.333 0 00-.333-.334h-1.57zm-4.351-.25v-.83h3.017V3H6.487z"
            fill="#000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.272 7.245c0 .184.149.333.333.333h4.776c.184 0 .333-.15.333-.333v-.667a.333.333 0 00-.333-.333H5.605a.333.333 0 00-.333.333v.667zm0 2.163c0 .184.149.333.333.333h4.776c.184 0 .333-.15.333-.333V8.74a.333.333 0 00-.333-.333H5.605a.333.333 0 00-.333.333v.667z"
            fill="#009995"
        />
    </svg>
)

export const productIcon = (
    <svg
      className="theme-arco-icon theme-arco-icon-products_unselected index__icon--K9Hiq"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="unset"
      color="rgb(var(--theme-arco-primary-6))"
      style={{fontSize}}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.412 1.825c-.863 0-1.583.658-1.66 1.517l-.82 9.015a1.667 1.667 0 001.661 1.818h8.818c.98 0 1.749-.842 1.66-1.818l-.818-9.015a1.667 1.667 0 00-1.66-1.517H4.411zM4.08 3.462a.333.333 0 01.332-.303h7.18c.173 0 .317.132.332.303l.82 9.015a.333.333 0 01-.333.364H3.593a.333.333 0 01-.332-.364l.819-9.015z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.052 7.335c-.877 0-1.607-.63-1.763-1.462-.034-.181-.18-.332-.364-.332h-.667c-.184 0-.335.15-.316.333a3.127 3.127 0 006.22 0c.02-.183-.132-.333-.316-.333h-.667c-.184 0-.33.151-.364.332a1.794 1.794 0 01-1.763 1.462z"
        fill="#009995"
      />
    </svg>
)

