import { Tab, Tabs } from '@mui/material';
import { a11yProps } from 'app/entities/ui-order/custom-tab-panel';
import React, { useContext, useEffect, useState } from 'react';
import { OrderContext } from '../OrderContext';

const orderStatusOptions = [
  {
    label: 'All',
    index: 0,
    value: '',
  },
  {
    label: 'Need Design',
    index: 1,
    value: 'NEED_DESIGN',
  },
  {
    label: 'To ship',
    index: 2,
    value: 'TO_SHIP',
  },
  {
    label: 'Shipped',
    index: 3,
    value: 'IN_TRANSIT',
  },
  {
    label: 'Completed',
    index: 4,
    value: 'COMPLETED',
  },
  {
    label: 'Pending',
    index: 5,
    value: 'ON_HOLD',
  },
  {
    label: 'Canceled',
    index: 6,
    value: 'CANCELLED',
  },
];

const OrderStatusTab = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { orderStatus, setOrderStatus, filterData } = useContext(OrderContext);

  useEffect(() => {
    if (orderStatus !== filterData?.orderStatus) {
      const tabInx = orderStatusOptions.findIndex(tab => tab.value === filterData.orderStatus);
      if (tabInx !== null) {
        setActiveTab(tabInx);
      }
    }
  }, [filterData?.orderStatus]);

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setOrderStatus(orderStatusOptions[tabIndex].value);
    setActiveTab(tabIndex);
  };

  return (
    <div className="mb-2">
      <Tabs value={activeTab} onChange={handleChange} aria-label="order filter tabs">
        {orderStatusOptions.map(option => (
          <Tab key={option.value} label={option.label} {...a11yProps(option.index)} />
        ))}
      </Tabs>
    </div>
  );
};

export default OrderStatusTab;
