import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IGlobalState {
    drawerOpen?: boolean;
}
const initialState: IGlobalState = {
    drawerOpen: false,
}
export const LayoutSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        toggleDrawer(state) {
            state.drawerOpen = !state.drawerOpen;
        },
    },
});
// Actions
export const { toggleDrawer } = LayoutSlice.actions;
// Reducer
export default LayoutSlice.reducer;
