import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getPaginationState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './flash-sale-schedule.reducer';
import { getFilterEntities } from '../shop/shop.reducer';
import FlashSaleScheduleDeleteDialog from './flash-sale-schedule-delete-dialog';
import ShopAutocomplete from '../shop/shop-autocomplete';

export const FlashSaleSchedule = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(null);

  const flashSaleScheduleList = useAppSelector(state => state.flashSaleSchedule.entities);
  const loading = useAppSelector(state => state.flashSaleSchedule.loading);
  const totalItems = useAppSelector(state => state.flashSaleSchedule.totalItems);
  const [filterShopId, setFilterShopId] = React.useState('');

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const openDeleteModal = id => {
    setEntityToDelete(id);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setEntityToDelete(null);
  };

  useEffect(() => {
    if (filterShopId) {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          shopId: filterShopId
        })
      );
    } else {
      getAllEntities();
    }
  }, [filterShopId]);
  return (
    <div>
      <h2 id="flash-sale-schedule-heading" data-cy="FlashSaleScheduleHeading">
        <Translate contentKey="dmmtiktokApp.flashSaleSchedule.home.title">Flash Sale Schedules</Translate>
        <div className="d-flex justify-content-end">
          <Button size='sm' className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="dmmtiktokApp.flashSaleSchedule.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <ShopAutocomplete onShopChange={setFilterShopId} />
        </div>
      </h2>
      <div className="table-responsive">
        {flashSaleScheduleList && flashSaleScheduleList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.id">ID</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
              </th>
              <th className="hand" onClick={sort('cron')}>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.cron">Cron</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('cron')} />
              </th>
              <th className="hand" onClick={sort('status')}>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.status">Status</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('status')} />
              </th>
              <th className="hand" onClick={sort('discount')}>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.discount">Discount</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('discount')} />
              </th>
              <th className="hand" onClick={sort('duration')}>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.duration">Duration</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('duration')} />
              </th>
              <th className="hand" onClick={sort('description')}>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.description">Description</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('description')} />
              </th>
              <th>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.shop">Shop</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th>
                <Translate contentKey="dmmtiktokApp.flashSaleSchedule.user">User</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th />
            </tr>
            </thead>
            <tbody>
            {flashSaleScheduleList.map((flashSaleSchedule, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/flash-sale-schedule/${flashSaleSchedule.id}`} color="link" size="sm">
                    {flashSaleSchedule.id}
                  </Button>
                </td>
                <td>{flashSaleSchedule.cron}</td>
                <td>{flashSaleSchedule.status}</td>
                <td>{flashSaleSchedule.discount}</td>
                <td>{flashSaleSchedule.duration}</td>
                <td>{flashSaleSchedule.description}</td>
                <td>
                  {flashSaleSchedule.shop ? <Link to={`/shop/${flashSaleSchedule.shop.id}`}>{flashSaleSchedule.shop.name}</Link> : ''}
                </td>
                <td>{flashSaleSchedule.user ? flashSaleSchedule.user.id : ''}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/flash-sale-schedule/${flashSaleSchedule.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/flash-sale-schedule/${flashSaleSchedule.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                    </Button>
                    <Button
                      onClick={() => openDeleteModal(flashSaleSchedule.id)}
                      color="danger"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />{' '}
                      <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="dmmtiktokApp.flashSaleSchedule.home.notFound">No Flash Sale Schedules found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={flashSaleScheduleList && flashSaleScheduleList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {deleteModalOpen && (
        <FlashSaleScheduleDeleteDialog
          id={entityToDelete}
          onClose={closeDeleteModal}
        />
      )}
    </div>
  );
};

export default FlashSaleSchedule;
