import React from 'react'
import { Label, LabelProps, Table } from 'reactstrap'

export const BlockTitle = ({ text = null }) => {
    return (
        <h4 style={{ fontSize: "20px" }} className="d-flex mb-4">
            {text}
        </h4>
    )
}

export const PageTitle: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div style={{ fontSize: "20px" }}>
            {children}
        </div>
    )
}

interface FormLabelType extends LabelProps {
    text?: string,
    required?: boolean,
    htmlFor?: string
}
export const FormLabel: React.FC<React.PropsWithChildren<FormLabelType>> = ({ text = null, required = undefined, htmlFor, children, ...props }) => {

    return (
        <Label {...props} className={`form-label ${props.className}`} for={htmlFor}>
            {required && <span className="text-danger me-1">*</span>}
            <span>{text || children}</span>
        </Label>
    )
}

const baseColumns = [
    {
        title: "Variation name"
    },
    {
        title: "Product indentifier code"
    },
    {
        title: "Retail price"
    },
    {
        title: "Quantity"
    },
    {
        title: "Seller SKU"
    },
]
export const EmptyVariantTable = () => {
    return (
        <Table hover bordered striped>
            <thead style={{ backgroundColor: '#f0f0f0' }}>
                <tr>
                    {
                        baseColumns.map((c) => (
                            <th className='form-label' key={c.title}>
                                {["Retail price", "Quantity"].includes(c.title) && <span className="text-danger me-1">*</span>}
                                <span>{c.title}</span>
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={baseColumns.length} className='p-0 bg-white'>
                        <div style={{ height: 96 }} className='d-flex justify-content-center align-items-center bg-white'>
                            No available SKUs
                        </div>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}