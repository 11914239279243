import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity } from '../local-product/local-product.reducer';

export const LocalProductDeleteDialog = ({ isOpen, onClose, product }) => {
  const dispatch = useAppDispatch();

  const updateSuccess = useAppSelector(state => state.localProduct.updateSuccess);

  useEffect(() => {
    if (updateSuccess) {
      onClose();
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(product?.id));
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose} data-cy="localProductDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="dmmtiktokApp.localProduct.delete.question">
        <Translate contentKey="dmmtiktokApp.localProduct.delete.question" interpolate={{ id: product?.id }}>
          Are you sure you want to delete this LocalProduct?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-localProduct" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LocalProductDeleteDialog;
