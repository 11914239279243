import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormGroup, Label, Input, Row, Col, FormFeedback } from 'reactstrap';
import { BlockTitle, FormLabel } from './common';

const Shipping = () => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <BlockTitle text="Shipping" />
      <Row>
        <Col md={6}>
          <FormGroup>
            <FormLabel htmlFor='packageWeight' required>Weight</FormLabel>
            <Controller
              name="packageWeight"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} type="number" />}
              rules={{ required: 'Weight is required' }}
            />
            {errors.packageWeight && <FormFeedback>{errors.packageWeight.message && String(errors.packageWeight.message)}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FormLabel htmlFor='weightUnit' required>Weight Unit</FormLabel>
            <Controller
              name="weightUnit"
              control={control}
              defaultValue="lb"
              render={({ field }) => (
                <Input {...field} type="select">
                  <option value="lb">Pound (lb)</option>
                  <option value="kg">Kilogram (kg)</option>
                </Input>
              )}
            />
            {errors.weightUnit && <FormFeedback>{errors.weightUnit.message && String(errors.weightUnit.message)}</FormFeedback>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <FormLabel htmlFor='packageHeight' required>Package Height</FormLabel>
            <Controller
              name="packageHeight"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} type="number" placeholder='Height' />}
              rules={{ required: 'Package height is required' }}
            />
            {errors.packageHeight && <FormFeedback>{errors.packageHeight.message && String(errors.packageHeight.message)}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormLabel htmlFor='packageWidth' required>Package Width</FormLabel>
            <Controller
              name="packageWidth"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} type="number" placeholder='Width' />}
              rules={{ required: 'Package width is required' }}
            />
            {errors.packageWidth && <FormFeedback>{errors.packageWidth.message && String(errors.packageWidth.message)}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <FormLabel htmlFor='packageLength' required>Package Length</FormLabel>
            <Controller
              name="packageLength"
              control={control}
              defaultValue=""
              render={({ field }) => <Input {...field} type="number" placeholder='Length' />}
              rules={{ required: 'Package length is required' }}
            />
            {errors.packageLength && <FormFeedback>{errors.packageLength.message && String(errors.packageLength.message)}</FormFeedback>}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default Shipping;
