const buildVariants = (variants: Array<any>) => {
  const result: string[][] = [[]];

  for (const variant of variants) {
    const newResult: string[][] = [];
    for (const combination of result) {
      for (const option of variant.options) {
        if (option.value) {
          newResult.push([...combination, option.value]);
        }
      }
    }
    result.splice(0, result.length, ...newResult);
  }

  const variantColumnNames = variants.map(v => v.name);
  const columnNames = variantColumnNames.concat(['Product identifier code Type', 'Product identifier code', 'Retail price', 'Base cost', 'Quantity', 'Seller SKU']);
  const tableData = result.map(combination => {
    const row: { [key: string]: string | number } = {};
    let rowKey = '';
    variants.forEach((variant, index) => {
      row[variant.name] = combination[index] || '';
      rowKey = `${rowKey}_${combination[index]}`;
    });
    row['rowKey'] = rowKey; // Example value
    row['identifierCodeType'] = 'GTIN'; // Example value
    row['identifierCode'] = ''; // Example value
    row['retailPrice'] = 0; // Example value
    row['baseCost'] = 0; // Example value
    row['quality'] = 0;     // Example value
    row['sellerSku'] = 0;    // Example value
    return row;
  });
  return {
    variantColumnNames,
    columnNames,
    tableData
  };
};


export interface HeaderItem {
  key: string;
  label: string;
}

export interface TableItem {
  [key: string]: string | number;
}
export interface IVariantList {
  header: HeaderItem[],
  headerKey: Array<string>,
  tableData: TableItem[],
  tableRow: Record<string, TableItem>
  rowKeys: Array<string>
}
const buildVariantsV1 = (variants: Array<any>): IVariantList => {
  const header: Array<HeaderItem> = [];
  const table: Array<TableItem> = [];

  // Filter out variants with empty names
  const filteredVariants = variants
    .filter(variant => variant.name && variant.values.some(option => option.value));

  // Create header
  filteredVariants.forEach((variant, index) => {
    header.push({
      key: `variation${index + 1}`,
      label: variant.name
    });
  });
  const headerKey = header.map(item => item.key)
  // Create table rows
  const tableRow = {}
  const rowKeys = []
  const buildTable = (rows: Array<TableItem>, variantIndex: number, currentRow: TableItem) => {
    if (variantIndex === filteredVariants.length) {
      table.push(currentRow);
      let rowKey = ""
      for (const hk of headerKey) {
        rowKey += `${currentRow[hk]}`
      }
      rowKeys.push(rowKey)
      tableRow[rowKey] = currentRow
      return;
    }

    const variant = filteredVariants[variantIndex];
    variant.values
      .filter(option => option.value) // Skip options with empty value
      .forEach(option => {
        buildTable(rows, variantIndex + 1, {
          ...currentRow,
          [`variation${variantIndex + 1}`]: option.value
        });
      });
  };

  if (filteredVariants.length) {
    buildTable(table, 0, {});
  }

  const extraColumns = [
    { key: "identifierCodeType", label: "Product identifier code" },
    { key: "retailPrice", label: "Retail price" },
    { key: "baseCost", label: "Base cost" },
    { key: "quantity", label: "Quantity" },
    { key: "sellerSku", label: "Seller SKU" }
  ]
  header.push(...extraColumns)
  table.forEach(tbld => {
    for (const exc of extraColumns) {
      tbld[exc.key] = ''
    }
    tbld["identifierCodeType"] = 'GTIN'
    tbld["identifierCode"] = ''
  })
  return { header, tableRow, tableData: table, headerKey, rowKeys };
}


const buildVariationAttributes = (variants: Array<any>) => {
  return variants.map(variant => ({
    name: variant.name,
    values: variant.values.filter(option => option.value).map(option => option.value)
  }));
};

export default {
  buildVariants,
  buildVariantsV1,
  buildVariationAttributes
};
