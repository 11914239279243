import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from "app/config/store";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import 'react-quill/dist/quill.snow.css';
import { Input, Label } from "reactstrap";

const PreviewImage = ({ image, maxImageWidth, onClear }) => {
    return (
        <div className='preview-image position-relative'>
            <img src={image} alt="Preview" style={{ maxWidth: '100%', maxHeight: "100%", objectFit: "contain", width: maxImageWidth || "auto" }} />
            <a className='position-absolute' onClick={onClear}>
                <FontAwesomeIcon style={{ fontSize: 20, color: "white" }} icon={faClose} />
            </a>
        </div>
    )
}


type FileAttachProps = {
    prefixName?: string,
    name: string,
    labelText?: string,
    icon?: IconProp,
    imgIcon?: string,
    iconColor?: string
    center?: boolean,
    maxImageWidth?: number | string
}

const FileAttach: React.FC<React.PropsWithChildren<FileAttachProps>> = ({ prefixName, name, labelText, icon = null, imgIcon = null,
    iconColor = null, center = true, maxImageWidth, children }) => {
    const [previewSrc, setPreviewSrc] = useState<string | null>(null);
    const { control, getValues, setValue, watch } = useFormContext();
    const value = watch(name)

    useEffect(() => {
        if (value && !(value instanceof File)) {
            setPreviewSrc(value)
        }
    }, [value]);

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const file = files[0];
            setPreviewSrc(URL.createObjectURL(file)); // Create URL for the selected file
            setValue(name, file)
        }
    };

    const label = (
        <div className={`d-flex flex-column w-100 h-100 justify-content-center ${center ? 'align-items-center' : 'align-items-start'}`}>
            <Label style={{ cursor: "pointer" }} className="mb-0 d-flex flex-column align-items-center" htmlFor={name}>
                {icon && <FontAwesomeIcon icon={icon} size="3x" color={iconColor} />}
                {imgIcon && <img width={64} height={64} src={imgIcon} />}
                {labelText && <p style={{ color: "rgba(0,0,0,.35)", fontSize: "12px" }} className="mb-0">{labelText}</p>}
            </Label>
        </div>
    )

    const content = children || label;

    const handleClear = () => {
        setPreviewSrc(null);
        setValue(name, null)
    }

    return (
        <>
            {previewSrc ? <PreviewImage onClear={handleClear} maxImageWidth={maxImageWidth} image={previewSrc} /> : content}
            <Controller
                control={control}
                name={prefixName ? `${prefixName}.${name}` : name}
                rules={{
                    validate() {
                        const allFiles = getValues();
                        if (allFiles.images) {
                            const filesAttached = Object.values(allFiles.images).filter((file: Array<File>) => file !== undefined);
                            return filesAttached.length > 0 || 'At least one file must be attached.';
                        }
                        return true;
                    }
                }}
                render={({ field: { onChange, value, ...field } }) => (
                    <Input
                        {...field}
                        type="file"
                        id={name}
                        style={{ display: 'none' }}
                        accept="image/*"
                        multiple={false}
                        onChange={(e) => {
                            handleFileChange(e.target.files);
                            onChange(e.target.files[0])
                        }}
                    // invalid={!!errors.file}
                    />
                )}
            />
        </>
    )
}

export default FileAttach;