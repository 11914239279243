import React, { FC, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { BlockTitle, FormLabel } from './common';
import FileAttach from './FileAttach';
import { closeUp, differentAngles, inUse, primaryImage, secondaryImage, sizeAndScale, styleScenes, variations } from './images';
import QuillEditor from './QuillEditor';
import SizeChart from './SizeChart';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getProductAttribute, getProductCategory } from '../product.reducer';
import { MenuItem, Select } from '@mui/material';
import { IProductAttribute } from 'app/shared/model/local-product.model';
import MegaProductCategory from './MegaProductCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinning from 'app/shared/components/Spinning';

const cellStyle: React.CSSProperties = {
  width: '150px',
  height: '150px',
  textAlign: 'center',
  verticalAlign: 'middle',
  backgroundColor: 'white', // Cell background color
  border: '1px solid #dee2e6', // Bootstrap's default border color
  borderRadius: '5px',
  borderStyle: 'dashed',
};

const mergedCellStyle: React.CSSProperties = {
  ...cellStyle,
  width: '305px', // 2 * 150px + 5px gap
  height: '305px', // 2 * 150px + 5px gap
};

interface ProductAttributeComProps {
  index: number;
}
const ProductAttributeCom: React.FC<ProductAttributeComProps> = ({ index }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const attributes = useAppSelector<Array<IProductAttribute>>(state => state.product.productAttributes);
  const attribute = attributes?.[index];

  return (
    <Col sm={12} md={4}>
      {attribute ? (
        <>
          <FormLabel style={{ marginBottom: 0 }} required={attribute?.is_requried}>
            {attribute?.name}
          </FormLabel>
          <Controller
            control={control}
            name={`productAttributes.${index}.selectedId`}
            render={({ field }) => (
              <Select
                {...field}
                size="small"
                style={{ width: '100%' }}
                value={field.value || []} // Ensure value is always an array
                onChange={event => field.onChange(event.target.value)}
                multiple={attribute?.is_multiple_selection}
              >
                {attribute?.values?.map(attr => (
                  <MenuItem key={attr.id} value={attr.id}>
                    <span>{attr.name}</span>
                  </MenuItem>
                ))}
              </Select>
            )}
            rules={{ required: attribute.is_requried ? `${attribute.name} is required` : undefined }}
          />
          {errors.attributes?.[index]?.name && <FormFeedback>{errors.attributes?.[index]?.name?.message as string}</FormFeedback>}
        </>
      ) : null}
    </Col>
  );
};

interface ProductBasicInfomationProps {
  nonImages?: boolean;
  nonTitle?: boolean;
  nonCategory?: boolean;
}
export const ProductBasicInfomation: FC<ProductBasicInfomationProps> = ({ nonImages, nonTitle, nonCategory }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  // const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { fields: attributeFields } = useFieldArray({ control, name: 'productAttributes' });
  const dispatch = useAppDispatch();
  const attributeLoading = useAppSelector(state => state.product.attributeLoading);
  const attributes = useAppSelector(state => state.product.productAttributes);

  const categoryIdValue = watch('categoryId');

  useEffect(() => {
    dispatch(getProductCategory());
  }, []);

  useEffect(() => {
    if (categoryIdValue) {
      dispatch(getProductAttribute(categoryIdValue));
    }
  }, [categoryIdValue]);

  useEffect(() => {
    if (attributes) {
      setValue('productAttributes', attributes);
    }
  }, [attributes]);

  return (
    <>
      {nonTitle ? null : <BlockTitle text="Basic Infomation" />}
      {nonImages ? null : (
        <Label className="form-label" for="name">
          <span className="text-danger me-1">*</span>Product Image
        </Label>
      )}
      <Row className="justify-content-center">
        {nonImages ? null : (
          <Col md={12} className="mb-4">
            <table style={{ borderCollapse: 'separate' }} className="table-bordered table-custom-spacing">
              <tbody>
                <tr>
                  <td rowSpan={2} colSpan={2} style={mergedCellStyle} className="position-relative">
                    <FileAttach
                      maxImageWidth={mergedCellStyle.width}
                      name="images.mainProductImage"
                      labelText="Upload main image"
                      imgIcon={primaryImage}
                    />
                  </td>
                  <td style={cellStyle}>
                    <FileAttach maxImageWidth={cellStyle.width} name="images.productImage2" labelText="Primary" imgIcon={primaryImage} />
                  </td>
                  <td style={cellStyle}>
                    <FileAttach
                      maxImageWidth={cellStyle.width}
                      name="images.productImage3"
                      labelText="Secondary"
                      imgIcon={secondaryImage}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>
                    <FileAttach
                      maxImageWidth={cellStyle.width}
                      name="images.productImage4"
                      labelText="Different Angles"
                      imgIcon={differentAngles}
                    />
                  </td>
                  <td style={cellStyle}>
                    <FileAttach maxImageWidth={cellStyle.width} name="images.productImage5" labelText="In Use" imgIcon={inUse} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>
                    <FileAttach maxImageWidth={cellStyle.width} name="images.productImage6" labelText="Variations" imgIcon={variations} />
                  </td>
                  <td style={cellStyle}>
                    <FileAttach
                      maxImageWidth={cellStyle.width}
                      name="images.productImage7"
                      labelText="Style Scenes"
                      imgIcon={styleScenes}
                    />
                  </td>
                  <td style={cellStyle}>
                    <FileAttach maxImageWidth={cellStyle.width} name="images.productImage8" labelText="Close Up" imgIcon={closeUp} />
                  </td>
                  <td style={cellStyle}>
                    <FileAttach
                      maxImageWidth={cellStyle.width}
                      name="images.productImage9"
                      labelText="Size & Scale"
                      imgIcon={sizeAndScale}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {errors?.images && <FormFeedback>Please upload at least one image to set as the product image</FormFeedback>}
          </Col>
        )}
        <Col md={12}>
          {nonImages ? null : (
            <FormGroup>
              <FormLabel htmlFor="productName" text={'Product Name'} required />
              <Controller
                control={control}
                name="productName" // Ensure the name field is correct
                defaultValue=""
                render={({ field }) => <Input {...field} />}
                rules={{ required: 'Product name is required' }}
              />
              {errors.productDescription && <FormFeedback>{errors.productDescription?.message as string}</FormFeedback>}
            </FormGroup>
          )}
          {nonCategory ? null : (
            <>
              <FormGroup>
                <FormLabel htmlFor="categoryId" text={'Product Category'} required />
                <Controller
                  control={control}
                  name="categoryId" // Ensure the name field is correct
                  render={({ field }) => <MegaProductCategory container="product-list-page" onSelect={catId => field.onChange(catId)} />}
                  rules={{ required: 'Product name is required' }}
                />
                {errors.category && <FormFeedback>{errors.category?.message as string}</FormFeedback>}
              </FormGroup>
              <Spinning isLoading={attributeLoading}>
                <FormGroup>
                  <FormLabel className="mb-2" htmlFor="attribute" text={'Product Attribute'} />
                  {attributeFields?.length ? (
                    <Row className="g-2 bg-light rounded-2 p-2">
                      {attributeFields.map((attrField, attrIndex) => (
                        <ProductAttributeCom key={attrField.id} index={attrIndex} />
                      ))}
                    </Row>
                  ) : (
                    <div className="bg-light rounded-2 p-2 d-flex justify-content-center align-items-center" style={{ minHeight: 100 }}>
                      Select category to show attribute
                    </div>
                  )}
                </FormGroup>
              </Spinning>
            </>
          )}
          <FormGroup>
            <Label className="form-label" for="product_description">
              <span className="text-danger me-1">*</span>Product Description
            </Label>
            <Controller
              name="productDescription"
              control={control}
              defaultValue=""
              render={({ field }) => <QuillEditor value={field.value} onChange={field.onChange} />}
              rules={{ required: 'Product description is required' }}
            />
            {errors.productDescription && <FormFeedback>{errors.productDescription?.message as string}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={12}>
          <SizeChart />
        </Col>
      </Row>
    </>
  );
};

export default ProductBasicInfomation;
