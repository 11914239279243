import { IShop } from 'app/shared/model/shop.model';
import { IUser } from 'app/shared/model/user.model';
import { IUILineItem } from 'app/shared/model/ui-line-item.model';

export interface IUIOrder {
  id?: string;
  createTime?: number | null;
  buyer?: string | null;
  status?: string | null;
  shippingType?: string | null;
  deliveryOptionName?: string | null;
  total?: number | null;
  shop?: IShop | null;
  user?: IUser | null;
  lineItems?: IUILineItem[] | null;
}

export const defaultValue: Readonly<IUIOrder> = {};
