import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ExportHistory from './export-history';
import ExportHistoryDetail from './export-history-detail';
import ExportHistoryUpdate from './export-history-update';
import ExportHistoryDeleteDialog from './export-history-delete-dialog';

const ExportHistoryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ExportHistory />} />
    <Route path="new" element={<ExportHistoryUpdate />} />
    <Route path=":id">
      <Route index element={<ExportHistoryDetail />} />
      <Route path="edit" element={<ExportHistoryUpdate />} />
      <Route path="delete" element={<ExportHistoryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ExportHistoryRoutes;
