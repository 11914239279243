// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the container takes full height */
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/home/home.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA,EAAA,2CAAA;EACA,UAAA;AACF","sourcesContent":[".home-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  padding: 20px;\n  background-color: #ffffff;\n  border-radius: 8px;\n}\n\n.center-div {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%; /* Ensure the container takes full height */\n  width: 80%;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
