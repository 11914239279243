import axios from 'axios';
import { createAsyncThunk, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/metrics';
import { IMetrics } from 'app/shared/model/metrics';

const initialState: EntityState<IMetrics> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = 'api/dashboard';

export const fetchMetrics = createAsyncThunk(
  'dashboard/fetch_dashboard_metrics',
  async ({ startDate, endDate, shopId }: { startDate: number, endDate: number, shopId: string }) => {
    let requestUrl = `${apiUrl}?`;
    if (shopId !== null) {
      requestUrl += `shopId=${shopId}&`;
    }
    if (startDate !== null) {
      requestUrl += `startDate=${startDate}&`;
    }
    if (endDate !== null) {
      requestUrl += `endDate=${endDate}&`;
    }
    // Remove the trailing ampersand
    requestUrl = requestUrl.slice(0, -1);
    return axios.get<IMetrics>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const DashboardMetricSlice = createEntitySlice({
  name: 'dashboardMetrics',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(fetchMetrics), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  }
});

export const { reset } = DashboardMetricSlice.actions;

// Reducer
export default DashboardMetricSlice.reducer;
