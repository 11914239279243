import './header.scss';

import React, { useState } from 'react';
import { Storage, Translate } from 'react-jhipster';
import LoadingBar from 'react-redux-loading-bar';
import { Badge, Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';

import { useAppDispatch } from 'app/config/store';
import AccountDropdown from 'app/shared/components/AccountDropdown';
import LanguageDropdown from 'app/shared/components/LanguageDropdown';
import { setLocale } from 'app/shared/reducers/locale';
import { Brand } from './header-components';
import { Button } from '@mui/material';
import { toggleDrawer } from 'app/modules/layout/layout.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  userType: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useAppDispatch();

  const handleLocaleChange = langKey => {
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => {
    // setMenuOpen(!menuOpen);
    dispatch(toggleDrawer())
  }

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />

      <Navbar data-cy="navbar" dark expand="md" fixed="top" className="bg-black">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ms-auto" navbar>
            <div className='d-flex gap-3 justify-content-center align-items-center'>
              {
                props.isAuthenticated ?
                  (props.userType === 'PAID' ?
                    (<Badge color={'info'}>{props.userType}</Badge>) :
                    (<Button href={"/pricing"}><Badge color={'danger'}>Trial(Upgrade Now)</Badge></Button>)
                  ) : ''
              }
              <AccountDropdown />
            </div>
          </Nav>
        </Collapse>
      </Navbar>

    </div>
  );
};

export default Header;
