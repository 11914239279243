import React, { useEffect, useState } from 'react';
import {
  Drawer, Box, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Button, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getFilterEntities } from '../shop/shop.reducer';

const FilterDrawer = ({ open, onClose, onApply, shops }) => {
  const [filters, setFilters] = useState({
    timeCreatedFrom: null,
    timeCreatedTo: null,
    orderStatus: '',
    // urgent: '',
    certificateOfAuthenticity: '',
    buyerUsername: '',
    product: '',
    shopId: '',
    // add the rest of your filter states here
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleDateChange = (name, date) => {
    setFilters({
      ...filters,
      [name]: date.getTime(),
    });
  };

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleClearAll = () => {
    setFilters({
      timeCreatedFrom: null,
      timeCreatedTo: null,
      orderStatus: '',
      // urgent: '',
      certificateOfAuthenticity: '',
      buyerUsername: '',
      product: '',
      shopId: '',
      // reset the rest of your filter states here
    });
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 300, padding: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Filters</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Time created (From)"
              value={filters.timeCreatedFrom}
              onChange={(date) => handleDateChange('timeCreatedFrom', date)}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <DatePicker
              label="Time created (To)"
              value={filters.timeCreatedTo}
              onChange={(date) => handleDateChange('timeCreatedTo', date)}
            />
          </FormControl>
        </LocalizationProvider>
        {/* <FormControl fullWidth margin="normal">
          <InputLabel>Order Status</InputLabel>
          <Select
            name="orderStatus"
            value={filters.orderStatus}
            onChange={handleChange}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value="AWAITING_SHIPMENT">Awaiting Shipment</MenuItem>
            <MenuItem value="AWAITING_COLLECTION">Awaiting collection</MenuItem>
            <MenuItem value="IN_TRANSIT">In Transit</MenuItem>
            <MenuItem value="DELIVERED">Delivered</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Shop</InputLabel>
          <Select
            name="shopId"
            value={filters.shopId}
            onChange={handleChange}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            {shops.map((shop) => (
              <MenuItem key={shop.id} value={shop.id}>
                {shop.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/*<FormControl fullWidth margin="normal">*/}
        {/*  <InputLabel>Urgent</InputLabel>*/}
        {/*  <Select*/}
        {/*    name="urgent"*/}
        {/*    value={filters.urgent}*/}
        {/*    onChange={handleChange}*/}
        {/*  >*/}
        {/*    <MenuItem value=""><em>None</em></MenuItem>*/}
        {/*    <MenuItem value="SHIP_WITHIN_24_HOURS">To ship within 24 hours</MenuItem>*/}
        {/*    <MenuItem value="CANCELED_WITHIN_24_HOURS">To be canceled within 24 hours</MenuItem>*/}
        {/*    <MenuItem value="CANCELLATION_REQUESTED">Cancellation requested</MenuItem>*/}
        {/*    <MenuItem value="SHIPMENT_OVERDUE">Shipment overdue</MenuItem>*/}
        {/*    <MenuItem value="ABNORMAL_PACKAGES">Abnormal packages</MenuItem>*/}
        {/*  </Select>*/}
        {/*</FormControl>*/}
        <TextField
          fullWidth
          margin="normal"
          label="Buyer username"
          name="buyerUsername"
          value={filters.buyerUsername}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Product"
          name="product"
          value={filters.product}
          onChange={handleChange}
        />
        {/* Add the rest of your filter inputs here */}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleClearAll}>Clear all</Button>
          <Button variant="contained" color="primary" onClick={handleApply}>Apply</Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
