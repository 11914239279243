import { Drawer } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'app/config/store'
import { Brand } from 'app/shared/layout/header/header-components'
import React from 'react'
import { toggleDrawer } from './layout.reducer'
import { SideBarContent } from './SideMenu'
import { useSideMenu } from './useSideMenu'

const DrawerMenu = () => {
    const dispatch = useAppDispatch();
    const drawerOpen = useAppSelector(state => state.layout.drawerOpen)
    const menu = useSideMenu()

    const handleToggleDrawer = () => dispatch(toggleDrawer());

    return (
        <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={handleToggleDrawer}
        >
            <div className='d-flex justify-content-center drawer-header'>
                <Brand />
            </div>
            <SideBarContent menu={menu} />
        </Drawer>
    )
}

export default DrawerMenu