import {
  faArrowLeft,
  faAsterisk,
  faBan,
  faBell,
  faBolt,
  faBook,
  faCloud,
  faCogs,
  faDatabase,
  faEye,
  faFlag,
  faHeart,
  faHome,
  faList,
  faLock,
  faMoneyBill,
  faPencilAlt,
  faPlus,
  faRoad,
  faRocket,
  faSave,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faStore,
  faSync,
  faTachometerAlt,
  faTasks,
  faThList,
  faTimesCircle,
  faTrash,
  faUser,
  faUserPlus,
  faUsers,
  faUsersCog,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
  library.add(
    faArrowLeft,
    faAsterisk,
    faBan,
    faBell,
    faBook,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSignInAlt,
    faSignOutAlt,
    faSearch,
    faSort,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimesCircle,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faMoneyBill,
    faStore,
    faRocket,
    faBolt,
  );
};
