import React from 'react';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import FileAttach from './FileAttach';
import { FormLabel } from './common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from 'reactstrap';
const cellStyle: React.CSSProperties = {
    border: '1px solid #dee2e6', // Bootstrap's default border color
    borderRadius: "5px",
    borderStyle: "dashed",
    gap: "4px",
    padding: "10px",
    width: '150px',
    height: '150px',
}
const SizeChart = () => {
    return (
        <div className='d-flex flex-column'>
            <FormLabel htmlFor='sizeChart'>Size Chart</FormLabel>
            <FileAttach name="sizeChart" center={false} maxImageWidth={150}>
                <Label htmlFor='sizeChart' className='d-flex flex-column justify-content-center align-items-center' style={cellStyle}>
                    <FontAwesomeIcon icon={faImage} size="2x" color={'rgba(0, 0, 0, 0.1)'} />
                    <p style={{ color: "rgba(0,0,0,.5)", fontSize: "14px" }} className="mb-0">Upload size chart</p>
                </Label>
            </FileAttach>
        </div>
    )
}

export default SizeChart;