import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/estimate-cost';
import {IEstimatedProfit} from "app/shared/model/estimated-profit";

const initialState: EntityState<IEstimatedProfit> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/dashboard';

export const fetchEstimatedProfits = createAsyncThunk(
  'estimatedProfit/fetch_estimated_profit_list',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}/estimated-profit?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IEstimatedProfit[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const fetchTodayEstimatedProfits = createAsyncThunk(
  'estimatedProfit/fetch_today_estimated_profit',
  async (pickedDate: string) => {
    const requestUrl = `${apiUrl}/estimated-profit-date?date=${pickedDate}`;
    return axios.get<IEstimatedProfit>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const EstimatedProfitSlice = createEntitySlice({
  name: 'estimatedProfit',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchTodayEstimatedProfits.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(fetchEstimatedProfits), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(fetchTodayEstimatedProfits, fetchEstimatedProfits), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = EstimatedProfitSlice.actions;

// Reducer
export default EstimatedProfitSlice.reducer;
