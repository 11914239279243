import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UIOrder from './ui-order';
import UIOrderDetail from './ui-order-detail';
import UIOrderUpdate from './ui-order-update';
import UIOrderDeleteDialog from './ui-order-delete-dialog';

const UIOrderRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UIOrder />} />
    <Route path=":id">
      <Route index element={<UIOrderDetail />} />
      <Route path="delete" element={<UIOrderDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UIOrderRoutes;
