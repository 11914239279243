/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ShopAutocomplete from 'app/entities/shop/shop-autocomplete';
import React, { FC, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { getProductCategory } from '../../product.reducer';
import MegaProductCategory from '../MegaProductCategory';
import ConfirmModal from './ConfirmModal';
import './style.scss';

interface PublicToShopProps {
  onClearProductsSelected?: () => void;
}

const PublicToShop: FC<PublicToShopProps> = ({ onClearProductsSelected }) => {
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [categorySelectedName, setCategorySelectedName] = useState<string>();
  const publishSuccessMessage = useAppSelector(state => state.product.publishSuccessMessage);
  const publishLoading = useAppSelector(state => state.product.publishLoading);
  const { setValue, watch } = useFormContext();
  const methods = useForm();
  const {
    formState: { errors },
  } = methods;
  const productSelected = watch('productSelected');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProductCategory());
  }, []);

  useEffect(() => {
    if (publishSuccessMessage) {
      onClearProductsSelected?.();
      setOpenConfirmModal(false);
    }
  }, [publishSuccessMessage]);

  const handleOpenConfirmModal = async () => {
    if (!productSelected?.length) {
      toast.error('Select at least one product to publish');
      return;
    }
    const result = await methods.trigger();
    if (!result) {
      return;
    }
    setOpenConfirmModal(true);
  };

  const handleCloseModal = () => {
    setValue('productSelected', []);
    setOpenConfirmModal(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <div className="bg-white pt-3 px-3 rounded-2 mb-3">
            <div className="mb-1">
              <Label>Publish products</Label>
            </div>
            <Row className="g-4">
              <Col sm={12} xl={5}>
                <FormGroup>
                  <Controller
                    control={methods.control}
                    name="shopIds" // Ensure the name field is correct
                    defaultValue=""
                    render={({ field }) => <ShopAutocomplete style={{ width: '100%' }} onShopChange={ids => field.onChange(ids)} />}
                    rules={{
                      required: 'Select shop to publish',
                    }}
                  />
                  {errors.shopId && <FormFeedback>{errors.shopId?.message as string}</FormFeedback>}
                </FormGroup>
              </Col>
              <Col sm={12} xl={5}>
                <FormGroup>
                  <Controller
                    control={methods.control}
                    name="categoryId" // Ensure the name field is correct
                    defaultValue=""
                    render={({ field }) => (
                      <MegaProductCategory
                        container="product-list-page"
                        onSelect={catId => field.onChange(catId)}
                        onCategoryString={name => setCategorySelectedName(name)}
                      />
                    )}
                    rules={{
                      required: 'Select category to publish',
                    }}
                  />
                  {errors.categoryId && <FormFeedback>{errors.categoryId?.message as string}</FormFeedback>}
                </FormGroup>
              </Col>
              <Col sm={12} xl={2}>
                <Button disabled={publishLoading} outline color="info" className="w-100" type="button" onClick={handleOpenConfirmModal}>
                  <Translate contentKey="entity.action.publish">Publish</Translate>
                  <span>({productSelected?.length || 0})</span>
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
        <ConfirmModal
          isOpen={isOpenConfirmModal}
          products={productSelected}
          categoryName={categorySelectedName}
          onClose={handleCloseModal}
          methods={methods}
        />
      </FormProvider>
    </>
  );
};

export default PublicToShop;
