import { IShop } from 'app/shared/model/shop.model';
import { IUser } from 'app/shared/model/user.model';

export interface IFlashSaleSchedule {
  id?: string;
  cron?: string | null;
  auto?: boolean | null;
  status?: string | null;
  discount?: number | null;
  duration?: number | null;
  description?: string | null;
  shop?: IShop | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IFlashSaleSchedule> = {};
