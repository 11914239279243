// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-table-container {
  background: white;
  padding: 10px;
  border-radius: 8px;
}

.order-filter-item {
  background-color: white;
  border: 1px solid #4ECDC4;
  padding: 2px 8px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/ui-order/component/style.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,aAAA;EACA,kBAAA;AADJ;;AAIA;EACI,uBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AADJ","sourcesContent":["@import \"../../../custom.scss\";\n\n.order-table-container {\n    background: white;\n    padding: 10px;\n    border-radius: 8px;\n}\n\n.order-filter-item {\n    background-color: white;\n    border: 1px solid $light-teal;\n    padding: 2px 8px;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
