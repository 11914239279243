import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './partner-app.reducer';

export const PartnerAppDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const partnerAppEntity = useAppSelector(state => state.partnerApp.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="partnerAppDetailsHeading">
          <Translate contentKey="dmmtiktokApp.partnerApp.detail.title">PartnerApp</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{partnerAppEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="dmmtiktokApp.partnerApp.name">Name</Translate>
            </span>
          </dt>
          <dd>{partnerAppEntity.name}</dd>
          <dt>
            <span id="key">
              <Translate contentKey="dmmtiktokApp.partnerApp.key">Key</Translate>
            </span>
          </dt>
          <dd>{partnerAppEntity.key}</dd>
          <dt>
            <span id="secret">
              <Translate contentKey="dmmtiktokApp.partnerApp.secret">Secret</Translate>
            </span>
          </dt>
          <dd>{partnerAppEntity.secret}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="dmmtiktokApp.partnerApp.version">Version</Translate>
            </span>
          </dt>
          <dd>{partnerAppEntity.version}</dd>
          <dt>
            <span id="region">
              <Translate contentKey="dmmtiktokApp.partnerApp.region">Region</Translate>
            </span>
          </dt>
          <dd>{partnerAppEntity.region}</dd>
          <dt>
            <span id="serviceId">
              <Translate contentKey="dmmtiktokApp.partnerApp.serviceId">Service Id</Translate>
            </span>
          </dt>
          <dd>{partnerAppEntity.serviceId}</dd>
        </dl>
        <Button tag={Link} to="/partner-app" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/partner-app/${partnerAppEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PartnerAppDetail;
