import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './local-product.reducer';

export const LocalProductDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const localProductEntity = useAppSelector(state => state.localProduct.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="localProductDetailsHeading">
          <Translate contentKey="dmmtiktokApp.localProduct.detail.title">LocalProduct</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.id}</dd>
          <dt>
            <span id="brand">
              <Translate contentKey="dmmtiktokApp.localProduct.brand">Brand</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.brand}</dd>
          <dt>
            <span id="productName">
              <Translate contentKey="dmmtiktokApp.localProduct.productName">Product Name</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productName}</dd>
          <dt>
            <span id="productDescription">
              <Translate contentKey="dmmtiktokApp.localProduct.productDescription">Product Description</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productDescription}</dd>
          <dt>
            <span id="packageWeight">
              <Translate contentKey="dmmtiktokApp.localProduct.packageWeight">Package Weight</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.packageWeight}</dd>
          <dt>
            <span id="packageLength">
              <Translate contentKey="dmmtiktokApp.localProduct.packageLength">Package Length</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.packageLength}</dd>
          <dt>
            <span id="packageWidth">
              <Translate contentKey="dmmtiktokApp.localProduct.packageWidth">Package Width</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.packageWidth}</dd>
          <dt>
            <span id="packageHeight">
              <Translate contentKey="dmmtiktokApp.localProduct.packageHeight">Package Height</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.packageHeight}</dd>
          <dt>
            <span id="deliveryOptions">
              <Translate contentKey="dmmtiktokApp.localProduct.deliveryOptions">Delivery Options</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.deliveryOptions}</dd>
          <dt>
            <span id="mainProductImage">
              <Translate contentKey="dmmtiktokApp.localProduct.mainProductImage">Main Product Image</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.mainProductImage}</dd>
          <dt>
            <span id="productImage2">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage2">Product Image 2</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage2}</dd>
          <dt>
            <span id="productImage3">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage3">Product Image 3</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage3}</dd>
          <dt>
            <span id="productImage4">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage4">Product Image 4</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage4}</dd>
          <dt>
            <span id="productImage5">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage5">Product Image 5</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage5}</dd>
          <dt>
            <span id="productImage6">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage6">Product Image 6</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage6}</dd>
          <dt>
            <span id="productImage7">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage7">Product Image 7</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage7}</dd>
          <dt>
            <span id="productImage8">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage8">Product Image 8</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage8}</dd>
          <dt>
            <span id="productImage9">
              <Translate contentKey="dmmtiktokApp.localProduct.productImage9">Product Image 9</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productImage9}</dd>
          <dt>
            <span id="sizeChart">
              <Translate contentKey="dmmtiktokApp.localProduct.sizeChart">Size Chart</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.sizeChart}</dd>
          <dt>
            <span id="productStatus">
              <Translate contentKey="dmmtiktokApp.localProduct.productStatus">Product Status</Translate>
            </span>
          </dt>
          <dd>{localProductEntity.productStatus}</dd>
        </dl>
        <Button tag={Link} to="/local-product" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/local-product/${localProductEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LocalProductDetail;
