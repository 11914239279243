/* eslint-disable no-console */
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from 'app/config/store';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import { Button, Col, Form, FormFeedback, Input, Row, Table } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { IVariantList } from '../helper';
import { EmptyVariantTable } from './common';

type InputItemProps = {
  rowKey: string | number;
  fieldName: string;
  inputType?: InputType;
  required?: string;
  value: any;
};
const InputItem: FC<InputItemProps> = ({ value, rowKey, fieldName, inputType = 'text', required }) => {
  // const dispatch = useAppDispatch();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  // const value = watch(`variants[${variantIndex}].options[${optionIndex}].value`)

  // useEffect(() => {
  //     const {variants} = getValues()
  //     dispatch(buildVariantList(variants));
  // }, [value])
  return (
    <div style={{ flex: 1 }}>
      <Controller
        control={control}
        name={`variations.${rowKey}.${fieldName}`} // Ensure the name field is correct
        // defaultValue={value[fieldName]}
        render={({ field }) => <Input {...field} value={value} type={inputType} />}
        rules={{ required }}
      />
      {errors.variations?.[rowKey]?.[fieldName] && <FormFeedback>{errors.variations?.[rowKey]?.[fieldName]?.message}</FormFeedback>}
    </div>
  );
};
const SelectItem = ({ rowKey, fieldName, required = false, options }) => {
  // const dispatch = useAppDispatch();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  // const value = watch(`variants[${variantIndex}].options[${optionIndex}].value`)

  // useEffect(() => {
  //     const {variants} = getValues()
  //     dispatch(buildVariantList(variants));
  // }, [value])
  return (
    <div style={{ flex: 1 }}>
      <Controller
        control={control}
        name={`variations.${rowKey}.${fieldName}`} // Ensure the name field is correct
        render={({ field }) => {
          return (
            <Input {...field} type={'select'}>
              {options.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Input>
          );
        }}
        rules={{ required }}
      />
      {errors.variants?.[rowKey]?.[fieldName] && <FormFeedback>{errors.variants?.[rowKey]?.[fieldName]?.message}</FormFeedback>}
    </div>
  );
};

const BatchEdit = () => {
  const { control } = useForm();
  return (
    <Form>
      <Row className="d-flex">
        <Col>Validation List</Col>
        <Col>
          <Button>Batch Edit</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input id="attribute" name="attribute" type="select">
            <option>1</option>
          </Input>
        </Col>
        <Col>
          <Controller
            control={control}
            name={'retailPrice'} // Ensure the name field is correct
            render={({ field }) => <Input {...field} type={'number'} />}
          />
        </Col>
        <Col>
          <Controller
            control={control}
            name={'quantity'} // Ensure the name field is correct
            render={({ field }) => <Input {...field} type={'number'} />}
          />
        </Col>
        <Col>
          <Controller
            control={control}
            name={'quantity'} // Ensure the name field is correct
            render={({ field }) => <Input {...field} type={'text'} />}
          />
        </Col>
        <Col>
          <Button>Apply</Button>
        </Col>
      </Row>
    </Form>
  );
};

const identifierCodeTypeOptions = [
  {
    label: 'GTIN',
    value: 'GTIN',
  },
  {
    label: 'EAN',
    value: 'EAN',
  },
  {
    label: 'UPC',
    value: 'UPC',
  },
  {
    label: 'ISBN',
    value: 'ISBN',
  },
];

interface VariantListProps {
  nonSellerSKU?: boolean;
  nonIdentityCode?: boolean;
  // variantData: any;
}
const VariantList: React.FC<VariantListProps> = ({ nonSellerSKU, nonIdentityCode }) => {
  const { watch, control, getValues, setValue } = useFormContext();
  const {
    fields: variations,
    replace,
    remove,
  } = useFieldArray({
    control,
    name: 'variations',
  });

  const variantData = watch('variantData');

  const variantColumnWidth = useMemo(() => {
    if (variantData?.headerKey) {
      if (variantData?.headerKey.length === 1) {
        return '30%';
      } else if (variantData.headerKey.length === 2) {
        return '20%';
      } else if (variantData.headerKey.length === 3) {
        return '15%';
      } else if (variantData.headerKey.length === 4) {
        return 'auto';
      }
    }
  }, [variantData?.headerKey]);

  const tableColumns = useMemo(() => {
    let columns = variantData?.header;
    if (nonIdentityCode && columns) {
      columns = columns.filter(item => item.key !== 'identifierCodeType');
    }
    if (nonSellerSKU && columns) {
      columns = columns.filter(item => item.key !== 'sellerSku');
    }
    return columns;
  }, [variantData?.header, nonSellerSKU, nonIdentityCode]);

  useEffect(() => {
    if (variantData?.tableData) {
      const formData = getValues();
      const tableRowsMap = formData?.variations?.reduce((prev, curr) => {
        let rowkey = '';
        for (const hk of variantData.headerKey) {
          rowkey += `${curr[hk]}`;
        }
        prev[rowkey] = curr;
        return prev;
      }, {});
      const tableRow = { ...variantData.tableRow };
      for (const tblRowKey in tableRow) {
        if (tableRowsMap[tblRowKey]) {
          tableRow[tblRowKey] = { ...tableRow[tblRowKey], ...tableRowsMap[tblRowKey] };
        }
      }
      const newVariants = Object.values(tableRow);
      // setValue('variations', newVariants);
      console.log(tableRow);
      replace(newVariants);
    }
  }, [variantData?.tableData]);

  const watchFieldArray = watch('variations');
  const controlledFields = variations.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const handleDelete = (index: number) => remove(index);

  return (
    <>
      {variantData?.tableData?.length ? (
        <div>
          {/* <Label className='mb-1'><span className="text-danger me-1">*</span>Variant List</Label> */}
          {/* <BatchEdit /> */}
          <div>
            <Table hover bordered striped>
              <thead style={{ backgroundColor: '#f0f0f0' }}>
                <tr>
                  {tableColumns?.map((c, index) => (
                    <th key={c.key} style={{ width: index < variantData?.headerKey?.length ? variantColumnWidth : 'auto' }}>
                      <span>{c.label}</span>
                      {['Retail price', 'Base cost', 'Quantity'].includes(c.label) && <span className="text-danger me-1">*</span>}
                    </th>
                  ))}
                  <th />
                </tr>
              </thead>
              <tbody>
                {controlledFields.map((item: any, index) => (
                  <tr key={item.id} style={{ borderBottom: '1px solid #dee2e6' }}>
                    {variantData?.headerKey.map(columnKey => (
                      <td className="align-middle noborder" key={columnKey}>
                        {item[columnKey]}
                      </td>
                    ))}
                    {nonIdentityCode ? null : (
                      <td className="noborder">
                        <Row className="gx-1">
                          <Col xs="5">
                            <SelectItem rowKey={index} fieldName="identifierCodeType" options={identifierCodeTypeOptions} />
                          </Col>
                          <Col xs="7">
                            <InputItem value={item['identifierCode']} rowKey={index} fieldName="identifierCode" inputType="text" />
                          </Col>
                        </Row>
                      </td>
                    )}
                    <td className="noborder">
                      <InputItem
                        value={item['retailPrice']}
                        required="Please enter retail price"
                        rowKey={index}
                        fieldName="retailPrice"
                        inputType="number"
                      />
                    </td>
                    <td className="noborder">
                      <InputItem
                        value={item['baseCost']}
                        required="Please enter Base cost"
                        rowKey={index}
                        fieldName="baseCost"
                        inputType="number"
                      />
                    </td>
                    <td className="noborder">
                      <InputItem
                        value={item['quantity']}
                        required="Please enter quantity"
                        rowKey={index}
                        fieldName="quantity"
                        inputType="number"
                      />
                    </td>
                    {nonSellerSKU ? null : (
                      <td className="noborder">
                        <InputItem value={item['sellerSku']} rowKey={index} fieldName="sellerSku" />
                      </td>
                    )}
                    <td className="text-center align-middle noborder">
                      <Button
                        color="danger"
                        onClick={() => handleDelete(index)}
                        style={{ background: 'none', border: 'none', color: 'GrayText', padding: 0 }}
                      >
                        <FontAwesomeIcon className="cursor-pointer table-delete-icon" icon={faTrashAlt} size="lg" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <EmptyVariantTable />
      )}
    </>
  );
};

export default VariantList;
