/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { useAppDispatch, useAppSelector } from 'app/config/store';
import PageHead from 'app/shared/components/PageHead';
import { IProductAttribute } from 'app/shared/model/local-product.model';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { Button, Form, FormFeedback, FormGroup, Input, Spinner } from 'reactstrap';
import { BlockTitle, FormLabel } from '../product/components/common';
import ProductVariantForm from '../product/components/variant-form';
import VariantList from '../product/components/variant-list';
import { createEntity, getEntity, reset, updateEntity } from './product-template.reducer';
import ProductBasicInfomation from '../product/components/basic-info';
import { uploadFile } from '../product/product.reducer';
import Shipping from '../product/components/shipping';
import helper from '../product/helper';
const defaultValues = {
  name: undefined,
  productDescription: undefined,
  variations: [],
  variantList: {},
  variationAttributes: [
    {
      name: '',
      values: [{ value: '' }],
    },
  ],
  weightUnit: 'lb',
  weight: undefined,
  packageHeight: undefined,
  packageWidth: undefined,
  packageLength: undefined,
};
const filterMatchingRecords = (arrayA: any[], arrayB: any[], keys: string[]) => {
  return arrayA.filter(itemA => {
    return arrayB.some(itemB => {
      return keys.every(key => itemA[key] === itemB[key]);
    });
  });
};
const convertToKeyValueObject = (arr: any[], keys: string[]) => {
  return arr.reduce(
    (acc, item) => {
      const key = keys.map(k => item[k]).join('');
      acc[key] = item;
      return acc;
    },
    {} as Record<string, any>,
  );
};
const extractKeys = (arr: any[], keys: string[]): string[] => {
  return arr.map(item => keys.map(k => item[k]).join(''));
};
const ProductTemplateUpdate = () => {
  const [variantData, setVariantData] = useState<any>();
  const loading = useAppSelector(state => state.productTemplate.loading);
  const updating = useAppSelector(state => state.productTemplate.updating);
  const updateSuccess = useAppSelector(state => state.productTemplate.updateSuccess);
  const productTemplateEntity = useAppSelector(state => state.productTemplate.entity);

  const methods = useForm<any>({ defaultValues });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const errors = methods.formState.errors;

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      navigate('/product-template');
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (productTemplateEntity && productTemplateEntity.id) {
      const variationAttributes = [];
      for (const va of productTemplateEntity.variationAttributes) {
        const attribute = { ...va };
        attribute.values = va.values.map(item => ({ value: item }));
        variationAttributes.push(attribute);
      }
      const variationTable = helper.buildVariantsV1(variationAttributes);
      const keys = extractKeys(productTemplateEntity.variations, variationTable.headerKey);

      const tableRow = isNew
        ? {}
        : Object.keys(variationTable.tableRow)
            .filter(key => keys.includes(key))
            .reduce((prev, curr) => {
              prev[curr] = variationTable.tableRow[curr];
              return prev;
            }, {});
      methods.reset({
        ...productTemplateEntity,
        variationAttributes,
        variations: productTemplateEntity.variations || [],
        variantData: {
          ...variationTable,
          tableRow,
        },
      });
    }
  }, [productTemplateEntity]);

  const buildProductAttributes = (productAttributes: Array<IProductAttribute & { selectedId?: string | Array<string> }>) => {
    return productAttributes
      .filter(item => !!item.selectedId)
      .map(pattr => {
        return {
          id: pattr.id,
          name: pattr.name,
          values: pattr.values.filter(value =>
            typeof pattr.selectedId === 'string' ? value.id === pattr.selectedId : pattr.selectedId.includes(value.id),
          ),
        };
      });
  };

  const uploadImage = async (file: File) => {
    const response = await dispatch(uploadFile(file));
    return (response.payload as any).data;
  };

  const onSubmit = async ({ images, sizeChart, ...product }) => {
    const productAttributes = buildProductAttributes(product.productAttributes);
    let sizeChartPayload = sizeChart;
    if (sizeChart instanceof File) {
      sizeChartPayload = await uploadImage(sizeChart);
    }
    // console.log(product.variationAttributes);
    product.variationAttributes.forEach(item => {
      item.values = item.values.filter(opt => opt.value).map(opt => opt.value);
    }, []);
    const entity = {
      ...product,
      variantList: undefined,
      sizeChart: sizeChartPayload,
      status: 'active',
      productAttributes,
    } as any;
    // console.log(entity);
    if (isNew) {
      await dispatch(createEntity(entity));
    } else {
      entity['id'] = id;
      dispatch(updateEntity(entity));
    }
  };

  return (
    <div>
      <PageHead left={<span>{isNew ? 'Create' : 'Edit'} Product Template</span>}></PageHead>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="bg-white rounded-4 p-4 mb-2">
            <FormGroup>
              <FormLabel htmlFor="name" text={'Template Name'} required />
              <Controller
                control={methods.control}
                name="name" // Ensure the name field is correct
                render={({ field }) => <Input {...field} />}
                rules={{ required: 'Template name is required' }}
              />
              {errors.productDescription && <FormFeedback>{errors.productDescription?.message as string}</FormFeedback>}
            </FormGroup>
            <ProductBasicInfomation nonImages nonTitle nonCategory />
          </div>
          <div className="bg-white rounded-4 p-4 mb-2">
            <ProductVariantForm />
            <VariantList nonIdentityCode nonSellerSKU />
          </div>
          <div className="bg-white rounded-4 p-4 mb-4">
            <Shipping />
          </div>
          <div className="d-flex gap-2">
            <Button disabled={loading} color="secondary" className="mb-4" onClick={() => navigate('/product-template')}>
              Cancel
            </Button>
            <Button disabled={loading} color="primary" type="submit" className="mb-4">
              {loading && <Spinner size="sm" />}
              {`${isNew ? 'Create' : 'Edit'} Template`}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ProductTemplateUpdate;
