/* eslint-disable @typescript-eslint/no-misused-promises */
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Link } from '@mui/material';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppSelector } from 'app/config/store';
import { IUILineItem } from 'app/shared/model/ui-line-item.model';
import { IUIOrder } from 'app/shared/model/ui-order.model';
import React, { FC, useEffect, useState } from 'react';
import { TextFormat } from 'react-jhipster';
import { Col, Row } from 'reactstrap';
import UpdateDesign from './UpdateDesign';
import TruncatedText from 'app/shared/components/TruncatedText';
interface FormInput {
  printFileFront: string;
  printFileBack: string;
}
interface IOrderDetail {
  order?: IUIOrder;
}
const OrderDetail: FC<IOrderDetail> = ({ order }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeLineItem, setActiveLineItem] = useState<IUILineItem>();
  const updateSuccess = useAppSelector(state => state.localProduct.updateSuccess);

  useEffect(() => {
    if (updateSuccess) {
      closeModal();
    }
  }, [updateSuccess]);

  const handleCopyOrderId = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(order?.id);
  };

  const closeModal = () => setOpenModal(false);

  const handleOpenModal = (lineItem: IUILineItem) => {
    setActiveLineItem(lineItem);
    setOpenModal(true);
  };

  const showUpdateDesignButton = (uILineItem: IUILineItem) => {
    return (
      ['2D', '3D'].includes(uILineItem.productType) &&
      !uILineItem.printFileFront &&
      !uILineItem.printFileBack &&
      order?.status === 'AWAITING_SHIPMENT'
    );
  };

  return (
    <div>
      <div className="mb-4 d-flex gap-2">
        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{order?.id}</span>
        <FontAwesomeIcon onClick={handleCopyOrderId} color="primary" icon={faCopy} />
      </div>
      <Row className="mb-4">
        <Col xs={12}>
          <p className={`block-title status ${order?.status}`}>{order?.status}</p>
        </Col>
        <Col className="d-flex flex-column gap-2">
          <div>
            <div className="listout-label-light">Created time</div>
            <span>
              <TextFormat value={order?.createTime} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
            </span>
          </div>
          <div>
            <div>Late dispatch after</div>
            <span>
              <TextFormat value={order?.createTime} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
            </span>
          </div>
        </Col>
        <Col className="d-flex flex-column gap-2">
          <div>
            <div className="listout-label-light">Fulfillment type</div>
            <span>FULFILLMENT_BY_SELLER</span>
          </div>
          <div>
            <div className="listout-label-light">Auto-cancel date</div>
            <span>
              <TextFormat value={order?.createTime} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
            </span>
          </div>
        </Col>
        <Col className="d-flex flex-column gap-2">
          <div>
            <div className="listout-label-light">Prepare order by</div>
            <span>
              <TextFormat value={order?.createTime} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
            </span>
          </div>
          <div>
            <div className="listout-label-light">Tracking number</div>
            <span></span>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={12}>
          <p className="block-title">{order?.lineItems?.length} Items</p>
        </Col>

        <Col xs={12} className="d-flex gap-2 mb-1">
          <div>Total:</div>
          <div className="listout-content">${order?.total}</div>
        </Col>
        {order?.lineItems?.map(uILineItem => (
          <Col xs={12} key={uILineItem.id} className="mb-2">
            <div className="order-products-card d-flex align-items-center">
              <div className="product-image">
                <img src={uILineItem.skuImage} width="100%" />
              </div>
              <div style={{ width: 'calc(100% - 100px)' }} className="px-2 py-1 d-flex flex-column gap-1">
                <div className="w-100 d-flex justify-content-between">
                  <div style={{ width: `calc(100% - ${showUpdateDesignButton(uILineItem) ? '150' : '0'}px)` }}>
                    <div>
                      <span className="order-product-name">{uILineItem?.productName}</span>
                    </div>
                    <div>{uILineItem?.skuName}</div>
                    <div>
                      <span>
                        <strong>Seller SKU: </strong>
                      </span>
                      <span>{uILineItem?.sellerSku}</span>
                    </div>
                    {/* <div className="d-flex gap-2">
                      {uILineItem.productType === 'POD' && uILineItem.printFileFront && (
                        <Link href={uILineItem.printFileFront} target="_blank" rel="noreferrer">
                          Front Design
                        </Link>
                      )}
                      {uILineItem.productType === 'POD' && uILineItem.printFileBack && (
                        <Link href={uILineItem.printFileBack} target="_blank" rel="noreferrer">
                          Back Design
                        </Link>
                      )}
                    </div> */}
                    <div className="d-flex gap-2">
                      <span>
                        <strong>Front: </strong>
                      </span>
                      <span>
                        {uILineItem.printFileFront ? (
                          <Link href={uILineItem.printFileFront} target="_blank" rel="noreferrer">
                            <TruncatedText text={uILineItem.printFileFront} maxLength={40} />
                          </Link>
                        ) : (
                          <span className="text-danger">Missing</span>
                        )}
                      </span>
                    </div>
                    <div className="d-flex gap-2">
                      <span>
                        <strong>Back:&nbsp;</strong>
                      </span>
                      <span>
                        {uILineItem.printFileBack ? (
                          <Link href={uILineItem.printFileBack} target="_blank" rel="noreferrer">
                            <TruncatedText text={uILineItem.printFileBack} maxLength={40} />
                          </Link>
                        ) : (
                          <span className="text-danger">{`  Missing`}</span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    {showUpdateDesignButton(uILineItem) && (
                      <Link style={{ textTransform: 'initial' }} onClick={() => handleOpenModal(uILineItem)}>
                        Update Design
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <Row className="mb-4">
        <Col xs={12}>
          <p className="block-title">What your buyer paid</p>

          <div className="d-flex gap-2 justify-content-between">
            <div>Payment method</div>
            <div className="listout-content">N/A</div>
          </div>
          <div className="d-flex gap-2 justify-content-between">
            <div>Item(s) subtotal after discounts</div>
            <div className="listout-content">${order?.total}</div>
          </div>
          <div className="d-flex gap-2 justify-content-between">
            <div>Shipping fee after discounts</div>
            <div className="listout-content">0</div>
          </div>
          <div className="d-flex gap-2 justify-content-between">
            <div>Taxes</div>
            <div className="listout-content">0</div>
          </div>
          <div className="d-flex gap-2 justify-content-between">
            <div>Total</div>
            <div className="listout-content total">${order?.total}</div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <p className="block-title">Customer Information</p>

          <div className="d-flex gap-2">
            <div>Buyer Email:</div>
            <div className="listout-content">{order?.buyer}</div>
          </div>
          <div className="d-flex gap-2">
            <div>Shipping Method:</div>
            <div className="listout-content">{order?.shippingType}</div>
          </div>
        </Col>
      </Row>
      <UpdateDesign isOpenModal={openModal} onCloseModal={closeModal} product={activeLineItem} />
    </div>
  );
};

export default OrderDetail;
