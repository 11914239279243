import { ILocalProduct } from 'app/shared/model/local-product.model';
import { IShop } from 'app/shared/model/shop.model';
import { IUser } from 'app/shared/model/user.model';

export interface IProductPublishHistory {
  id?: string;
  sku?: string | null;
  publishedAt?: number | null;
  tiktokProductId?: string | null;
  localProduct?: ILocalProduct | null;
  shop?: IShop | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IProductPublishHistory> = {};
