import { ISellerAuthorization } from 'app/shared/model/seller-authorization.model';

export interface IShop {
  id?: string;
  cipher?: string | null;
  code?: string | null;
  name?: string | null;
  region?: string | null;
  sellerType?: string | null;
  note?: string | null;
  authorization?: ISellerAuthorization | null;
}

export const defaultValue: Readonly<IShop> = {};
