export interface IMetrics {
  grossSales: number;
  gmvWithTax: number;
  gmv: number;
  totalOrders: number;
  orderedSKUs: number;
  buyers: number;
  firstTimeBuyers: number;
  averageOrderValue: number;
  grossRevenueWithTTSSubsidy: number;
}

export const defaultValue: Readonly<IMetrics> = {
  grossSales: 0,
  gmvWithTax: 0,
  gmv: 0,
  totalOrders: 0,
  orderedSKUs: 0,
  buyers: 0,
  firstTimeBuyers: 0,
  averageOrderValue: 0,
  grossRevenueWithTTSSubsidy: 0
};
