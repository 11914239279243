import PageHead from "app/shared/components/PageHead"
import React from "react"


export const ProductDetail = () => {
    return (
        <PageHead left="Create Product" />
    )
}

export default ProductDetail