import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

const FlashSaleModal = ({ open, onClose, onSubmit, title }) => {
  const [discount, setDiscount] = useState('10');
  const [duration, setDuration] = useState('24');
  const [auto, setAuto] = useState(true);
  const [cronExpression, setCronExpression] = useState('0 0 00 1/1 * ? *');

  const handleSubmit = () => {
    onSubmit(discount, duration, auto);
    setDiscount('');
    onClose();
  };
  const handleCronChange = e => {
    setCronExpression(e);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the discount percentage for the flash sale.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="discount"
          label="Discount"
          type="number"
          fullWidth
          value={discount}
          onChange={e => setDiscount(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="duration"
          label="Duration"
          type="number"
          fullWidth
          value={duration}
          onChange={e => setDuration(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox checked={auto} onChange={e => setAuto(e.target.checked)} />}
          label="Automatically create a new flash sale after the current one ends."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlashSaleModal;
