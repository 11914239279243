import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import "./style.scss"
import { languages } from 'app/config/translation';
import { AccountMenu } from 'app/shared/layout/menus';
import { useAppSelector } from 'app/config/store';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MenuItem {
    id: string;
    icon: string;
    link: string;
    translateKey: string;
    title: string;
}
const accountMenuItems: Array<MenuItem> = [
    {
        id: "login-item",
        icon: "sign-in-alt",
        link: "/login",
        translateKey: "global.menu.account.login",
        title: "Sign in"
    },
    {
        id: "user-plus",
        icon: "user-plus",
        link: "/account/register",
        translateKey: "global.menu.account.register",
        title: "Register"
    },
]
const accountMenuItemsAuthenticated = [
    {
        id: "wrench",
        icon: "wrench",
        link: "/account/settings",
        translateKey: "global.menu.account.settings",
        title: "Settings"
    },
    {
        id: "lock",
        icon: "lock",
        link: "/account/password",
        translateKey: "global.menu.account.password",
        title: "Password"
    },
    {
        id: "sign-out-alt",
        icon: "sign-out-alt",
        link: "/logout",
        translateKey: "global.menu.account.logout",
        title: "Sign out"
    },
]

const MenuAccountItem = ({ item, onSelect }) => {
    return (<div key={item.id} onClick={onSelect} className='d-flex gap-2 align-items-center px-4 py-2 language-item'>
        <FontAwesomeIcon icon={item.icon} fixedWidth />
        <span className="align-middle">
            <Translate contentKey={item.translateKey}>{item.title}</Translate>
        </span>
    </div>)
}
const AccountDropdown = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate()
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSelect = (item: MenuItem) => {
        toggleDropdown()
        navigate(item.link)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
                tag="span"
                onClick={toggleDropdown}
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
            >
                <div className='language-selected'>
                    <img src={`content/images/jhipster_family_member_0_head-192.png`} alt={"User Account"} />{' '}
                </div>
            </DropdownToggle>
            <DropdownMenu>
                {isAuthenticated && accountMenuItemsAuthenticated.map((item, i) => <MenuAccountItem key={item.id} item={item} onSelect={() => handleSelect(item)} />)}
                {!isAuthenticated && accountMenuItems.map((item, i) => <MenuAccountItem key={item.id} item={item} onSelect={() => handleSelect(item)} />)}
            </DropdownMenu>
        </Dropdown>
    );
};

export default AccountDropdown;
