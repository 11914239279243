export interface IShopMetric {
  id?: string;
  totalOrders?: number | null;
  totalOnhold?: number | null;
  totalAwaitingShipment?: number | null;
  totalAwaitingCollection?: number | null;
  totalCancelled?: number | null;
  totalDelivered?: number | null;
  totalFlashsale?: number | null;
  totalInFlashsale?: number | null;
  totalNotInFlashsale?: number | null;
  totalProducts?: number | null;
  totalLive?: number | null;
  totalInactive?: number | null;
  totalReviewing?: number | null;
  totalSuspended?: number | null;
  totalDraft?: number | null;
  totalDeleted?: number | null;
}

export const defaultValue: Readonly<IShopMetric> = {};
