export const cleanObject = (obj: any, replaceWith: string = ''): any => {
    const isReplaceMode = replaceWith !== undefined;

    if (Array.isArray(obj)) {
        return obj.map(item => cleanObject(item, replaceWith)).filter(item => isReplaceMode || (item !== undefined && item !== null));
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((acc, key) => {
            const value = cleanObject(obj[key], replaceWith);
            if (isReplaceMode) {
                acc[key] = value === undefined || value === null ? replaceWith : value;
            } else {
                if (value !== undefined && value !== null) {
                    acc[key] = value;
                }
            }
            return acc;
        }, {} as any);
    }
    return obj;
};

export const buildUrlParams = (params: Record<string, string | number>) => {
    return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
}
