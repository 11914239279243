// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0.1em;
  margin: 30px 0 20px;
}

h3 span {
  background: #fff;
  padding: 0 10px;
  text-transform: uppercase;
}

.entity-details {
  display: flex;
  flex-wrap: wrap;
}

.detail-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ccc;
}

.detail-item-no-border {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.detail-item b {
  width: 100px;
}

.detail-item span {
  width: 100%;
}

.detail-item-no-border span {
  width: 100%;
}

.detail-property {
  font-weight: bold;
  width: 40%;
}

.detail-value {
  width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/base-cost/base-cost-detail-modal.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,6BAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,6BAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,UAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":["h3 {\n  width: 100%;\n  text-align: center;\n  border-bottom: 1px solid #ccc;\n  line-height: 0.1em;\n  margin: 30px 0 20px;\n}\n\nh3 span {\n  background:#fff;\n  padding:0 10px;\n  text-transform: uppercase;\n}\n\n.entity-details {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.detail-item {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: 0.5rem 0;\n  border-bottom: 1px solid #ccc;\n}\n\n.detail-item-no-border {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: 0.5rem 0;\n}\n\n.detail-item b {\n  width: 100px;\n}\n\n.detail-item span {\n  width: 100%;\n}\n\n.detail-item-no-border span {\n  width: 100%;\n}\n\n.detail-property {\n  font-weight: bold;\n  width: 40%;\n}\n\n.detail-value {\n  width: 70%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
