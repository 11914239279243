// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown {
  border: 1px solid rgba(245, 245, 245, 0.8431372549);
  height: 36px;
  padding: 4px;
}

.confirm-modal-content .product-list-container {
  max-height: 300px;
  overflow: auto;
}
.confirm-modal-content .hightlight {
  color: #009995;
}
.confirm-modal-content .product-list {
  background: #f5f5f5;
}

.product-with-source {
  height: 30%;
  background: rgba(220, 53, 69, 0.8);
  right: 10px;
  bottom: 10px;
  padding: 4px 10px;
  border-radius: 8px;
}
.product-with-source div {
  color: white;
  font-weight: bold;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/product/components/PublicToShop/style.scss","webpack://./src/main/webapp/app/custom.scss"],"names":[],"mappings":"AAEA;EACI,mDAAA;EACA,YAAA;EACA,YAAA;AADJ;;AAII;EACI,iBAAA;EACA,cAAA;AADR;AAII;EACI,cCHE;ADCV;AAKI;EACI,mBClBQ;ADehB;;AAOA;EACI,WAAA;EACA,kCAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAJJ;AAKI;EACI,YAAA;EACA,iBAAA;EACA,eAAA;AAHR","sourcesContent":["@import \"../../../../custom.scss\";\n\n.custom-dropdown {\n    border: 1px solid $base-bg-color-dark;\n    height: 36px;\n    padding: 4px;\n}\n.confirm-modal-content {\n    .product-list-container {\n        max-height: 300px;\n        overflow: auto;\n    }\n\n    .hightlight {\n        color: $primary;\n    }\n\n    .product-list {\n        background: $base-bg-color;\n    }\n}\n\n.product-with-source {\n    height: 30%;\n    background: rgba($color: $danger, $alpha: 0.8) ;\n    right: 10px;\n    bottom: 10px;\n    padding: 4px 10px;\n    border-radius: 8px;\n    div {\n        color: white;\n        font-weight: bold;\n        font-size: 14px;\n    }\n}","$base-bg-color: #f5f5f5;\n$base-bg-color-dark: #f5f5f5d7;\n\n$text-color-1: rgba(0, 0, 0, .92);\n$header-height: 61;\n$footer-height: 90;\n$text-base-light: #6b7280bf;\n$text-base: #6b7280;\n$text-base-dark: #2a2d32;\n\n// Colors\n$primary: #009995;\n$secondary: #FF6B6B;\n$success: #28A745;\n$info: #17A2B8;\n$warning: #FFC107;\n$danger: #DC3545;\n$light: #F8F9FA;\n$dark: #343A40;\n\n// Additional custom colors\n$accent: #FF9F1C;\n$neutral: #6C757D;\n$light-teal: #4ECDC4;\n$dark-teal: #005F5C;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
