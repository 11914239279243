import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductTemplate from './product-template';
import ProductTemplateDetail from './product-template-detail';
import ProductTemplateUpdate from './product-template-update';

const ProductTemplateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductTemplate />} />
    <Route path="new" element={<ProductTemplateUpdate />} />
    <Route path=":id">
      <Route index element={<ProductTemplateDetail />} />
      <Route path="edit" element={<ProductTemplateUpdate />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductTemplateRoutes;
